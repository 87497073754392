import * as yup from "yup";
import i18n from "i18next";

const schema = yup
  .object({
    DriverName: yup
      .string()
      .required(() => i18n.t("diesalClients.driverNameIsRequired")),
    NationalIdNumber: yup
      .string()
      .required(() => i18n.t("diesalClients.idNumberIsRequired"))
      .length(14, () => i18n.t("diesalClients.driverMustbe14Number")),
    CarId: yup
      .string()
      .required(() => i18n.t("diesalClients.pleaseChooseCar")),
  })
  .required();

export default schema;
