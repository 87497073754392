/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react';
import {
  Col,
  Row,
  Table,
} from 'react-bootstrap';
// import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Loading from '../../components/Loading';
import PdfPage from './pdf/PdfPage';

function SummaryTable({
  offerData, issueFrom, IssueProduct, pdfLang, hideButtons,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const { i18n, t } = useTranslation();
  const { offerId } = useParams();

  const handleTotal = () => {
    const totalArray = [];
    if (offerData) {
      const newData = [...offerData.PriceOfferItems];
      newData.forEach((el) => {
        el.total = (el.Quantity * el.SellPriceWithNoTax);
        totalArray.push(el.total);
      });
      if (totalArray.length > 0) {
        setTotal(totalArray?.reduce((a, b) => a + b));
      }
    }
  };
  useEffect(() => {
    handleTotal();
  }, [offerData]);
  // console.log(offerData, 'gggggggggggg');
  return (
    <>
      <div className="first_table">
        <Table striped bordered hover className="mt-5 table-responsive mb-0">
          <thead className="text-white" style={{ backgroundColor: '#333' }}>
            <tr>
              <th className="col-md-4">
                {' '}
                {t('priceOffer.productNameSystem9')}
                {' '}
              </th>
              <th className="col-md-4">
                {' '}
                {t('priceOffer.productNamePo')}
                {' '}
              </th>
              <th className="col-md-4">
                {' '}
                {t('priceOffer.quantity')}
                {' '}
              </th>
              <th className="col-md-4">
                {t('priceOffer.purchasePrice')}
                {' '}
              </th>
              <th className="col-md-4">
                {' '}
                {t('priceOffer.sellingPrice')}
                {' '}
              </th>
              <th className="col-md-4">
                {t('priceOffer.priceMatchingPaymentTerms')}
                {' '}
              </th>
              {/* <th className="col-md-4"> Minimum Price Set </th> */}
              <th className="col-md-4">
                {' '}
                {t('priceOffer.total')}
                {' '}
              </th>
            </tr>
          </thead>
          <tbody>
            {offerData?.PriceOfferItems.map((el) => (
              <tr key={el.PriceOfferId}>
                <td className="col-md-4">{i18n.dir() === 'ltr' ? el?.ProductSystem9Json?.ProductNameEn : el?.ProductSystem9Json?.ProductNameAr}</td>
                <td className="col-md-4">{el.ProductNamePO}</td>
                <td className="col-md-4">{el.Quantity}</td>
                <td className="col-md-4">{el.PurchasePriceWithNoTax}</td>
                <td className="col-md-4">{el.SellPriceWithNoTax}</td>
                <td className="col-md-4">{el.IsPriceMatchPaymentTerms ? 'yes' : 'No'}</td>
                {/* <td className="col-md-4">{el.MinPrice}</td> */}
                <td className="col-md-4" id="total">{el.Quantity * el.SellPriceWithNoTax}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <h2 className="mt-3" id="totalValue">
          Total :
          {' '}
          {total}
        </h2>
      </div>
      {hideButtons ? ''
        : (
          <div className="buttons d-flex mt-5 justify-content-center">
            <Link style={{ backgroundColor: '#000' }} to={`/offerDetails/${offerId}`} variant="dark" className="main-button text-white me-3">{t('priceOffer.back')}</Link>
            {/* <SubmitPdf issueFrom={issueFrom} IssueProduct={IssueProduct} pdfLang={pdfLang} setLoading={setLoading} offerData={offerData} /> */}

            <PDFDownloadLink
              document={<PdfPage offerData={offerData} IssueProduct={IssueProduct} pdfLang={pdfLang} issueFrom={issueFrom} />}
              fileName="Price_Offer"
              style={{
                textDecoration: 'none',
                padding: '10px',
                color: '#fff',
                backgroundColor: '#ba2928',
                border: 'none',
                borderRadius: '10px',
              }}
            >
              {({
                blob, url, loading, error,
              }) => (loading ? t('priceOffer.loadingDocument') : t('priceOffer.downloadPdf'))}
            </PDFDownloadLink>
            <Link
              style={{
                textDecoration: 'none',
                padding: '10px',
                color: '#fff',
                backgroundColor: '#ba2928',
                border: 'none',
                borderRadius: '10px',
                margin: '0 10px',
              }}
              to="/"
            >
              {' '}
              {t('priceOffer.goToHome')}
              {' '}

            </Link>
            {/* <PDFViewer>
              <PdfPage offerData={offerData} IssueProduct={IssueProduct} pdfLang={pdfLang} issueFrom={issueFrom} />
            </PDFViewer> */}
          </div>

        )}
      {loading && <Loading />}
    </>
  );
}

export default SummaryTable;
