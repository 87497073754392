/* eslint-disable react/no-unescaped-entities */
import React, { forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';
import footerAr from '../../assets/images/Header/arFooter.png';
import enFooter from '../../assets/images/Header/enFooter.png';
import headerAr from '../../assets/images/Header/arHeader.png';
import headerEn from '../../assets/images/Header/enHeader.png';
import arYasser from '../../assets/images/Header/arYasser.png';
import enYasser from '../../assets/images/Header/enYasser.png';

export function TextComponent({ offerData, issueFrom, IssueProduct }) {
  return (
    <div className="">
      <p className="pt-1">* the company is subject to advance Payment tax trial system</p>
      <ul className="px-3">
        <li>
          <div className="d-flex">
            <ins className="font-weight-bold text-underline">Price Offer validity:</ins>
            <p className="px-2 m-0">
              {' '}
              price offer valid for
              {' '}
              {offerData?.PriceOfferValidity}
              {' '}
              Hours
            </p>
          </div>
        </li>
        <li>
          <div className="d-flex">
            <ins style={{ width: '15%' }} className="font-weight-bold text-underline">Payment Terms:</ins>
            <p className="px-2 m-0">
              {' '}
              Payment terms
              {' '}
              {offerData?.PaymentTerms?.Name}
              {' '}
              , bank transfer or payable check received
              {' '}
              {offerData?.ExptectedDateToRecievePaymentCheck}
            </p>
          </div>
        </li>
        <li>
          <div className="d-flex">
            <p className="m-0"> Prices don’t include VAT 14% </p>
          </div>
        </li>
        <li>
          <div className="d-flex">
            <p className="m-0">
              {' '}
              Prices stated are valid unless there is no price change by the production companies until the date of receipt of the purchases order,
              provided availability of products at the production companies
              {' '}

            </p>
          </div>
        </li>
        <li>
          <div className="d-flex">
            {/* <p className="m-0">any additional notes : </p> */}
            <p style={{ whiteSpace: 'pre-wrap' }} className="m-0 px-2 ">{offerData?.Notes}</p>
          </div>
        </li>
      </ul>
      <h6 className="m-0"> if you have any questions or comments, please don't hesitate to contact us</h6>
      <h6 className="pt-3 m-0">
        {' '}
        {offerData?.SalesRepresentitiveName}
        {' '}
        {offerData?.SalesRepresentitiveNumber}
      </h6>
    </div>
  );
}

function Pdf({ offerData, issueFrom, IssueProduct }) {
  // console.log(offerData, 'ffffffffffff');
  return (
    <div style={{ direction: 'ltr' }} className="printable-content">
      <div className="pdf-header">
        <img src={headerEn} alt="" className="img-fluid w-100" />
      </div>

      <div className="body-content px-3">
        <h5 style={{ fontSize: '1.5rem' }} className="text-center mb-3 mt-2">Price Offer</h5>
        <Row>
          <Col xs={8}>
            <div className="d-flex">
              <p className="font-weight-bold mb-0">Company :</p>
              <p className="px-2 mb-0">
                {' '}
                {offerData?.ClientNameInPO}
              </p>
            </div>
          </Col>
          <Col xs={4}>
            <div className="d-flex">
              <p className="font-weight-bold mb-0">Attention :</p>
              <p className="px-2 mb-0">
                {' '}
                {offerData?.PriceOfferSentTo}
              </p>
            </div>
          </Col>
          <Col xs={8}>
            <div className="d-flex mt-2">
              <p className="font-weight-bold  mb-0">Date :</p>
              <p className="px-2 mb-0">
                {' '}
                {offerData?.CreationDate?.substring(0, 10)}
              </p>
            </div>
          </Col>
          <Col xs={4}>
            <div className="d-flex mt-2">
              <p className="font-weight-bold  mb-0">Price Offer :</p>
              <p className="px-2 mb-0">
                #
                {offerData?.SerialNumber}
              </p>
            </div>
          </Col>
        </Row>
        {offerData?.PriceOfferItems?.length > 5 && offerData?.PriceOfferItems?.length < 14
          ? (
            <>
              <div className="bg-photo">
                <Table bordered hover className="mt-2 table-responsive mb-0 pdf-table mb-1">
                  <thead className="">
                    <tr>
                      <th className="col-md-6 py-1"> Product Description</th>
                      <th className="col-md-3 py-1"> Price </th>
                      <th className="col-md-3 py-1"> Quantity </th>
                    </tr>

                  </thead>
                  <tbody>
                    {offerData?.PriceOfferItems?.slice(0, 8).map((el) => (
                      <tr key={el.PriceOfferId}>
                        <td className="col-md-6 py-1">

                          {IssueProduct === '1' ? el.ProductNamePO : el.ProductName }
                        </td>
                        <td className="col-md-3 py-1">{el.SellPriceWithNoTax}</td>
                        <td className="col-md-6 py-1">{el.Quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div style={{ height: '470px' }} className="" />
              <div className="bg-photo">
                {offerData?.PriceOfferItems?.length < 8
                  ? (
                    <Table bordered hover className="mt-2 table-responsive mb-0 pdf-table mb-1">
                      <thead className="">
                        <tr>
                          <th className="col-md-6 py-3"> Product Description</th>
                          <th className="col-md-3 py-3"> Price </th>
                          <th className="col-md-3 py-3"> Quantity </th>
                        </tr>

                      </thead>
                      <tbody>
                        {offerData?.PriceOfferItems?.slice(8, offerData?.PriceOfferItems?.length).map((el) => (
                          <tr key={el.PriceOfferId}>
                            <td className="col-md-6 py-3">

                              {IssueProduct === '1' ? el.ProductNamePO : el.ProductName }
                            </td>
                            <td className="col-md-3 py-3">{el.SellPriceWithNoTax}</td>
                            <td className="col-md-6 py-3">{el.Quantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )
                  : ''}
                <TextComponent offerData={offerData} issueFrom={issueFrom} IssueProduct={IssueProduct} />
              </div>
            </>
          ) : offerData?.PriceOfferItems?.length > 15 ? (
            <>
              <div className="bg-photo">
                <Table bordered hover className="mt-2 table-responsive mb-0 pdf-table mb-1">
                  <thead className="">
                    <tr>
                      <th className="col-md-6 py-3"> Product Description</th>
                      <th className="col-md-3 py-3"> Price </th>
                      <th className="col-md-3 py-3"> Quantity </th>
                    </tr>

                  </thead>
                  <tbody>
                    {offerData?.PriceOfferItems?.slice(0, 8).map((el) => (
                      <tr key={el.PriceOfferId}>
                        <td className="col-md-6 py-3">

                          {IssueProduct === '1' ? el.ProductNamePO : el.ProductName }
                        </td>
                        <td className="col-md-3 py-3">{el.SellPriceWithNoTax}</td>
                        <td className="col-md-6 py-3">{el.Quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div style={{ height: '470px' }} className="" />
              <div className="bg-photo">
                <Table bordered hover className="mt-2 table-responsive mb-0 pdf-table mb-1">
                  <thead className="">
                    <tr>
                      <th className="col-md-6 py-3"> Product Description</th>
                      <th className="col-md-3 py-3"> Price </th>
                      <th className="col-md-3 py-3"> Quantity </th>
                    </tr>

                  </thead>
                  <tbody>
                    {offerData?.PriceOfferItems?.slice(8, 16).map((el) => (
                      <tr key={el.PriceOfferId}>
                        <td className="col-md-6 py-3">

                          {IssueProduct === '1' ? el.ProductNamePO : el.ProductName }
                        </td>
                        <td className="col-md-3 py-3">{el.SellPriceWithNoTax}</td>
                        <td className="col-md-6 py-3">{el.Quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div style={{ height: '470px' }} className="" />
              <div className="bg-photo">
                <Table bordered hover className="mt-2 table-responsive mb-0 pdf-table mb-1">
                  <thead className="">
                    <tr>
                      <th className="col-md-6 py-3"> Product Description</th>
                      <th className="col-md-3 py-3"> Price </th>
                      <th className="col-md-3 py-3"> Quantity </th>
                    </tr>

                  </thead>
                  <tbody>
                    {offerData?.PriceOfferItems?.slice(16, offerData?.PriceOfferItems?.length).map((el) => (
                      <tr key={el.PriceOfferId}>
                        <td className="col-md-6 py-3">

                          {IssueProduct === '1' ? el.ProductNamePO : el.ProductName }
                        </td>
                        <td className="col-md-3 py-3">{el.SellPriceWithNoTax}</td>
                        <td className="col-md-6 py-3">{el.Quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div style={{ height: '470px' }} className="" />
              <div className="bg-photo">
                <TextComponent offerData={offerData} issueFrom={issueFrom} IssueProduct={IssueProduct} />
              </div>

            </>
          )
            : (
              <div className="bg-photo">
                <Table bordered hover className="mt-2 table-responsive mb-0 pdf-table mb-1">
                  <thead className="">
                    <tr>
                      <th className="col-md-6 py-3"> Product Description</th>
                      <th className="col-md-3 py-3"> Price </th>
                      <th className="col-md-3 py-3"> Quantity </th>
                    </tr>

                  </thead>
                  <tbody>
                    {offerData?.PriceOfferItems?.map((el) => (
                      <tr key={el.PriceOfferId}>
                        <td className="col-md-6 py-3">

                          {IssueProduct === '1' ? el.ProductNamePO : el.ProductName }
                        </td>
                        <td className="col-md-3 py-3">{el.SellPriceWithNoTax}</td>
                        <td className="col-md-6 py-3">{el.Quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TextComponent offerData={offerData} issueFrom={issueFrom} IssueProduct={IssueProduct} />
              </div>
            ) }

      </div>
      <div className="pdf-footer">
        <img src={footerAr} alt="" className="img-fluid w-100" />
      </div>
    </div>
  );
}

export default Pdf;
