import * as yup from 'yup';
import i18n from 'i18next';

const schemaTable = yup
  .object({
    // ProductNamePO: yup.string().required(() => i18n.t('auth.required')),
    Quantity: yup.string().required(() => i18n.t('auth.required')),
    PurchasePriceWithNoTax: yup.string().required(() => i18n.t('auth.required')),
    SellPriceWithNoTax: yup.string().required(() => i18n.t('auth.required')),
    // Inventory: yup.string().required(() => i18n.t('auth.required')),
    // QuantityRequestedInOtherPO: yup.string().required(() => i18n.t('auth.required')),
    // MinPrice: yup.string().required(() => i18n.t('auth.required')),
    // IsPriceMatchPaymentTerms: yup.string().required(() => i18n.t('auth.required')),
    // total: yup.string().required(() => i18n.t('auth.required')),
  })
  .required();

export default schemaTable;
