/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import ClientDetails from "./clientDetails";
import OfferApiEndpoints from "../../ymc-sdk/services/offer.api";
import Api from "../../ymc-sdk/services";
import AuthApiEndpoints from "../../ymc-sdk/services/auth.api";

function NewClient() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: regions, isLoading: regionsLoading } = useQuery(
    ["regions"],
    () => OfferApiEndpoints.getRegions(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("regions", data);
        return data;
      },
    }
  );
  const { data: productCategories, isLoading: productCategoriesLoading } =
    useQuery(
      ["productCategories"],
      () => Api.lookupData.getProductCategories(),
      {
        staleTime: 10 * 60 * 1000, // 10 mins
        cacheTime: 15 * 60 * 1000, // 15 mins
        select: (data) => {
          // console.log("productCategories", data);
          return data;
        },
      }
    );
  const { data: clientStatus, isLoading: clientStatusLoading } = useQuery(
    ["clientStatus"],
    () => Api.lookupData.getClientStatus(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("clientStatus", data);
        return data;
      },
    }
  );
  const { data: industries, isLoading: industriesLoading } = useQuery(
    ["industries"],
    () => OfferApiEndpoints.getIndustries(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("industries", data);
        return data;
      },
    }
  );
  const { data: productTypes, isLoading: productTypesLoading } = useQuery(
    ["productTypes"],
    () => Api.lookupData.getDiesalProductTypes(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("industries", data);
        return data;
      },
    }
  );
  const { data: adminUsers, isLoading: adminUsersLoading } = useQuery(
    ["adminUsers"],
    () => AuthApiEndpoints.getSystemUsers(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );

  // Combine isLoading states of all queries
  const allQueriesLoading =
    regionsLoading ||
    productCategoriesLoading ||
    clientStatusLoading ||
    industriesLoading ||
    productTypesLoading ||
    adminUsersLoading;

  // console.log(allQueriesLoading);
  return (
    <ClientDetails
      newClient
      industries={industries}
      regions={regions}
      productCategories={productCategories}
      clientStatus={clientStatus}
      productTypes={productTypes}
      assignedUsers={adminUsers}
      isLoading={allQueriesLoading}
    />
  );
}
export default NewClient;
