import React, { useState, useEffect } from 'react';
import OfferDetails from '../../OfferDetails/OfferDetails';
import CompleteOfferDetails from '../../OfferDetails/CompleteOfferDetails';

function EditOffer() {
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [updateForm, setUpdateForm] = useState(null);
  const [offerData, setOfferData] = useState(null);
  const [IssueProduct, setIssueProduct] = useState('1');
  const [issueFrom, setIssueFrom] = useState('1');
  const [pdfLang, setPdfLaNG] = useState('1');
  return (
    <>
      <OfferDetails
        setIssueProduct={setIssueProduct}
        setPdfLaNG={setPdfLaNG}
        setIssueFrom={setIssueFrom}
        edit
        paymentTerms={paymentTerms}
        setPaymentTerms={setPaymentTerms}
        setUpdateForm={setUpdateForm}
        offerData={offerData}
        setOfferData={setOfferData}
      />
      <CompleteOfferDetails
        issueFrom={issueFrom}
        pdfLang={pdfLang}
        IssueProduct={IssueProduct}
        edit
        paymentTerms={paymentTerms}
        updateForm={updateForm}
        offerData={offerData}
        setOfferData={setOfferData}
      />

    </>
  );
}

export default EditOffer;
