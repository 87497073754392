/* eslint-disable eqeqeq */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
// eslint-disable-next-line object-curly-newline
import { Col, Form, Row, Table } from "reactstrap";
import { Button, Pagination } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import InputField from "../../components/input";
import OfferApiEndpoints from "../../ymc-sdk/services/offer.api";
import Loading from "../../components/Loading";
import Api from "../../ymc-sdk/services";

function ProductsPONumber() {
  const { t, i18n } = useTranslation();
  const [isloading, setLoading] = useState(false);
  const UserId = useSelector((state) => state.auth?.userData?.Id);
  const pages = useSelector((state) => state.auth?.userData?.Pages);
  const isPageRouteValid = (pageRoute) => {
    return pages?.some((page) => page.PageRouting === pageRoute);
  };
  const [Page, setPage] = useState(0);
  const [PageSize, setPageSize] = useState(3);
  const [resData, setResData] = useState({
    Data: [],
    Page: 0,
    PageSize: 0,
    TotalCount: 0,
  });
  useEffect(() => {
    setLoading(true);
    const data = { Page, PageSize };
    // console.log("sssssssss");
    Api.home
      .GetAllProductsQuantityInPendingPriceOffers(data)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        setResData({
          ...resData,
          Data: [...res.Data.Data],
          Page: res.Data.Page,
          PageSize: res.Data.PageSize,
          TotalCount: res.Data.TotalCount,
        });
        // console.log(resData);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  }, [Page]);

  return (
    <div className="mb-5">
      <h3>{t("home.productsPoNumber")}</h3>

      <div className="">
        {resData?.Data?.length ? (
          <Table striped bordered hover className="table-responsive mb-0">
            <thead className="text-white" style={{ backgroundColor: "#333" }}>
              <tr>
                <th
                  className="bg-dark text-white"
                  style={{ verticalAlign: "middle" }}
                >
                  {t("home.productNameSystem9")}
                </th>
                <th
                  className="bg-dark text-white"
                  style={{ verticalAlign: "middle" }}
                >
                  {t("home.totalInventoryRequestedThroughPOs")}
                </th>
              </tr>
            </thead>
            <tbody>
              {resData?.Data?.map((el) => (
                <tr key={el.ProductCode}>
                  <td>{el.ProductName}</td>
                  <td>{el.QuantityRequestedInPendingPriceOffers}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h3 className="text-center mt-4"> {t("search.noData")} </h3>
        )}
        <div className="d-flex justify-content-center py-2">
          {(resData?.Data?.length !== 0 || Page !== 0) && (
            <Pagination>
              <Pagination.Prev
                onClick={() => {
                  setPage((prevCount) => prevCount - 1);
                }}
                disabled={Page === 0}
              >
                {i18n.language === "en" ? (
                  <FaAngleLeft size={30} color="#dd2c00" />
                ) : (
                  <FaAngleRight size={30} color="#dd2c00" />
                )}
                <span style={{ color: "#dd2c00" }}>{t("home.previous")}</span>
              </Pagination.Prev>
              <Pagination.Next
                onClick={() => {
                  setPage((prevCount) => prevCount + 1);
                }}
                disabled={resData?.PageSize < PageSize}
              >
                <span style={{ color: "#dd2c00" }}>{t("home.next")}</span>
                {i18n.language === "en" ? (
                  <FaAngleRight size={30} color="#dd2c00" />
                ) : (
                  <FaAngleLeft size={30} color="#dd2c00" />
                )}
              </Pagination.Next>
            </Pagination>
          )}
        </div>
      </div>
      {isloading && <Loading />}
    </div>
  );
}
export default ProductsPONumber;
