import * as yup from "yup";
import i18n from "i18next";

const schema = yup
  .object({
    // driverName: yup
    //   .string()
    //   .required(() => i18n.t("diesalClients.driverNameIsRequired")),
    DieselProductTypeId: yup.string().required(() => i18n.t("auth.required")),
    LitersTakenByCar: yup
      .number()
      .typeError(() => i18n.t("auth.required"))
      .required(() => i18n.t("auth.required")),
    TotalInvoiceValue: yup
      .number()
      .typeError(() => i18n.t("auth.required"))
      .required(() => i18n.t("auth.required")),
    LastTimeKMS: yup
      .number()
      .typeError(() => i18n.t("auth.required"))
      .required(() => i18n.t("auth.required")),
    KmsCounterToday: yup
      .number()
      .typeError(() => i18n.t("auth.required"))
      .required(() => i18n.t("auth.required")),
  })
  .required();

export default schema;
