/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable max-lines */
/* eslint-disable no-debugger */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import schemaInputs from "./schemaInputs";
import InputField from "../../components/input";
import {
  updatePriceOffer,
  getOfferById,
} from "../../ymc-sdk/redux/actions/offer.actions";
import Loading from "../../components/Loading";
import PaymentApiEndpoints from "../../ymc-sdk/services/terms.api";
import { getClientDataById } from "../../ymc-sdk/redux/actions/client.actions";
import { uploadPurchaseFile } from "../../ymc-sdk/redux/actions/searchOffer.actions";
import { getSystemConfiguration } from "../../ymc-sdk/redux/actions/config.actions";
import BinaryInput from "../../components/binary-input";

function OfferDetails({
  paymentTerms,
  setPaymentTerms,
  setUpdateForm,
  setOfferData,
  offerData,
  edit,
  setIssueFrom,
  setPdfLaNG,
  setIssueProduct,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.userData);
  const [poStatus, setPoStatus] = useState({ value: 0, label: "Pending" });
  const [attachment, setAttachment] = useState(null);
  const [rejectReason, setRejectReason] = useState(null);
  const [errs, setErrs] = useState(null);
  const [errsAttach, setErrsAttach] = useState(null);
  const {
    reset,
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schemaInputs) });

  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [clientid, setClientId] = useState(null);
  const { offerId } = useParams();
  const navigate = useNavigate();
  // const history = useHistory();

  const DateToday = (separator = "-") => {
    const dateObj = new Date();
    const month = `0${dateObj.getMonth() + 1}`.slice(-2);
    const day = `0${dateObj.getDate()}`.slice(-2);
    const year = dateObj.getFullYear();
    const shortDate = `${year}-${month}-${day}`;
    return shortDate;
  };
  // get payment terms
  const { data: allPaymentTerms } = useQuery(
    ["allPaymentTerms"],
    () => PaymentApiEndpoints.gePaymentTerms(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  useEffect(() => {
    reset({
      priceOfferDate: DateToday(),
      salesRepresentitiveName: auth?.FullName,
      // from data by client id
    });
  }, [DateToday(), auth, offerData]);
  useEffect(() => {
    dispatch(getOfferById(offerId))
      .then((res) => {
        setClientId(res?.Data?.ClientId);
        const newValues = { ...res?.Data };
        newValues.PaymentTerms = newValues.PaymentTerms?.Name;
        newValues.Client = newValues.Client?.ClientName;
        newValues.issueFrom = "0";
        newValues.IssueProduct = "0";
        newValues.pdfLang = "1";
        newValues.CreationDate = newValues?.CreationDate?.substring(0, 10);
        reset(newValues);
        setPaymentTerms(res?.Data?.PaymentTerms);
        setOfferData(res?.Data);
        res.Data.PaymentTerms.label = res?.Data?.PaymentTerms?.Name;
        if (res?.Data?.Status === 0) {
          setPoStatus({ value: 0, label: "Pending" });
        } else if (res?.Data?.Status === 1) {
          setPoStatus({ value: 1, label: "Accepted" });
        } else if (res?.Data?.Status === 2) {
          setPoStatus({ value: 2, label: "Rejected" });
        }
        if (res?.Data?.AttachmentUrl) {
          setAttachment(res?.Data?.AttachmentUrl);
        }
        if (res?.Data?.RejectReason) {
          setRejectReason(res?.Data?.RejectReason);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [offerId]);
  useEffect(() => {
    if (clientid) {
      reset(offerData);

      dispatch(getClientDataById(clientid))
        .then((res) => {
          // console.log(res.Data?.ContractualPaymentTerm);
          reset({
            ClientActualPaymentPeriod: res.Data?.ContractualPaymentTerm || "0",
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [clientid, offerData]);
  const onSubmit = (data) => {
    const userId = localStorage.getItem("UserId");
    data.lastUserModifyId = userId;
    data.createdBy = offerData.CreatedBy;
    data.paymentTermId = paymentTerms?.Id;
    data.PriceOfferSentTo = offerData?.PriceOfferSentTo;
    data.ClientNameInPO = offerData?.ClientNameInPO;
    data.ClientId = clientid;
    data.Status = poStatus.value;
    data.ExpectedDateForClientReply =
      configs.NumberOfDaysAfterCreatePriceOfferForClientReply;
    if (edit) {
      data.PriceOfferDate = offerData.PriceOfferDate;
    }
    if (attachment && poStatus.value === 1) {
      data.AttachmentUrl = attachment;
    }
    if (rejectReason && poStatus.value === 2) {
      data.RejectReason = rejectReason;
    }
    // console.log(data);
    /// ////////send client function
    // eslint-disable-next-line no-mixed-operators
    if (
      (poStatus?.value === 2 && !rejectReason) ||
      (poStatus?.value === 2 && rejectReason?.length < 0)
    ) {
      setErrs(t("auth.required"));
    } else if (poStatus?.value === 1 && !attachment) {
      setErrsAttach(t("auth.required"));
    } else {
      setLoading(true);
      dispatch(updatePriceOffer(offerId, { userId }, data))
        .then((res) => {
          setUpdateForm(true);
          dispatch(getOfferById(offerId)).then((res) => {
            setOfferData(res?.Data);
            setLoading(false);
            // navigate(-1);
          });
          toast.success(t("shared.updatedSuccessfully"));
        })
        .catch((error) => {
          setLoading(false);
          toast.error(t("shared.sorrySomethingWrong"));
        });
    }
  };
  useEffect(() => {
    dispatch(
      getSystemConfiguration("A72A0DD5-5D68-4C57-964A-3737FCB2F8E8")
    ).then((res) => {
      reset({
        NumberOfDaysAfterCreatePriceOfferForClientReply:
          res.Data?.NumberOfDaysAfterCreatePriceOfferForClientReply,
      });
      setConfigs(res.Data);
    });
  }, []);
  /// /////// data for edit only
  const stausOptions = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Accepted" },
    { value: 2, label: "Rejected" },
  ];
  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("files", file.files[0]);
    // console.log(file, 'ffffffffffffffffff');
    dispatch(uploadPurchaseFile(formData)).then((response) => {
      setAttachment(response.Data[0]);
      setErrsAttach(null);
    });
  };
  const stateReason = (val) => {
    setRejectReason(val);
    if (val.length > 0 && errs) {
      setErrs(null);
    }
  };
  // useEffect(() => {
  //   if (offerData) {
  //     const newValues = { ...offerData };
  //     newValues.PaymentTerms = newValues.PaymentTerms?.Name;
  //     newValues.Client = newValues.Client?.ClientName;
  //     newValues.issueFrom = '1';
  //     newValues.IssueProduct = '0';
  //     newValues.pdfLang = '0';
  //     newValues.CreationDate = newValues?.CreationDate?.substring(0, 10);
  //     console.log(newValues?.CreationDate?.substring(0, 10));
  //     reset(newValues);
  //     // Name
  //   }
  // }, [offerData]);
  /// //
  // pdf options
  const IssueProductWatch = watch("IssueProduct");
  const issueFromWatch = watch("issueFrom");
  const pdfLangWatch = watch("pdfLang");

  useEffect(() => {
    if (edit && issueFromWatch) {
      setIssueFrom(issueFromWatch);
    }
  }, [issueFromWatch]);
  useEffect(() => {
    if (edit && IssueProductWatch) {
      setIssueProduct(IssueProductWatch);
    }
  }, [IssueProductWatch]);
  useEffect(() => {
    if (edit && pdfLangWatch) {
      setPdfLaNG(pdfLangWatch);
    }
  }, [pdfLangWatch]);
  return (
    <div className="p-5">
      <Helmet>
        <title>{`YMC Group | ${t("priceOffer.offerDetails")} | ${
          offerData?.CreationDate?.substring(0, 10) || " "
        } | ${offerData?.Client?.ClientName || " "}`}</title>
      </Helmet>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="gx-5 gy-3">
          <Col md={3}>
            <Table>{t("priceOffer.priceOfferDate")}</Table>
            {edit ? (
              <div className="custom-date-input">
                {offerData?.PriceOfferDate?.substring(0, 10)}
              </div>
            ) : (
              <InputField
                name="price-offer-date"
                readOnly
                errors={errors}
                type="date"
                {...register("priceOfferDate")}
              />
            )}
          </Col>
          <Col md={3}>
            <Table>{t("priceOffer.priceOfferExpectedReplyDate")}</Table>
            <InputField
              readOnly
              placeholder={t("priceOffer.numberOfDays")}
              name="ExpectedDateForClientReply"
              errors={errors}
              type="number"
              {...register("NumberOfDaysAfterCreatePriceOfferForClientReply")}
            />
          </Col>
          <Col md={3}>
            <Table>{t("priceOffer.priceOfferValidity")}</Table>
            <InputField
              readOnly={!!edit}
              placeholder={t("priceOffer.numberOfHours")}
              name="PriceOfferValidity"
              errors={errors}
              type="number"
              {...register("PriceOfferValidity")}
            />
          </Col>
          <Col md={3}>
            <Table>{t("priceOffer.paymentTerms")}</Table>
            <Select
              defaultValue={paymentTerms}
              value={paymentTerms}
              onChange={setPaymentTerms}
              options={allPaymentTerms}
              placeholder={t("priceOffer.chooseClient")}
              isClearable
              isDisabled={!!edit}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
            />
          </Col>
          <Col md={3}>
            {/* //// user who is login */}
            <Table> {t("priceOffer.salesRepresentativeName")} </Table>
            <InputField
              placeholder={t("priceOffer.addYourName")}
              name="salesRepresentitiveName"
              errors={errors}
              readOnly
              type="text"
              {...register("salesRepresentitiveName")}
            />
          </Col>
          <Col md={3}>
            <Table>{t("priceOffer.salesRepresentativeNum")} </Table>
            <InputField
              readOnly={!!edit}
              placeholder={t("priceOffer.addYourNumber")}
              name="SalesRepresentitiveNumber"
              errors={errors}
              type="tel"
              {...register("SalesRepresentitiveNumber")}
            />
          </Col>
          <Col md={3}>
            <Table> {t("priceOffer.expectedDateToReceivePaymentCheck")} </Table>
            <InputField
              placeholder={t("priceOffer.expectedDateToReceivePaymentCheck")}
              name="ExptectedDateToRecievePaymentCheck"
              errors={errors}
              type="text"
              readOnly={!!edit}
              {...register("ExptectedDateToRecievePaymentCheck")}
            />
          </Col>
          <Col md={3}>
            <Table>{t("priceOffer.clientActualPaymentPeriod")}</Table>
            <InputField
              placeholder={t("priceOffer.clientActualPaymentPeriod")}
              name="ClientActualPaymentPeriod"
              errors={errors}
              type="number"
              readOnly
              {...register("ClientActualPaymentPeriod")}
            />
          </Col>
          <Col md={12}>
            <Table>{t("priceOffer.additionalInformation")}</Table>
            <textarea
              placeholder={t("priceOffer.writeSomethingHere")}
              rows="5"
              style={{ width: "100%" }}
              name="Notes"
              type="number"
              {...register("Notes")}
            />
          </Col>
          {edit && (
            <>
              <Col md={3}>
                <Table>{t("priceOffer.poStatus")} </Table>
                <Select
                  defaultValue={poStatus}
                  value={poStatus}
                  onChange={setPoStatus}
                  options={stausOptions}
                  placeholder="Po status"
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              </Col>
              {poStatus.value === 1 && (
                <Col md={3}>
                  <labe className="pb-3 d-block">
                    {" "}
                    {t("priceOffer.uploadPurchase")}{" "}
                  </labe>
                  <input type="file" onChange={(e) => uploadFile(e.target)} />
                  {attachment && (
                    <a
                      target="_blank"
                      href={`https://backendapi.ymcgroup.org/${attachment}`}
                      rel="noreferrer"
                    >
                      {" "}
                      PDF
                    </a>
                  )}
                  {errsAttach && (
                    <p className="text-danger m-0"> {errsAttach} </p>
                  )}
                </Col>
              )}
              {poStatus.value === 2 && (
                <Col md={3}>
                  <div className="outlined-input">
                    <labe className="pb-3 d-block">
                      {" "}
                      {t("priceOffer.stateRejection")}{" "}
                    </labe>
                    <input
                      onChange={(e) => stateReason(e.target.value)}
                      value={rejectReason}
                      className="form-control"
                      type="text"
                      placeholder="State reason of rejection"
                    />
                    {errs && <p className="text-danger m-0"> {errs} </p>}
                  </div>
                </Col>
              )}
              <Col md={3}>
                <Table> {t("priceOffer.issueProductsAs")} </Table>
                <BinaryInput
                  id={1}
                  label={t("priceOffer.productNameSystem9")}
                  type="radio"
                  value="0"
                  className="cancellation-policy-label"
                  name="IssueProduct"
                  disabled
                  {...register("IssueProduct")}
                />
                <BinaryInput
                  id={2}
                  label={t("priceOffer.productNameWritten")}
                  type="radio"
                  value="1"
                  className="cancellation-policy-label"
                  name="IssueProduct"
                  disabled
                  {...register("IssueProduct")}
                />
              </Col>
              <Col md={3}>
                <Table> {t("priceOffer.issuePoAs")}</Table>
                <BinaryInput
                  id={1}
                  label={t("priceOffer.yasserMohamedCompany")}
                  type="radio"
                  value="0"
                  className="cancellation-policy-label"
                  name="issueFrom"
                  disabled
                  {...register("issueFrom")}
                />
                <BinaryInput
                  id={2}
                  label={t("priceOffer.yasserMohamedName")}
                  type="radio"
                  value="1"
                  className="cancellation-policy-label"
                  name="issueFrom"
                  disabled
                  {...register("issueFrom")}
                />
              </Col>
              <Col md={3}>
                <Table> {t("priceOffer.choosePdfLang")} </Table>
                <BinaryInput
                  id={1}
                  label={t("priceOffer.english")}
                  type="radio"
                  value="1"
                  className="cancellation-policy-label"
                  name="pdfLang"
                  disabled
                  {...register("pdfLang")}
                />
                <BinaryInput
                  id={2}
                  label={t("priceOffer.arabic")}
                  type="radio"
                  value="0"
                  className="cancellation-policy-label"
                  name="pdfLang"
                  disabled
                  {...register("pdfLang")}
                />
              </Col>
            </>
          )}
          <Col md={12}>
            <Row>
              <Col md={edit ? 6 : 12}>
                <button
                  type="submit"
                  className="main-button primary btn d-block w-30 m-auto"
                >
                  {" "}
                  {t("priceOffer.update")}{" "}
                </button>
              </Col>
              {edit && (
                <Col md={6}>
                  <Link
                    to="/offerSearch"
                    type="submit"
                    className="main-button primary btn d-block w-30 m-auto"
                  >
                    {" "}
                    {t("priceOffer.backToSearch")}{" "}
                  </Link>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
      {loading && <Loading />}
    </div>
  );
}

export default OfferDetails;
// sales representative number : mobile number
