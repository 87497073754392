/* eslint-disable comma-dangle */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Col, Form, Row } from "reactstrap";
import Select from "react-select";
import { toast } from "react-hot-toast";
import Loading from "../../components/Loading";
import ClientContacts from "../createOffer/ClientContacts/ClientContacts";
import { getClientDataById } from "../../ymc-sdk/redux/actions/client.actions";
import Api from "../../ymc-sdk/services";
import Users from "./users";
import AuthApiEndpoints from "../../ymc-sdk/services/auth.api";
import Roles from "./roles";

function UserSettings() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [isloading, setLoading] = useState(false);
  const [clientData, setClientData] = useState({});

  const { data: AllRoles, isLoading } = useQuery(
    ["user-roles", i18n.language],
    AuthApiEndpoints.getRoles,
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );

  return (
    <div className="auth-modal body-style mt-3 px-5 py-3">
      <Helmet>
        <title>{`YMC Group | ${ t("userSettings.userSettings")}`}</title>
      </Helmet>
      <div className="text-center mb-4">
        <h2 className="">{t("userSettings.userSettings")}</h2>
      </div>
      <Tabs
        defaultActiveKey="users"
        id="uncontrolled-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="users" title={t("userSettings.users")}>
          <Users AllRoles={AllRoles} />
        </Tab>
        <Tab eventKey="roles" title={t("userSettings.roles")}>
          <Roles AllRoles={AllRoles} />
        </Tab>
      </Tabs>
      {isloading && <Loading />}
    </div>
  );
}
export default UserSettings;
