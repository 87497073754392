/* eslint-disable import/prefer-default-export */
import Api from '../../services';

export const updateTermsById = (id, params, data) => {
  return (dispatch) => {
    return Api.config.updateTermsById(id, params, data).then((response) => {
      dispatch({
        type: 'CREATE_CLIENT',
        data: response,
      });
      return response;
    });
  };
};
export const getSystemConfiguration = (id) => {
  return (dispatch) => {
    return Api.config.getSystemConfiguration(id).then((response) => {
      dispatch({
        type: 'CREATE_CLIENT',
        data: response,
      });
      return response;
    });
  };
};

export const UpdateSystemConfiguration = (params, data) => {
  return (dispatch) => {
    return Api.config.UpdateSystemConfiguration(params, data).then((response) => {
      dispatch({
        type: 'CREATE_CLIENT',
        data: response,
      });
      return response;
    });
  };
};

export const updateClientsSystem9 = (id) => {
  return (dispatch) => {
    return Api.config.updateClientsSystem9(id).then((response) => {
      dispatch({
        type: 'UPDATE_CLIENTS',
        data: response,
      });
      return response;
    });
  };
};
export const updateProductsSystem9 = (id) => {
  return (dispatch) => {
    return Api.config.updateProductsSystem9(id).then((response) => {
      dispatch({
        type: 'UPDATE_PRODUCS',
        data: response,
      });
      return response;
    });
  };
};
