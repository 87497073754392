/* eslint-disable comma-dangle */
import * as yup from "yup";
import i18n from "i18next";

const schema = yup

  .object({
    UserName: yup
      .string()
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        "Username can contain lowercase letters, digits (0-9), underscores, and hyphens only"
      )
      .required(() => i18n.t("auth.required")),
    FullName: yup.string().required(() => i18n.t("auth.required")),
    PhoneNumber: yup
      .string()
      .required(() => i18n.t("auth.required"))
      .matches(/^01[0125][0-9]{8}$/, "Phone number is not valid."),
    RoleId: yup.string().required(() => i18n.t("auth.required")),
  })
  .required();

export default schema;
