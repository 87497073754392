/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-curly-newline */
import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, FormGroup, FormFeedback, Row, Col } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { ErrorMessage } from "@hookform/error-message";
import InputField from "../../components/input";
import {
  changeUserPassword,
  logOut,
  signUp,
} from "../../ymc-sdk/redux/actions/auth.actions";
import Loading from "../../components/Loading";
import schema from "./changePasswordSchema";

function ChangePassword({ userId, userName, onCloseModal, ...props }) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const CreatedBy = localStorage.getItem("UserId");
  const navigate = useNavigate();

  const {
    reset,
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

  const encryptPass = (pass) => {
    return btoa(pass);
  };

  const onSubmit = (data) => {
    data.NewPassword = encryptPass(data.NewPassword);
    data.ConfirmPassword = encryptPass(data.ConfirmPassword);

    setLoading(true);
    dispatch(changeUserPassword(userId, data))
      .then((res) => {
        // console.log(res);
        setLoading(false);
        if (CreatedBy === userId) {
          dispatch(logOut());
          navigate("/login");
        }
        reset();
        props.onHide();
        onCloseModal();
        toast.success(t("userSettings.changePasswordSuccessfully"));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`${t("userSettings.changePassword")} -> `}
          <span className="text-danger fw-bolder fs-2">{userName}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form_login" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col className="position-relative" md={12}>
              <InputField
                placeholder={t("userSettings.newPassword")}
                name="NewPassword"
                errors={errors}
                type="Password"
                {...register("NewPassword")}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col className="position-relative" md={12}>
              <InputField
                placeholder={t("userSettings.confirmPassword")}
                name="ConfirmPassword"
                errors={errors}
                type="Password"
                {...register("ConfirmPassword")}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col md={12}>
              <button
                type="submit"
                className="main-button primary d-block btn m-auto mt-5"
              >
                {t("userSettings.changePassword")}
              </button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {loading && <Loading />}
    </Modal>
  );
}

export default ChangePassword;
