import * as yup from 'yup';
import i18n from 'i18next';

const schema = yup
  .object({
    PersonName: yup.string().required(() => i18n.t('auth.required')),
    PersonNumber: yup.string().required(() => i18n.t('auth.required'))
      .matches(
        /^01[0125][0-9]{8}$/,
        i18n.t('auth.phoneRequired'),
      ),
    PersonPosition: yup.string().required(() => i18n.t('auth.required')),
  })
  .required();

export default schema;
