import * as yup from 'yup';
import i18n from 'i18next';

const schema = yup
  .object({
    ClientName: yup.string().required(() => i18n.t('auth.required')),
    ClientNameInPO: yup.string().required(() => i18n.t('auth.required')),
    priceOfferSentTo: yup.string().required(() => i18n.t('auth.required')),
    ClientType: yup.string().required(() => i18n.t('auth.required')),
    // Industry_Name: yup.string().required(() => i18n.t('auth.required')),
    // Client_Address: yup.string().required(() => i18n.t('auth.required')),
  })
  .required();

export default schema;
