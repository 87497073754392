/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Text, View, StyleSheet, Image,
} from '@react-pdf/renderer';
import Company from '../../../assets/images/Header/Company.png';
import Person from '../../../assets/images/Header/Person.png';

function BodyBottomContent({ offerData, pdfLang, issueFrom }) {
  const styles = StyleSheet.create({
    container: {
      margin: '20px 0',
      fontSize: '11px',
      position: 'relative',
    },
    flex: {
      display: 'flex',
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      margin: '5px 0',
    },
    underline: {
      fontStyle: 'bold',
      textDecoration: 'underline',
      fontSize: '11px',
    },
    rate: {
      width: '20%',
      padding: '10px',
    },
    stamp: {
      position: 'absolute',
      bottom: '-115px',
      left: pdfLang === '1' ? 'auto' : '00px',
      right: pdfLang === '1' ? '10px' : 'auto',
      zIndex: '5',
      display: 'flex',
      width: '200px',
    },
    point: {
      width: '4px',
      height: '4px',
      backgroundColor: '#000',
      borderRadius: '50%',
      marginTop: '4px',
      marginLeft: pdfLang === '1' ? 0 : '4px',
      marginRight: pdfLang === '1' ? '4px' : 0,
    },
  });
  return (
    <View style={styles.container}>
      <View style={styles.flex}>
        {pdfLang === '1' ? (
          <Text>
            *The company is subject to Advance Payment Tax System
          </Text>
        )
          : (
            <View style={{ ...styles.flex, margin: '0' }}>
              <Text>
                {' '}
                {' '}
                *
              </Text>
              {' '}
              <Text> تخضع الشركه لنظام الدفعات المقدمه </Text>
            </View>
          )}
      </View>
      <View style={styles.flex}>
        <Text style={styles.point} />
        <Text style={styles.underline}>
          {' '}
          {pdfLang === '1' ? 'Price offer validity:' : 'صلاحيه عرض السعر:'}
        </Text>
        <View style={{ ...styles.flex, margin: '0' }}>
          <Text className="px-2 m-0">
            {' '}

            {pdfLang === '1' ? 'price offer valid for' : ' عرض السعر ساري لمده'}

          </Text>
          <Text className="px-2 m-0">
            {' '}

            {' '}
            {offerData?.PriceOfferValidity}
            {' '}
          </Text>
        </View>
        <Text className="px-2 m-0">
          {pdfLang === '1' ? 'Hours' : 'ساعه'}

        </Text>
        {/* <Text className="px-2 m-0">
          {' '}
          {' '}

          {pdfLang === '1' ? 'Hours' : 'ساعه'}
        </Text> */}
      </View>

      <View style={styles.flex}>
        <Text style={styles.point} />
        <Text style={styles.underline}>
          {' '}
          {pdfLang === '1' ? 'Payment terms:' : 'شروط الدفع:'}
        </Text>
        <View style={{ ...styles.flex, margin: '0' }}>
          {' '}

          <Text>
            {' '}
            {offerData?.PaymentTerms?.Name}
          </Text>
          <Text>
            {' '}
            {' '}
            ,
            {' '}
          </Text>

          <Text>
            {pdfLang === '1' ? ' bank transfer or payable check received' : ' نقدا او بتحويل بنكي او من خلال شيك '}

          </Text>

          {' '}
          <Text>
            {' '}
            {offerData?.ExptectedDateToRecievePaymentCheck}
          </Text>

        </View>
      </View>

      <View style={styles.flex}>
        <Text style={styles.point} />
        <Text className="m-0">
          {' '}
          {pdfLang === '1' ? 'Prices don’t include VAT 14%' : 'الأسعار غير شامله القيمه المضافه 41% '}
          {' '}
        </Text>
      </View>

      <View style={styles.flex}>
        {' '}
        <Text style={styles.point} />
        {' '}
        {pdfLang === '1'
          ? (
            <Text>
              Prices stated are valid unless there is no price change by the production companies, provided availability of products at the production companies

            </Text>
          )
          : (
            <Text>
              الأسعار ساريه ما لم يكن هناك تغيير في الاسعار من قبل الشركات المنتجه مع شروط توافر المنتجات لدى الشركه المنتجه
            </Text>
          )}
      </View>

      <View style={styles.flex}>
        <Text style={styles.point} />
        {/* <p className="m-0">any additional notes : </p> */}
        <Text style={{ whiteSpace: 'pre-wrap', marginBottom: '5px' }}>{offerData?.Notes}</Text>
      </View>

      <View style={styles.flex}>
        <Text>
          {pdfLang === '1' ? 'If you have any questions or comments, please dont hesitate to contact us' : 'في حال وجود اي اسئله او ملاحظات يرجى عدم التردد في الاتصال بنا '}
        </Text>
      </View>
      <View style={styles.flex}>
        <Text>
          {' '}
          {offerData?.SalesRepresentitiveName}
          {' '}
          {offerData?.SalesRepresentitiveNumber}
        </Text>
      </View>
      <Image
        src={issueFrom === '1' ? Person : Company}
        alt=""
        className="img-fluid w-100"
        style={styles.stamp}
      />
    </View>
  );
}

export default BodyBottomContent;
