/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Loading from "../../components/Loading";
import Api from "../../ymc-sdk/services";
import PendingTasks from "./pendingTasks";
import PoNotifications from "./poNotifications";
import ProductsPONumber from "./productsPONumber";

function Home() {
  const { t } = useTranslation();

  return (
    <div className="auth-modal body-style mt-3 px-5">
      <Helmet>
        <title>{`YMC Group | ${ t("navBar.home")}`}</title>
      </Helmet>
      <h1 style={{ fontSize: "45px" }}>{t("home.notifications")}</h1>
      <PoNotifications />
      <PendingTasks />
      <ProductsPONumber />
    </div>
  );
}
export default Home;
