/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-cycle
import Api from "../../services";

export const getUserData = (data) => {
  return (dispatch) => {
    return Api.auth.getUserData(data).then((response) => {
      response.Data = {
        ...response.Data,
        PageActions: response.Data.PagesActions,
      };
      dispatch({
        type: "GET_USER",
        data: response,
      });
      return response;
    });
  };
};
export const login = (data) => {
  return (dispatch) => {
    return Api.auth.login(data).then((response) => {
      response.Data = {
        ...response.Data,
        PageActions: response.Data.PagesActions,
        Id: response.Data.UserId,
      };
      dispatch({
        type: "LOGIN",
        data: response,
      });
      dispatch(getUserData(response.Data.UserId));
      return response;
    });
  };
};
export const signUp = (data) => {
  return (dispatch) => {
    return Api.auth.signUp(data).then((response) => {
      dispatch({
        type: "SIGN_UP",
        data: response,
      });
      return response;
    });
  };
};
export const logOut = (data) => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
      data: "logout",
    });
    return "logout";
  };
};
export const deleteUserById = (userId, deletedById) => {
  return (dispatch) => {
    return Api.auth.deleteUserById(userId, deletedById).then((response) => {
      dispatch({
        type: "DELETE_USER",
        data: response,
      });
      return response;
    });
  };
};
export const updateUserData = (userId, data) => {
  return (dispatch) => {
    return Api.auth.updateUserData(userId, data).then((response) => {
      dispatch({
        type: "UPDATE_USER",
        data: response,
      });
      return response;
    });
  };
};

export const changeUserPassword = (userId, data) => {
  return (dispatch) => {
    return Api.auth.changeUserPassword(userId, data).then((response) => {
      dispatch({
        type: "CHANGE_USER_PASSWORD",
        data: response,
      });
      return response;
    });
  };
};
