import { combineReducers } from 'redux';
import AuthReducer from './auth.reducer';
import sharedReducer from './shared.reducer';

const rootReducer = combineReducers({
  shared: sharedReducer,
  auth: AuthReducer,
});

export default rootReducer;
