/* eslint-disable import/prefer-default-export */
import Api from '../../services';

export const cretePriceOffer = (params, data) => {
  return (dispatch) => {
    return Api.offer.cretePriceOffer(params, data).then((response) => {
      dispatch({
        type: 'CREATE_OFFER',
        data: response,
      });
      return response;
    });
  };
};
export const updatePriceOffer = (id, params, data) => {
  return (dispatch) => {
    return Api.offer.updatePriceOffer(id, params, data).then((response) => {
      dispatch({
        type: 'UPDATE_OFFER',
        data: response,
      });
      return response;
    });
  };
};
export const getOfferById = (id) => {
  return (dispatch) => {
    return Api.offer.getOfferById(id).then((response) => {
      dispatch({
        type: 'UPDATE_OFFER',
        data: response,
      });
      return response;
    });
  };
};
export const productGetQuantity = (code) => {
  return (dispatch) => {
    return Api.offer.productGetQuantity(code).then((response) => {
      dispatch({
        type: 'UPDATE_OFFER',
        data: response,
      });
      return response;
    });
  };
};
export const checkProductPriceMatching = (data) => {
  return (dispatch) => {
    return Api.offer.checkProductPriceMatching(data).then((response) => {
      dispatch({
        type: 'UPDATE_OFFER',
        data: response,
      });
      return response;
    });
  };
};
export const createPriceOfferItem = (params, data) => {
  return (dispatch) => {
    return Api.offer.createPriceOfferItem(params, data).then((response) => {
      dispatch({
        type: 'UPDATE_OFFER',
        data: response,
      });
      return response;
    });
  };
};
export const deletePriceOfferItem = (id, params) => {
  return (dispatch) => {
    return Api.offer.deletePriceOfferItem(id, params).then((response) => {
      dispatch({
        type: 'DELETE_CONTACT',
        data: response,
      });
      return response;
    });
  };
};
