/* eslint-disable comma-dangle */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as ReactDOMClient from "react-dom/client";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import StorageService from "./ymc-sdk/storage-service";
import LocaleService from "./ymc-sdk/locale-service";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LocalStorageProvider from "./localStorageProvider";
import LocaleResolver from "./localeResolver";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min"; // Import Bootstrap JS
import "./assets/scss/main.scss";
import "./i18n";
import store from "./ymc-sdk/redux/storeConfig";

const queryClient = new QueryClient();
StorageService.setStorageProvider(LocalStorageProvider);
LocaleService.setLocaleResolver(LocaleResolver);

const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
