/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import AuthApiEndpoints from "../../ymc-sdk/services/auth.api";
import Loading from "../../components/Loading";
import AddNewUser from "./addNewUser";
import DeleteUser from "./deleteUser";
import EditUser from "./editUser";
import ChangePassword from "./changePassword";
import InputField from "../../components/input";

function Users({ AllRoles }) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [isloading, setLoading] = useState(false);
  const [addingModalShow, setAddingModalShow] = useState(false);
  const [deletedModalShow, setDeletedModalShow] = useState(false);
  const [deletedUser, setDeletedUser] = useState({});
  const [editedModalShow, setEditedModalShow] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  const [changedPasswordModalShow, setChangedPasswordModalShow] =
    useState(false);
  const [changedPasswordUser, setChangedPasswordUser] = useState({});
  const [adminUsersList, setAdminUsersList] = useState([]);
  const [adminUsersTableList, setAdminUsersTableList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const { data: adminUsers, isLoading } = useQuery(
    ["adminUsers"],
    () => AuthApiEndpoints.getSystemUsers(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  const queryClient = useQueryClient();

  const handleCloseModalAndRefetch = () => {
    queryClient.invalidateQueries("adminUsers");
    // Close the modal (you can implement modal closing logic here)
  };

  useEffect(() => {
    // console.log(adminUsers);
    setAdminUsersList(adminUsers);
    setAdminUsersTableList(adminUsers);
  }, [adminUsers]);
  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearchText(inputValue);
    if (inputValue.length > 0) {
      const searchList = adminUsersList.filter(
        (us) =>
          us.UserName?.toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          us.FullName?.toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          us.PhoneNumber?.toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase()) ||
          us.UserRoles?.[0]?.Role?.Name?.toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase())
      );
      setAdminUsersTableList(searchList);
    } else {
      const searchList = adminUsersList;
      setAdminUsersTableList(searchList);
    }
  };
  return (
    <div className="">
      <div className="row d-flex justify-content-end">
        <div className="col-12 col-md-6 order-2 order-md-1">
          <label className="mb-2 w-100" htmlFor="search">
            {t("userSettings.search")}
          </label>
          <InputField
            placeholder={t("userSettings.search")}
            name="search"
            type="search"
            value={searchText}
            onChange={handleSearch}
            className="w-100"
          />
        </div>
        <div className="col-12 col-md-6 order-1 order-md-2">
          {pageActions?.includes("userSettings_Create") && (
            <Button
              size="md"
              className="main-button primary btn d-block m-auto me-2"
              disabled={!pageActions?.includes("userSettings_Create")}
              onClick={() => setAddingModalShow(true)}
            >
              {t("userSettings.addNewUser")}
            </Button>
          )}
        </div>
      </div>
      {pageActions?.includes("userSettings_ListView") && (
        <Table striped bordered hover className="mt-3 table-responsive mb-0">
          <thead className="text-white" style={{ backgroundColor: "#333" }}>
            <tr>
              <th
                className="bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("userSettings.userName")}
              </th>
              <th
                className="bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("userSettings.fullName")}
              </th>
              <th
                className="bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("userSettings.phoneNumber")}
              </th>
              <th
                className="bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("userSettings.role")}
              </th>
              <th
                className="bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("userSettings.manager")}
              </th>
              {pageActions?.includes("userSettings_Update") && (
                <>
                  <th
                    className="bg-dark text-white"
                    style={{ verticalAlign: "middle" }}
                  >
                    {t("userSettings.editUser")}
                  </th>
                  <th
                    className="bg-dark text-white"
                    style={{ verticalAlign: "middle" }}
                  >
                    {t("userSettings.changePassword")}
                  </th>
                </>
              )}
              {pageActions?.includes("userSettings_Delete") && (
                <th
                  className="bg-dark text-white"
                  style={{ verticalAlign: "middle" }}
                >
                  {t("userSettings.deleteUser")}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {adminUsersTableList?.map((user) => (
              <tr key={user.Id}>
                <td>{user.UserName}</td>
                <td>{user.FullName}</td>
                <td>{user.PhoneNumber}</td>
                <td>{user.UserRoles[0]?.Role?.Name}</td>
                <td>
                  {user.ManagerId &&
                    adminUsers?.find((us) => us?.Id === user.ManagerId)
                      ?.FullName}
                </td>
                {pageActions?.includes("userSettings_Update") && (
                  <td>
                    <Button
                      size="sm"
                      variant="success"
                      className="me-2"
                      disabled={!pageActions?.includes("userSettings_Update")}
                      onClick={() => {
                        setEditedUser(user);
                        setEditedModalShow(true);
                      }}
                    >
                      {t("userSettings.editUser")}
                    </Button>
                  </td>
                )}
                {pageActions?.includes("userSettings_Update") && (
                  <td>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      className="me-2"
                      disabled={!pageActions?.includes("userSettings_Update")}
                      onClick={() => {
                        setChangedPasswordUser(user);
                        setChangedPasswordModalShow(true);
                      }}
                    >
                      {t("userSettings.changePassword")}
                    </Button>
                  </td>
                )}
                {pageActions?.includes("userSettings_Delete") && (
                  <td>
                    <div className="d-flex">
                      <Button
                        size="md"
                        variant="danger"
                        className="me-2"
                        disabled={!pageActions?.includes("userSettings_Delete")}
                        onClick={() => {
                          setDeletedUser({
                            userId: user.Id,
                            userName: user.UserName,
                          });
                          setDeletedModalShow(true);
                        }}
                      >
                        {t("userSettings.deleteUser")}
                      </Button>
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <AddNewUser
        AllRoles={AllRoles}
        managerUsers={adminUsers?.filter(
          (us) => us?.UserRoles[0]?.Role?.Name === "Manager"
        )}
        onCloseModal={handleCloseModalAndRefetch}
        show={addingModalShow}
        onHide={() => setAddingModalShow(false)}
      />
      <DeleteUser
        userId={deletedUser?.userId}
        userName={deletedUser?.userName}
        onCloseModal={handleCloseModalAndRefetch}
        show={deletedModalShow}
        onHide={() => setDeletedModalShow(false)}
      />
      <EditUser
        AllRoles={AllRoles}
        userData={editedUser}
        managerUsers={adminUsers?.filter(
          (us) => us?.UserRoles[0]?.Role?.Name === "Manager"
        )}
        onCloseModal={handleCloseModalAndRefetch}
        show={editedModalShow}
        onHide={() => setEditedModalShow(false)}
      />
      <ChangePassword
        userId={changedPasswordUser?.Id}
        userName={changedPasswordUser?.UserName}
        onCloseModal={handleCloseModalAndRefetch}
        show={changedPasswordModalShow}
        onHide={() => setChangedPasswordModalShow(false)}
      />
      {isloading && <Loading />}
      {isLoading && <Loading />}
    </div>
  );
}

export default Users;
