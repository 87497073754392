/* eslint-disable object-curly-newline */
import React, { useState, useCallback } from "react";
import { Form, Modal, ModalBody, Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";
import schema from "./schema";
import InputField from "../../components/input";
import LogoOrLang from "../../components/Language";
import Logo from "../../assets/images/Logo.png";
import { login } from "../../ymc-sdk/redux/actions/auth.actions";
import { setServerErrors } from "../../helpers";
import Loading from "../../components/Loading";

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });
  const encryptPass = (pass) => {
    return btoa(pass);
  };
  const onSubmit = (data) => {
    setLoading(true);
    data.password = encryptPass(data.password);
    // console.log(data);
    dispatch(login(data))
      .then((res) => {
        localStorage.setItem("UserId", res.Data.UserId);
        setLoading(false);
        // console.log(res.Data);
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        const responseErrors = {
          userName: {
            field: "userName",
            message: "please check your credintials and try agaim",
          },
        };
        setServerErrors(responseErrors, setError);
      });
  };

  return (
    <div className="auth-modal login ">
      <Helmet>
        <title>{`YMC Group | ${ t("auth.login")}`}</title>
      </Helmet>
      <Form className="form_login" onSubmit={handleSubmit(onSubmit)}>
        <div className="logo_lang">
          <img src={Logo} alt="logo" />
          <LogoOrLang />
        </div>
        <Row>
          <Col md={12}>
            <InputField
              placeholder={t("auth.email")}
              name="userName"
              errors={errors}
              type="text"
              {...register("userName")}
            />
          </Col>
          <Col className="position-relative" md={12}>
            <InputField
              placeholder={t("auth.password")}
              name="password"
              errors={errors}
              type="password"
              {...register("password")}
              wrapperClassName="ltr-input"
            />
          </Col>
          <Col md={12}>
            <button
              type="submit"
              className="main-button primary d-block btn m-auto"
            >
              {t("auth.login")}
            </button>
          </Col>
        </Row>
      </Form>
      {loading && <Loading />}
    </div>
  );
}

export default Login;
