import React, { useState, useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { deleteClientById, deleteContactClientById } from "../../ymc-sdk/redux/actions/client.actions";
import Loading from "../../components/Loading";

function DeleteClient({ clientId, ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    const userId = localStorage.getItem("UserId");
    setLoading(true);
    dispatch(deleteClientById(clientId, { userId }))
      .then((res) => {
        setLoading(false);
        toast.success(t("shared.deletedSuccessfully"));
        navigate(`/clientSearch`);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("clients.deleteClient")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("clients.areYouWantToDeleteThisClient")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button size="md" variant="danger" className="me-2" onClick={handleDelete}>
          {t("clients.deleteClient")}
        </Button>
        <Button onClick={props.onHide} variant="secondary">{t("shared.cancel")}</Button>
      </Modal.Footer>
      {loading && <Loading />}
    </Modal>
  );
}

export default DeleteClient;
