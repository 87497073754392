import * as yup from "yup";
import i18n from "i18next";

const schema = yup
  .object({
    UserId: yup.string().required(() => i18n.t("auth.required")),
  })
  .required();

export default schema;
