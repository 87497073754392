/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useQuery } from 'react-query';
import schema from './schemaNewClient';
import NewClient from './NewClient';
import CurrentClient from './CurrentClient';
import ClientContacts from './ClientContacts/ClientContacts';
import OfferApiEndpoints from '../../ymc-sdk/services/offer.api';
import ClientsApiEndpoints from '../../ymc-sdk/services/client.api';

function CreateOffer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clientId, stetClientId] = useState(null);
  const [offerId, setOfferId] = useState(null);
  // get regions
  const { data: regions } = useQuery(
    ['Regions'],
    () => OfferApiEndpoints.getRegions(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    },
  );
    // get clients system 9
  const { data: clients9 } = useQuery(
    ['Clients 9'],
    () => ClientsApiEndpoints.getClientsSystem9(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    },
  );
  // get clients system 9
  const { data: clientsYMC, isLoading } = useQuery(
    ['Clients YMC'],
    () => ClientsApiEndpoints.getClientsYMC(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    },
  );

  return (
    <div className="auth-modal body-style mt-3 p-5">
      <Helmet>
        <title>{`YMC Group | ${ t('priceOffer.createOffer')}`}</title>
      </Helmet>
      <div className="text-center mb-4">
        <h2 className="">
          {' '}
          {t('priceOffer.createOffer')}
        </h2>
      </div>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" title={t('priceOffer.newClient')}>
          {clientId
            ? <ClientContacts clientId={clientId} offerId={offerId} />
            : <NewClient stetClientId={stetClientId} setOfferId={setOfferId} regions={regions} clientsYMC={clientsYMC} />}

        </Tab>
        <Tab eventKey="profile" title={t('priceOffer.currentClient')}>
          <CurrentClient clients9={clients9} isLoading={isLoading} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default CreateOffer;
