import HttpHelpers from './helpers';

const PaymentApiEndpoints = {
  gePaymentTerms: () => {
    return HttpHelpers.authenticatedAxios
      .get('PaymentTerm')
      .then((response) => {
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          el.label = el.Name;
          arrayData.push(el);
        });
        return arrayData;
      });
  },
  updateTermsById: (id, params, data) => {
    return HttpHelpers.authenticatedAxios
      .put(`PaymentTerm/${id}`, data, { params })
      .then((response) => response.data);
  },
  deleteContactClientById: (id, params) => {
    return HttpHelpers.authenticatedAxios
      .delete(`ClientContactDetails/HardDelete/${id}`, { params })
      .then((response) => response.data);
  },
  getSystemConfiguration: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`SystemConfiguration/GetSystemConfigurationById/${id}`)
      .then((response) => response.data);
  },
  UpdateSystemConfiguration: (params, data) => {
    return HttpHelpers.authenticatedAxios
      .post('SystemConfiguration/CreateOrUpdateSystemConfiguration', data, { params })
      .then((response) => response.data);
  },
  updateClientsSystem9: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`ClientSystem9/UpdateSystem9Clients/${id}`)
      .then((response) => {
        return response;
      });
  },
  updateProductsSystem9: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`ProductSystem9/UpdateSystem9Products/${id}`)
      .then((response) => {
        return response;
      });
  },
};

export default PaymentApiEndpoints;
