/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Table, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import InputField from "../../components/input";
import { updateTermsById } from "../../ymc-sdk/redux/actions/config.actions";
import Loading from "../../components/Loading";

function TermsInput({ term, name }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);

  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const onSubmit = (data) => {
    setLoading(true);
    const onjData = {};
    const userId = localStorage.getItem("UserId");
    onjData.createdBy = term.CreatedBy;
    onjData.lastUserModifyId = userId;
    onjData.Percentage = data.Name;
    onjData.name = term.Name;
    // console.log(data, 'vvvvvvvvvvvvvvvvv');

    dispatch(updateTermsById(term.Id, { userId, id: term.Id }, onjData))
      .then((res) => {
        setLoading(false);
        toast.success(t("shared.updatedSuccessfully"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };

  useEffect(() => {
    if (term) {
      const resetObj = {};
      reset({ Name: term.Percentage });
      // console.log(resetObj, 'vvvvvvvvvvvvvvvv');
    }
  }, [term]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="gx-0 align-items-center">
        <Col md={8}>
          <Label>{term.Name}</Label>
          <InputField
            name={term.Name}
            placeholder={term.Name}
            errors={errors}
            type="test"
            readOnly={!pageActions?.includes("config_Update")}
            {...register("Name")}
            // value={term.Name}
          />
        </Col>
        {pageActions?.includes("config_Update") && (
          <Col md={4}>
            <button
              type="submit"
              className="main-button primary btn d-block m-auto"
              disabled={!pageActions?.includes("config_Update")}
            >
              {" "}
              {t("priceOffer.update")}
            </button>
          </Col>
        )}
      </Row>
      {loading && <Loading />}
    </Form>
  );
}

export default TermsInput;
