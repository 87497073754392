/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import OfferDetails from "./OfferDetails";

import CompleteOfferDetails from "./CompleteOfferDetails";
import { getSystemConfiguration } from "../../ymc-sdk/redux/actions/config.actions";

function OfferDetailsPage() {
  const { t } = useTranslation();
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [updateForm, setUpdateForm] = useState(null);
  const [offerData, setOfferData] = useState(null);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getSystemConfiguration('A72A0DD5-5D68-4C57-964A-3737FCB2F8E8')).then((res) => {
  //     // setConfigDataData(res.Data);
  //   });
  // }, []);
  return (
    <div className="body-style">
      <OfferDetails
        setPaymentTerms={setPaymentTerms}
        paymentTerms={paymentTerms}
        setUpdateForm={setUpdateForm}
        offerData={offerData}
        setOfferData={setOfferData}
      />
      {updateForm || offerData?.PriceOfferItems?.length > 0 ? (
        <CompleteOfferDetails
          paymentTerms={paymentTerms}
          updateForm={updateForm}
          offerData={offerData}
          setOfferData={setOfferData}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default OfferDetailsPage;
