/* eslint-disable comma-dangle */
import axios from "axios";
import LocaleService from "../locale-service";
import StorageService from "../storage-service";

class HttpHelpers {
  constructor() {
    this.subscribers = [];
  }

  setBaseUrl(apiBaseUrl) {
    this.apiBaseUrl = apiBaseUrl;
    this.authenticatedAxios = axios.create({ baseURL: this.apiBaseUrl });
    this.unAuthenticatedAxios = axios.create({ baseURL: this.apiBaseUrl });
    this.addAuthenticationInterceptor();
    this.addUnauthenticationInterceptor();
    this.addResponseInterceptor();
  }

  getToken() {
    return StorageService.get("Token");
  }

  getLocale() {
    return LocaleService.get();
  }

  // getCurrency() {
  //   return StorageService.get('currency');
  // }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(
      (callback) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        callback(accessToken)
      // eslint-disable-next-line function-paren-newline
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  addAuthenticationInterceptor() {
    this.authenticatedAxios.interceptors.request.use(
      async (config) => {
        const locale = await this.getLocale();
        config.headers["Accept-Language"] = locale;

        // const currency = await this.getCurrency();
        // config.headers['accept-currency'] = currency;
        // ** Get token from AsyncStorage/LocalStorage
        const accessToken = await this.getToken();
        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  addUnauthenticationInterceptor() {
    this.unAuthenticatedAxios.interceptors.request.use(
      async (config) => {
        const locale = await this.getLocale();
        config.headers["Accept-Language"] = locale;

        // const currency = await this.getCurrency();
        // config.headers['accept-currency'] = currency;
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  addResponseInterceptor() {
    this.authenticatedAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          StorageService.set("Token", null);
          StorageService.set("UserId", null);
          StorageService.set("auth", false);
          window.location.href = "/login"; // Navigate to the login page using window.location
          // navigateToLoginPage(); // Implement your navigation logic
        }
        return Promise.reject(error);
      }
    );
  }
}

export default new HttpHelpers();
