/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-debugger */
import React, { useState, useEffect, useMemo } from "react";
import { uid } from "uid";
import { Table, Form, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiDeleteBin5Line } from "react-icons/ri";
import Select from "react-select";
import { GrAdd, GrLinkNext } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputField from "../../../components/input";
import Loading from "../../../components/Loading";
import {
  createCar,
  createDriver,
  deleteCar,
  deleteDriver,
} from "../../../ymc-sdk/redux/actions/diesalClients.actions";
import schema from "./schemaDriver";

function ClientDriver({
  clientId,
  clientCars,
  onDataUpdate,
  isCreateValid,
  isDeleteValid,
}) {
  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableCarsData, setTableCarsData] = useState([]);
  const [car, setCar] = useState(null);
  const [cars, setCars] = useState([]);
  const [carSelect, setCarSelect] = useState(null);
  const [carsSelect, setCarsSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    // if (productTypes) {
    //   setProductType(productTypes);
    // }
    if (clientCars) {
      setLoading(true);
      setTableCarsData(clientCars);
      // console.log(clientCars);
      let allDriversData = [];
      const CarsData = [];
      const carsSelectData = [];
      clientCars?.forEach((el) => {
        el.label = el.CarNumber;
        CarsData.push(el);
        carsSelectData.push(el);
        allDriversData = [...allDriversData, ...el.DriverCars];
      });
      setCarsSelect(carsSelectData);
      const allDrivers = {};
      allDrivers.label = "all Cars";
      allDrivers.Id = 0;
      allDrivers.DriverCars = allDriversData;
      CarsData.push(allDrivers);
      setCars(CarsData);
      setCar(allDrivers);
      // console.log("cars", cars);
      // console.log("carssss", car);
      setLoading(false);
    }
  }, [clientId, clientCars]);
  const onSubmit = (data) => {
    setLoading(true);
    const userId = localStorage.getItem("UserId");
    data.createdBy = userId;
    // console.log(data);
    /// ////////send client function
    dispatch(createDriver({ UserId: userId }, data))
      .then((res) => {
        onDataUpdate();
        setLoading(false);
        // console.log(res);
        reset();
        setCarSelect(null);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        if (
          // eslint-disable-next-line operator-linebreak
          err?.response?.data?.Errors[0] ===
          "This Driver is already exist with the other client you can not add the same Driver to different clients"
        ) {
          toast.error(t("clients.driverExistWithOtherClient"));
        } else {
          toast.error(t("shared.sorrySomethingWrong"));
        }
      });
  };
  const handleDelete = (cid) => {
    const userId = localStorage.getItem("UserId");
    setLoading(true);
    // console.log(cid);
    dispatch(deleteDriver(cid, { userId }))
      .then((res) => {
        onDataUpdate();
        setLoading(false);
        // const data = [...tableCarsData];
        // setTableCarsData(data.filter((item) => item.Id !== cid));
        toast.success(t("shared.deletedSuccessfully"));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  const handleDownloadQrcode = (qrCode, driverName, driverId) => {
    const imageUrl = `data:image/jpeg;base64,${qrCode}`;
    const img = new Image();
    img.onload = () => {
      const width = 1060;
      const height = 1060;

      // Create a Canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the Canvas dimensions to the desired width and height
      canvas.width = width;
      canvas.height = height;

      // Draw the image on the Canvas with the desired dimensions
      ctx.drawImage(img, 0, 0, width, height);

      // Convert the Canvas content to a data URL
      const resizedImageUrl = canvas.toDataURL("image/jpeg");

      // Create an anchor element and trigger a click event to download the image
      const a = document.createElement("a");
      a.href = resizedImageUrl;
      a.download = `${driverId}-${driverName}.jpg`; // Replace 'image.jpg' with the desired file name
      a.click();
    };

    // Set the image source to load it
    img.src = imageUrl;
  };

  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    const inputValue = event.target.value;
    const maxLength = 14;
    if (
      // eslint-disable-next-line operator-linebreak
      inputValue.length >= maxLength ||
      (charCode !== 8 && charCode !== 0 && (charCode < 48 || charCode > 57))
    ) {
      event.preventDefault();
    }
  };
  return (
    <div className="first_table">
      <h5 className="my-3 text-center"> {t("clients.drivers")} </h5>
      <Table striped bordered hover className="mt-5 table-responsive mb-0">
        <thead className="text-white" style={{ backgroundColor: "#333" }}>
          <tr>
            <th
              className="col-md-3 py-3 bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              <Select
                name="chooseCar"
                defaultValue={car}
                value={car}
                onChange={setCar}
                options={cars}
                placeholder={t("clients.chooseCar")}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.Id}
                className="text-black"
              />
            </th>
            <th
              className="col-md-3 py-3 bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {" "}
              {t("diesalClients.driverName")}{" "}
            </th>
            <th
              className="col-md-3 py-3 bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {" "}
              {t("diesalClients.nationalIdNumber")}{" "}
            </th>
            <th
              className="col-md-3 py-3 bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {" "}
              {t("clients.driverQRCode")}{" "}
            </th>
            {isDeleteValid && (
              <th
                className="col-md-3 py-3 bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("search.options")}
              </th>
            )}
          </tr>
        </thead>
        {car?.DriverCars.length > 0 ? (
          <tbody>
            {car?.DriverCars.map((el) => (
              <tr key={el?.DriverId}>
                <td className="col-md-3 py-3">
                  {cars?.find((p) => p.Id === el?.CarId)?.label}
                </td>
                <td className="col-md-3 py-3">{el?.Driver?.DriverName}</td>
                <td className="col-md-3 py-3">
                  {el?.Driver?.NationalIdNumber}
                </td>
                <td className="d-flex justify-content-between w-100 col-md-3 py-3">
                  <img
                    src={`data:image/jpeg;base64,${el?.Driver?.DriverQrCode}`}
                    alt="Base64 Image"
                    width="70px"
                    height="70px"
                    className="mx-2"
                  />
                  <div>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={
                        () =>
                          // eslint-disable-next-line implicit-arrow-linebreak
                          handleDownloadQrcode(
                            el?.Driver?.DriverQrCode,
                            el?.Driver?.DriverName,
                            // eslint-disable-next-line comma-dangle
                            el?.Driver?.NationalIdNumber
                          )
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    >
                      download QRCode
                    </Button>
                  </div>
                </td>
                {isDeleteValid && (
                  <td className="col-md-3 py-3">
                    <Button
                      variant="danger"
                      size="md"
                      disabled={!isDeleteValid}
                      onClick={() => handleDelete(el?.DriverId)}
                    >
                      <RiDeleteBin5Line size="1.5em" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        ) : (
          ""
        )}
        {/* form */}
      </Table>

      <div className="form_table">
        {isCreateValid && (
          <Form className="w-100 mt-1" onSubmit={handleSubmit(onSubmit)}>
            <Row className="form_table">
              <Col md={4} className="position-relative">
                <InputField
                  name="DriverName"
                  placeholder={t("diesalClients.driverName")}
                  errors={errors}
                  type="text"
                  {...register("DriverName")}
                />
              </Col>
              <Col md={4} className="position-relative">
                <InputField
                  name="NationalIdNumber"
                  placeholder={t("diesalClients.nationalIdNumber")}
                  errors={errors}
                  type="text"
                  onKeyPress={handleKeyPress}
                  {...register("NationalIdNumber")}
                />
              </Col>
              <Col
                md={4}
                className="position-relative d-flex align-items-center"
              >
                <div>
                  <Controller
                    name="CarId"
                    control={control}
                    render={({ carsField }) => (
                      <Select
                        {...carsField}
                        name="CarId"
                        defaultValue={carSelect}
                        value={carSelect}
                        onChange={(selectedOption) => {
                          setCarSelect(selectedOption); // Update the selected option in the state
                          setValue("CarId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                        }}
                        options={carsSelect}
                        placeholder={t("clients.chooseCar")}
                        isSearchable
                        isClearable
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.Id}
                        className={
                          errors.CarId && !carSelect ? "error-border" : ""
                        }
                      />
                    )}
                  />
                  {errors.CarId && !carSelect ? (
                    <p className="error-label">{errors.CarId.message}</p>
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  )}
                </div>
                <button
                  title="add"
                  type="submit"
                  disabled={!isCreateValid}
                  className="main-button secondary btn d-block m-auto p-2 text-center mt-0"
                >
                  {t("shared.add")} <GrAdd />
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default ClientDriver;
