/* eslint-disable eqeqeq */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import { uid } from 'uid';
import {
  Table, Form, Row, Col, Button,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { GrAdd } from 'react-icons/gr';
import Select from 'react-select';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import InputField from '../../components/input';
import schemaTable from './schemaTable';
import SharedApiEndpoints from '../../ymc-sdk/services/shared.api';
import Buttons from '../../components/Buttons';
import Loading from '../../components/Loading';
import PdfPage from '../offerSummary/pdf/PdfPage';
import {
  createPriceOfferItem, getOfferById, deletePriceOfferItem, productGetQuantity,
} from '../../ymc-sdk/redux/actions/offer.actions';
import SubmitPdf from '../offerSummary/submitPdf';

function CompleteOfferDetails({
  paymentTerms, updateForm, offerData, edit, pdfLang, IssueProduct, issueFrom, setOfferData,
}) {
  const {
    reset, register, handleSubmit, setError, watch, setValue, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schemaTable) });
  const { i18n, t } = useTranslation();
  const { offerId } = useParams();
  const dispatch = useDispatch();
  const bottomRef = useRef(null);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [offerData, setOfferData] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  useEffect(() => {
    if (selectedProduct) {
      reset({
        PurchasePriceWithNoTax: selectedProduct?.ListPurchasePriceExclTaxes,
        Inventory: selectedProduct?.ProductInvBalance,
      });
      dispatch(productGetQuantity(selectedProduct?.ProductCode)).then((res) => {
        // console.log(res?.Data);
        reset({
          QuantityRequestedInOtherPO: res?.Data,
        });
      }).catch((error) => {
        // console.log(error);
      });
    }
  }, [selectedProduct]);

  // get products from system 9
  const { data: products } = useQuery(
    ['products system 9', i18n.language],
    () => SharedApiEndpoints.getProducts(i18n),
    {
      // staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    },
  );
  useEffect(() => {
    setTableData(offerData?.PriceOfferItems);
    // console.log(offerData?.PriceOfferItems, 'ggggggggg');
  }, [offerData]);

  useEffect(() => {
    if (updateForm) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [updateForm]);
  const onSubmit = (data) => {
    setLoading(true);
    const userId = localStorage.getItem('UserId');
    data.createdBy = userId;
    data.priceOfferId = offerId;
    data.ProductName = i18n.dir() === 'rtl' ? selectedProduct?.ProductNameAr : selectedProduct?.ProductNameEn;
    data.purchasePriceWithNoTax = selectedProduct?.ListPurchasePriceExclTaxes;
    data.productSystem9Code = selectedProduct?.ProductCode;
    data.productSystem9Json = selectedProduct;
    data.TotalForProduct = data.Quantity * data.SellPriceWithNoTax;
    if (data?.IsPriceMatchPaymentTerms === 'no') {
      data.IsPriceMatchPaymentTerms = 0;
    } else {
      data.IsPriceMatchPaymentTerms = 1;
    }
    // console.log(data);

    /// ////////send client function
    dispatch(createPriceOfferItem({ userId }, data)).then((res) => {
      setLoading(false);
      reset();
      setSelectedProduct(null);
      reset({ IsPriceMatchPaymentTerms: '' });
      dispatch(getOfferById(offerId)).then((res) => {
        setOfferData(res?.Data);
      });

      data.Id = res?.Data;
      const newData = { ...data };
      setTableData((prevVals) => [...prevVals, newData]);
      // console.log(newData, 'fffffffffffffffff');
      // console.log(tableData, 'fffffffffffffffff');
    }).catch((error) => {
      setLoading(false);
      toast.error(t('shared.sorrySomethingWrong'));
    });
  };

  // get total

  const watchQuantity = watch('Quantity');
  const watchPrice = watch('PurchasePriceWithNoTax');
  const SellPrice = watch('SellPriceWithNoTax');
  // console.log(SellPrice, 'watchQuantity', watchQuantity);

  useEffect(() => {
    if (watchQuantity && watchPrice) {
      const total = watchQuantity * SellPrice;

      reset({ TotalForProduct: total });
    }
  }, [watchQuantity, watchPrice]);
  useEffect(() => {
    if (paymentTerms && watchPrice && SellPrice) {
      if (watchPrice * (paymentTerms.Percentage + 1) > SellPrice) {
        reset({ IsPriceMatchPaymentTerms: 'no' });
      } else {
        reset({ IsPriceMatchPaymentTerms: 'yes' });
      }
    }
  }, [paymentTerms, watchPrice, SellPrice]);

  /// // delete function
  const handleDelete = (Id) => {
    setLoading(true);
    const userId = localStorage.getItem('UserId');
    // console.log(Id);
    dispatch(deletePriceOfferItem(Id, { userId })).then((res) => {
      const data = [...tableData];
      setLoading(false);

      setTableData(data.filter((item) => item.Id !== Id));
      dispatch(getOfferById(offerId)).then((res) => {
        setOfferData(res?.Data);
        setLoading(false);
      });
      toast.success(t('shared.deletedSuccessfully'));
    }).catch((error) => {
      setLoading(false);
      toast.error(t('shared.sorrySomethingWrong'));
    });
  };
  return (
    <div className="first_table">
      <Table striped bordered hover className="mt-5 table-responsive mb-0">
        <thead className="text-white" style={{ backgroundColor: '#333' }}>
          <tr>
            <th className="col-md-2">
              {' '}
              {t('priceOffer.productName')}
              {' '}
            </th>
            <th className="col-md-2">
              {t('priceOffer.adjustedProductName')}
              {' '}
            </th>
            <th className="col-md-1">
              {' '}
              {t('priceOffer.quantity')}
              {' '}
            </th>
            <th className="col-md-1">
              {' '}
              {t('priceOffer.listPurchasePrice')}
              {' '}
            </th>
            <th className="col-md-1">
              {' '}
              {t('priceOffer.sellingPrice')}
              {' '}
            </th>
            <th className="col-md-1">
              {' '}
              {t('priceOffer.inventory')}
              {' '}
            </th>
            <th className="col-md-1">
              {' '}
              {t('priceOffer.quantityRequestedByOtherPriceOffer')}
              {' '}
            </th>
            {/* <th className="col-md-1"> Minimum Price Set </th> */}
            <th className="col-md-1">
              {t('priceOffer.priceMatchingPaymentTerms')}
              {' '}
            </th>
            <th className="col-md-1">
              {' '}
              {t('priceOffer.total')}
              {' '}
            </th>
          </tr>
        </thead>
        { tableData?.length > 0 ? (
          <tbody>
            {tableData?.map((el) => (
              <tr key={el?.Id}>
                <td className="col-md-3">{el?.ProductName}</td>
                <td className="col-md-2">{el?.ProductNamePO}</td>
                <td className="col-md-1">{el?.Quantity}</td>
                <td className="col-md-1">{el?.PurchasePriceWithNoTax}</td>
                <td className="col-md-1">{el?.SellPriceWithNoTax}</td>
                <td className="col-md-1">{el?.Inventory}</td>
                <td className="col-md-1">{el?.QuantityRequestedInOtherPO}</td>
                {/* <td className="col-md-1">{el?.MinPrice}</td> */}
                <td className="col-md-1">{el?.IsPriceMatchPaymentTerms == false ? 'No' : 'Yes'}</td>
                <td className="col-md-1">{el.Quantity * el.SellPriceWithNoTax}</td>
                <td className="d-flex justify-content-between col-md-4">
                  {el.ContactPerson}
                  <Button variant="danger" size="sm" onClick={() => handleDelete(el.Id)}><RiDeleteBin5Line /></Button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : '' }
        {/* form */}
      </Table>

      <div className="form_table mt-3">
        <Form className="w-100 mt-1" onSubmit={handleSubmit(onSubmit)}>
          <Row className="form_table">
            <Col md={3} className="position-relative px-1">
              <Select
                // defaultValue={setSelectedProduct}
                value={selectedProduct}
                onChange={setSelectedProduct}
                options={products}
                placeholder={t('priceOffer.productName')}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.Id}
              />
            </Col>
            <Col md={2} className="position-relative px-1">
              <InputField
                placeholder={t('priceOffer.adjustedProductName')}
                name="ProductNamePO"
                errors={errors}
                type="text"
                {...register('ProductNamePO')}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col md={1} className="position-relative px-1">
              <InputField
                placeholder={t('priceOffer.quantity')}
                name="Quantity"
                errors={errors}
                type="number"
                {...register('Quantity')}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col md={1} className="position-relative px-1 ">
              <InputField
                placeholder={t('priceOffer.listPurchasePrice')}
                name="PurchasePriceWithNoTax"
                errors={errors}
                readOnly
                type="number"
                {...register('PurchasePriceWithNoTax')}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col md={1} className="position-relative px-1 ">
              <InputField
                placeholder={t('priceOffer.sellingPrice')}
                name="SellPriceWithNoTax"
                errors={errors}
                type="number"
                {...register('SellPriceWithNoTax')}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col md={1} className="position-relative px-1">
              <InputField
                placeholder={t('priceOffer.inventory')}
                name="Inventory"
                errors={errors}
                readOnly
                type="number"
                {...register('Inventory')}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col md={1} className="position-relative px-1">
              <InputField
                placeholder={t('priceOffer.quantityRequestedByOtherPriceOffer')}
                name="QuantityRequestedInOtherPO"
                readOnly
                errors={errors}
                type="number"
                {...register('QuantityRequestedInOtherPO')}
                wrapperClassName="ltr-input"
              />
            </Col>
            {/* <Col md={1} className="position-relative px-1">
              <InputField
                placeholder="Minimum Price"
                name="MinPrice"
                errors={errors}
                type="number"
                {...register('MinPrice')}
                wrapperClassName="ltr-input"
              />
            </Col> */}
            <Col md={1} className="position-relative px-1">
              <InputField
                placeholder={t('priceOffer.priceMatching')}
                name="IsPriceMatchPaymentTerms"
                errors={errors}
                type="text"
                readOnly
                {...register('IsPriceMatchPaymentTerms')}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col md={1} className="position-relative px-1 d-flex align-items-center">
              <InputField
                placeholder={t('priceOffer.total')}
                readOnly
                name="TotalForcccProduct"
                errors={errors}
                // value={TotalForProduct}
                type="number"
                {...register('TotalForProduct')}
                wrapperClassName="ltr-input w-90"
              />
              <button title={t('priceOffer.add')} type="submit" className="main-button secondary btn d-block m-auto p-2 text-center mt-0">
                <GrAdd />
              </button>
            </Col>
          </Row>
        </Form>
        {edit ? (
          <div className="text-center mb-5 mt-2">
            <PDFDownloadLink
              document={<PdfPage offerData={offerData} IssueProduct={IssueProduct} pdfLang={pdfLang} issueFrom={issueFrom} />}
              fileName="Price_Offer"
              style={{
                textDecoration: 'none',
                padding: '10px',
                color: '#fff',
                backgroundColor: '#ba2928',
                border: 'none',
                borderRadius: '10px',
              }}
            >
              {({
                blob, url, loading, error,
              }) => (loading ? 'Loading document...' : 'Download Pdf')}
            </PDFDownloadLink>
          </div>
        )
          : (
            <div ref={bottomRef} className="m-5">
              <Link to={`/offerSummary/${offerId}`} className="main-button primary btn d-block w-30 m-auto">
                {t('priceOffer.next')}
                {' '}
                <BsFillArrowRightCircleFill />

              </Link>
            </div>
          )}
        {/* <PDFViewer>
          <PdfPage offerData={offerData} IssueProduct={IssueProduct} pdfLang={pdfLang} issueFrom={issueFrom} />
        </PDFViewer> */}
      </div>
      {loading && <Loading />}

    </div>
  );
}

export default CompleteOfferDetails;
