import HttpHelpers from "./helpers";

const HomeApiEndpoints = {
  getNotificationAssignedToUser: (params) => {
    return HttpHelpers.authenticatedAxios
      .get(`Notification/GetNotificationAssignedToUser`, { params })
      .then((response) => response.data);
  },
  GetPriceOfferHomeNotificationsAssignedToUser: (params) => {
    return HttpHelpers.authenticatedAxios
      .get(`PriceOffer/GetPriceOfferHomeNotificationsAssignedToUser`, {
        params,
      })
      .then((response) => response.data);
  },
  GetAllProductsQuantityInPendingPriceOffers: (params) => {
    return HttpHelpers.authenticatedAxios
      .get(`ProductSystem9/GetAllProductsQuantityInPendingPriceOffers`, {
        params,
      })
      .then((response) => response.data);
  },
};

export default HomeApiEndpoints;
