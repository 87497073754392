/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Table, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate, useParams } from "react-router";
import { useQuery } from "react-query";
import InputField from "../../components/input";
import PaymentApiEndpoints from "../../ymc-sdk/services/terms.api";
import {
  UpdateSystemConfiguration,
  getSystemConfiguration,
} from "../../ymc-sdk/redux/actions/config.actions";
import Loading from "../../components/Loading";

function Configs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const [configData, setConfigDataData] = useState({});
  const onSubmit = (data) => {
    setLoading(true);
    const userId = localStorage.getItem("UserId");
    data.createdBy = configData.CreatedBy;
    data.lastUserModifyId = userId;
    // console.log(data, 'vvvvvvvvvvvvvvvvv');

    dispatch(UpdateSystemConfiguration({ userId, id: configData.Id }, data))
      .then((res) => {
        setLoading(false);
        toast.success(t("shared.updatedSuccessfully"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  useEffect(() => {
    dispatch(
      getSystemConfiguration("A72A0DD5-5D68-4C57-964A-3737FCB2F8E8")
    ).then((res) => {
      reset({
        MinimumPriceSet: res.Data.MinimumPriceSet,
        NumberOfDaysAfterCreatePriceOfferForClientReply:
          res.Data.NumberOfDaysAfterCreatePriceOfferForClientReply,
        NumberOfDaysAfterEndDurationForClientReply:
          res.Data.NumberOfDaysAfterEndDurationForClientReply,
        createdBy: res.Data.CreatedBy,
      });
      setConfigDataData(res.Data);
    });
  }, []);

  return (
    <div className="p-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="gx-5 gy-3 align-items-center">
          {/* <Col md={4}>
            <Label>min price set</Label>
            <InputField
              name="MinimumPriceSet"
              errors={errors}
              type="text"
              {...register('MinimumPriceSet')}
            />
          </Col> */}
          <Col md={6}>
            <Label> {t("configs.notificationForManager")} </Label>
            <InputField
              name="NumberOfDaysAfterEndDurationForClientReply"
              errors={errors}
              type="text"
              readOnly={!pageActions?.includes("config_Update")}
              {...register("NumberOfDaysAfterEndDurationForClientReply")}
            />
          </Col>
          <Col md={6}>
            <Label> {t("configs.expectedDateReply")} </Label>
            <InputField
              name="NumberOfDaysAfterCreatePriceOfferForClientReply"
              errors={errors}
              type="text"
              readOnly={!pageActions?.includes("config_Update")}
              {...register("NumberOfDaysAfterCreatePriceOfferForClientReply")}
            />
          </Col>
          {pageActions?.includes("config_Update") && (
            <button
              type="submit"
              className="main-button primary btn d-block w-30 m-auto"
              disabled={!pageActions?.includes("config_Update")}
            >
              {" "}
              {t("configs.notificationForManager")}{" "}
            </button>
          )}
        </Row>
      </Form>
      {loading && <Loading />}
    </div>
  );
}

export default Configs;
