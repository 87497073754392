/* eslint-disable default-param-last */
import StorageService from '../../storage-service';

const sharedReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_PRODUCTS':
      if (action.error) return state;
      return {
        ...state,
        products: action.data,
      };
    case 'GET_CLIENTS':
      if (action.error) return state;
      return {
        ...state,
        client: action.data,
      };
    default:
      return state;
  }
};

export default sharedReducer;
