import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import TermsForm from './termsForm';
import Configs from './configs';
import SyncStstem9 from './syncStstem9';

function Config() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{`YMC Group | ${ t("navBar.configs")}`}</title>
      </Helmet>
      <TermsForm />
      <Configs />
      <SyncStstem9 />
    </div>
  );
}

export default Config;
