import HttpHelpers from "./helpers";

const ClientSearchApiEndpoints = {
  getAllClientsWithFilteration: (params) => {
    return HttpHelpers.authenticatedAxios
      .get(`Client/GetAllClientsWithFilteration`, { params })
      .then((response) => response.data);
  },
  ExportFilteredClientsToExcel: (params) => {
    return HttpHelpers.authenticatedAxios
      .get(`Client/ExportFilteredClientsToExcel`, { params })
      .then((response) => response.data);
  },
  createNotificationAssignedToUser: (data) => {
    return HttpHelpers.authenticatedAxios
      .post(`Notification/CreateNotificationAssignedToUser`, data)
      .then((response) => response.data);
  },
};

export default ClientSearchApiEndpoints;
