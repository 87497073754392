import * as yup from 'yup';
import i18n from 'i18next';

const schemaInputs = yup
  .object({
    // priceOfferDate: yup.string().required(() => i18n.t('auth.required')),
    // ExpectedDateForClientReply: yup.string().required(() => i18n.t('auth.required')),
    PriceOfferValidity: yup.string().required(() => i18n.t('auth.required')),
    // paymentTerms: yup.string().required(() => i18n.t('auth.required')),
    // salesRepresentitiveName: yup.string().required(() => i18n.t('auth.required')),
    SalesRepresentitiveNumber: yup.string().required(() => i18n.t('auth.required'))
      .matches(
        /^01[0125][0-9]{8}$/,
        i18n.t('auth.phoneRequired'),
      ),
    // ExptectedDateToRecievePaymentCheck: yup.string().required(() => i18n.t('auth.required')),
    // ClientActualPaymentPeriod: yup.string().required(() => i18n.t('auth.required')),
    // Notes: yup.string(() => i18n.t('auth.required')),
  })
  .required();

export default schemaInputs;
