import HttpHelpers from './helpers';

const OfferApiEndpoints = {
  getRegions: () => {
    return HttpHelpers.authenticatedAxios
      .get('LookupData/GetRegions')
      .then((response) => {
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          el.label = el.RegionName;
          arrayData.push(el);
        });
        return arrayData; // JSON data parsed by `data.json()` call
      });
  },
  cretePriceOffer: (params, data) => {
    return HttpHelpers.authenticatedAxios
      .post('PriceOffer', data, { params })
      .then((response) => response.data);
  },
  updatePriceOffer: (id, params, data) => {
    return HttpHelpers.authenticatedAxios
      .put(`PriceOffer/${id}`, data, { params })
      .then((response) => response.data);
  },
  getOfferById: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`PriceOffer/${id}`)
      .then((response) => response.data);
  },
  getAllOffers: () => {
    return HttpHelpers.authenticatedAxios
      .get('PriceOffer')
      .then((response) => response.data);
  },
  getIndustries: () => {
    return HttpHelpers.authenticatedAxios
      .get('LookupData/GetIndustries')
      .then((response) => {
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          el.label = el.IndustryName;
          arrayData.push(el);
        });
        return arrayData; // JSON data parsed by `data.json()` call
      });
  },
  productGetQuantity: (code) => {
    return HttpHelpers.authenticatedAxios
      .get(`ProductSystem9/GetQuantity/${code}`)
      .then((response) => response.data);
  },
  checkProductPriceMatching: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('ProductSystem9/CheckProductPriceMatchinPaymentTerms', data)
      .then((response) => response.data);
  },
  createPriceOfferItem: (params, data) => {
    return HttpHelpers.authenticatedAxios
      .post('PriceOfferItem', data, { params })
      .then((response) => response.data);
  },
  deletePriceOfferItem: (id, params, data) => {
    return HttpHelpers.authenticatedAxios
      .delete(`PriceOfferItem/${id}`, { params })
      .then((response) => response.data);
  },
};

export default OfferApiEndpoints;
