/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { Form, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import BinaryInput from "../../components/binary-input";
import schema from "./schemaCurrentClient";
import InputField from "../../components/input";
import { cretePriceOffer } from "../../ymc-sdk/redux/actions/offer.actions";
import Loading from "../../components/Loading";
import Buttons from "../../components/Buttons";

function CurrentClient({ clients9, isLoading }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [selectClient, setSelectClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });
  const onSubmit = (data) => {
    setLoading(true);
    const userId = localStorage.getItem("UserId");
    data.createdBy = userId;
    data.clientId = selectClient?.Id;
    // console.log(data);
    dispatch(cretePriceOffer({ userId }, data))
      .then((res) => {
        setLoading(false);
        navigate(`/offerDetails/${res.Data}`);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };

  return (
    <div className="current_client pt-3">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="gx-5 gy-3">
          <Col md={6}>
            <label className="mb-2">
              {" "}
              {t("priceOffer.clientNamePresentOnPriceOffer")}
            </label>
            <InputField
              placeholder={t("priceOffer.enterUserName")}
              name="ClientNameInPO"
              errors={errors}
              type="text"
              {...register("ClientNameInPO")}
            />
          </Col>
          {/* <Col md={6}>
            <label>Client Type:</label>
            <BinaryInput
              errors={errors}
              id="company"
              label="company"
              type="radio"
              value="0"
              className="cancellation-policy-label"
              name="cancellation_policy"
              {...register('client_type')}
              hideError
            />
            <BinaryInput
              errors={errors}
              id="trader"
              label="trader"
              type="radio"
              value="1"
              className="cancellation-policy-label"
              name="cancellation_policy"
              {...register('client_type')}
              hideError
            />
          </Col> */}
          <Col md={6}>
            <label className="mb-2">{t("priceOffer.offerIsSentTo")}</label>
            <InputField
              placeholder={t("priceOffer.offerIsSentTo")}
              name="PriceOfferSentTo"
              errors={errors}
              type="text"
              {...register("PriceOfferSentTo")}
            />
          </Col>
          <Col md={6}>
            {/* (from system 9) */}
            <label className="mb-2"> {t("priceOffer.clientName")}</label>
            <Select
              defaultValue={selectClient}
              onChange={setSelectClient}
              options={clients9}
              placeholder={t("priceOffer.chooseAnOption")}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
            />
          </Col>
          {pageActions?.includes("createOffer_Create") && (
            <Col className="mt-5" md={12}>
              <button
                type="submit"
                className="main-button primary btn d-block px-5  m-auto"
                disabled={!pageActions?.includes("createOffer_Create")}
              >
                {" "}
                {t("priceOffer.next")}
              </button>
            </Col>
          )}
        </Row>
      </Form>
      {loading && <Loading />}
      {isLoading && <Loading />}
    </div>
  );
}

export default CurrentClient;
