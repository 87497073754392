/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Inputs from "./Inputs";
import SummaryTable from "./Table";
import {
  createPriceOfferItem,
  getOfferById,
} from "../../ymc-sdk/redux/actions/offer.actions";

function OfferSummary() {
  const { i18n, t } = useTranslation();
  const { offerId } = useParams();
  const dispatch = useDispatch();
  const [offerData, setOfferData] = useState(null);
  const [IssueProduct, setIssueProduct] = useState("Product name as written");
  const [issueFrom, setIssueFrom] = useState(
    "Yasser Montasser Rasheed Montasse"
  );
  const [pdfLang, setPdfLaNG] = useState("1");

  useEffect(() => {
    dispatch(getOfferById(offerId))
      .then((res) => {
        setOfferData(res?.Data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);
  return (
    <div className="p-5 body-style">
      <Helmet>
        <title>{`YMC Group | ${t("priceOffer.offerSummary")} | ${
          offerData?.CreationDate?.substring(0, 10) || " "
        } | ${
          offerData?.Client?.ClientName || " "
        }`}</title>
      </Helmet>
      <h2> {t("priceOffer.offerSummary")}</h2>
      <Inputs
        offerData={offerData}
        setIssueFrom={setIssueFrom}
        setIssueProduct={setIssueProduct}
        setPdfLaNG={setPdfLaNG}
      />
      <SummaryTable
        offerData={offerData}
        issueFrom={issueFrom}
        IssueProduct={IssueProduct}
        pdfLang={pdfLang}
      />
    </div>
  );
}

export default OfferSummary;
