import HttpHelpers from "./helpers";

const UploadFilesEndPoints = {
  uploadImage: (path, formData) => {
    return HttpHelpers.authenticatedAxios
      .post(`File/UploadFiles/${path}`, formData, {
        headers: {
          accept: "text/plain",
          "Content-Type": "multipart/form-data", // Important: Set the correct content type
        },
      })
      .then((response) => response.data);
  },
};

export default UploadFilesEndPoints;
