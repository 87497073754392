/* eslint-disable import/prefer-default-export */
import React from 'react';
import footerAr from '../../assets/images/Header/arFooter.png';
import enFooter from '../../assets/images/Header/enFooter.png';
import headerAr from '../../assets/images/Header/arHeader.png';
import headerEn from '../../assets/images/Header/enHeader.png';
import arYasser from '../../assets/images/Header/arYasser.png';
import enYasser from '../../assets/images/Header/enYasser.png';

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <div className="pdf-document">
        {/* <div className="pdf-header">
          <img src={headerEn} alt="" className="img-fluid w-100" />
        </div> */}
        <div className="pdf-page">
          <this.props.Table
            offerData={this.props.offerData}
            issueFrom={this.props.issueFrom}
            IssueProduct={this.props.IssueProduct}
          />
          {/* <div style={{ pageBreakBefore: 'always' }}>  </div> */}

        </div>
      </div>
    );
  }
}
