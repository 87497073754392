/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
import React, { useState, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Col, Form, Row } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { format } from "date-fns-tz";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePickerCustomStyle.css";
import InputField from "../../components/input";
import OfferApiEndpoints from "../../ymc-sdk/services/offer.api";
import Loading from "../../components/Loading";
import Api from "../../ymc-sdk/services";
import ClientsApiEndpoints from "../../ymc-sdk/services/client.api";
import { getClientDataById } from "../../ymc-sdk/redux/actions/client.actions";
import InvoicesTable from "./invoicesTable";
import AuthApiEndpoints from "../../ymc-sdk/services/auth.api";

function DieselInvoiceSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const [isloading, setLoading] = useState(false);
  const [region, setRegion] = useState(null);
  const [client, setClient] = useState(null);
  const [carNumber, setCarNumber] = useState(null);
  const [carsNumber, setCarsNumber] = useState([]);
  const [driverName, setDriverName] = useState(null);
  const [driversName, setDriversName] = useState([]);
  const [productType, setProductType] = useState(null);
  const [resData, setResData] = useState({
    Data: [],
    Page: 0,
    PageSize: 0,
    TotalCount: 0,
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [searchData, setSearchData] = useState({
    from: null,
    to: null,
    clientId: "",
    carNumber: "",
    driverName: "",
    dieselProductTypeId: "",
    regionId: "",
    page,
    pageSize,
  });
  const { data: clients9, isLoading: clients9Loading } = useQuery(
    ["Clients 9"],
    () => ClientsApiEndpoints.getClientsSystem9(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  // get clients system 9
  // const { data: clientsYMC, isLoading: clientsYMCLoading } = useQuery(
  //   ["Clients YMC"],
  //   () => ClientsApiEndpoints.getClientsYMC(),
  //   {
  //     staleTime: 10 * (60 * 1000), // 10 mins
  //     cacheTime: 15 * (60 * 1000), // 15 mins
  //     select: (data) => {
  //       return data;
  //     },
  //   }
  // );
  const { data: productTypes, isLoading: productTypesLoading } = useQuery(
    ["productTypes"],
    () => Api.lookupData.getDiesalProductTypes(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("industries", data);
        return data;
      },
      // eslint-disable-next-line comma-dangle
    }
  );
  const { data: regions, isLoading: regionsLoading } = useQuery(
    ["regions"],
    () => OfferApiEndpoints.getRegions(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("regions", data);
        return data;
      },
    }
  );

  const { data: adminUsers, isLoading: adminUsersLoading } = useQuery(
    ["admin users"],
    () => AuthApiEndpoints.getSystemUsers(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const formData = useWatch({ control });

  useEffect(() => {
    // console.log(client);
    if (client) {
      setLoading(true);
      dispatch(getClientDataById(client?.Id))
        .then((res) => {
          // console.log(res.Data);
          const CarsData = [];
          res.Data?.ClientCars?.forEach((el) => {
            CarsData.push({ label: el.CarNumber, value: el.CarNumber });
          });
          // console.log(CarsData);
          setCarsNumber(CarsData);

          const allDriverNames = [
            ...new Set(
              res.Data?.ClientCars?.flatMap(
                (c) =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  c.DriverCars?.map((driverCar) => driverCar.Driver.DriverName)
                // eslint-disable-next-line function-paren-newline
              )
            ),
          ];
          const driversData = allDriverNames.map((driverName) => ({
            label: driverName,
            value: driverName,
          }));
          setDriversName(driversData);
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [client]);
  const onValidDate = (data) => {
    if (data.from) {
      const modifiedDate = new Date(data.from);
      modifiedDate.setHours(modifiedDate.getHours() + 2);
      data.from = modifiedDate;
    }
    if (data.to) {
      const modifiedDate = new Date(data.to);
      modifiedDate.setHours(modifiedDate.getHours() + 2);
      data.to = modifiedDate;
    }
    return data;
  };
  const onSabmit = (data) => {
    data.clientId = client?.Id;
    data.carNumber = carNumber?.value;
    data.driverName = driverName?.value;
    data.dieselProductTypeId = productType?.Id;
    data.regionId = region?.Id;
    data.page = 0;
    data.pageSize = pageSize;
    data = onValidDate(data);
    // console.log(data);
    setPage(0);
    setLoading(true);
    setSearchData(data);
    // console.log(page, pageSize);
    Api.diesalClients.Invoice.searchDieselClient(data)
      .then((res) => {
        setLoading(false);
        // console.log(res.Data);
        setResData({
          ...resData,
          Data: [...res.Data],
          Page: res.Page,
          PageSize: res.PageSize,
          TotalCount: res.TotalCount,
        });
        // console.log(resData);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  const handlePagination = (updatePage) => {
    const newSearchData = { ...searchData, page: page + updatePage };
    setLoading(true);
    setSearchData({ ...searchData, page: page + updatePage });
    setPage(page + updatePage);
    // console.log(newSearchData);
    Api.diesalClients.Invoice.searchDieselClient(newSearchData)
      .then((res) => {
        setLoading(false);
        // console.log(res.Data);
        setResData({
          ...resData,
          Data: [...res.Data],
          Page: res.Page,
          PageSize: res.PageSize,
          TotalCount: res.TotalCount,
        });
        // console.log(resData);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };

  const exportExcel = (e) => {
    let filterData = { ...formData };
    filterData.clientId = client?.Id;
    filterData.carNumber = carNumber?.value;
    filterData.driverName = driverName?.value;
    filterData.dieselProductTypeId = productType?.Id;
    filterData.regionId = region?.Id;
    filterData = onValidDate(filterData);
    // console.log(filterData);
    e.preventDefault();
    setLoading(true);
    Api.diesalClients.Invoice.exportDieselClientToExcel(filterData)
      .then((res) => {
        setLoading(false);
        // console.log(res.Data);
        window.open(`https://backendapi.ymcgroup.org/${res.Data}`);
        // window.open(`http://localhost:5292/${res.Data}`);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  // eslint-disable-next-line operator-linebreak
  const allQueriesLoading =
    clients9Loading || productTypesLoading || regionsLoading || adminUsersLoading;
  return (
    <div className="auth-modal body-style mt-3 p-5">
      <Helmet>
        <title>{`YMC Group | ${ t("dieselInvoice.DieselInquiryAboutclientData")}`}</title>
      </Helmet>
      <div className="text-center mb-5">
        <h2 className="">{t("navBar.dieselInvoiceSearch")}</h2>
      </div>
      <div>
        <Form onSubmit={handleSubmit(onSabmit)}>
          <Row className="gy-4 gx-4">
            {/* start Date */}
            <Col md={6} className="">
              <label className="mb-2" htmlFor="from">
                {t("dieselInvoice.startDate")}
              </label>
              <br />
              <Controller
                name="from"
                control={control}
                className="w-100"
                render={({ field }) => (
                  <DatePicker
                    className="w-100 react-datepicker__input-container"
                    selected={field.value}
                    placeholderText={t("dieselInvoice.startDate")}
                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    {...field}
                  />
                )}
              />
            </Col>
            <Col md={6} className="">
              <label className="mb-2" htmlFor="to">
                {t("dieselInvoice.endDate")}
              </label>
              <br />
              <Controller
                name="to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    selected={field.value}
                    placeholderText={t("dieselInvoice.endDate")}
                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    {...field}
                  />
                )}
              />
            </Col>
            {/* clients system 9 */}
            <Col md={6} className="">
              <label className="mb-2" htmlFor="clients9">
                {t("dieselInvoice.companyNameFromSystem9")}
              </label>
              <Select
                name="clients9"
                defaultValue={client}
                onChange={setClient}
                options={clients9}
                placeholder={t("dieselInvoice.companyNameFromSystem9")}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.Id}
              />
            </Col>
            {/* car Number */}
            <Col md={6} className="">
              <label className="mb-2" htmlFor="carNumber">
                {t("dieselInvoice.carNumber")}
              </label>
              <Select
                name="carNumber"
                defaultValue={carNumber}
                onChange={setCarNumber}
                options={carsNumber}
                placeholder={t("dieselInvoice.carNumber")}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </Col>
            {/* driver Name */}
            <Col md={6} className="">
              <label className="mb-2" htmlFor="driverName">
                {t("dieselInvoice.driverName")}
              </label>
              <Select
                name="driverName"
                defaultValue={driverName}
                onChange={setDriverName}
                options={driversName}
                placeholder={t("dieselInvoice.driverName")}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </Col>
            <Col md={6} className="">
              <label className="mb-2" htmlFor="dieselProductTypeId">
                {t("dieselInvoice.productType")}
              </label>
              <Select
                name="dieselProductTypeId"
                defaultValue={productType}
                onChange={setProductType}
                options={productTypes}
                placeholder={t("dieselInvoice.productType")}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.Id}
              />
            </Col>
            <Col md={6} className="">
              <label className="mb-2" htmlFor="region">
                {t("clients.selectRegion")}
              </label>
              <Select
                name="region"
                defaultValue={region}
                onChange={setRegion}
                options={regions}
                placeholder={t("clients.selectRegion")}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.Id}
              />
            </Col>
          </Row>
          {pageActions?.includes("dieselInvoiceSearch_ListView") && (
            <Row className="justify-content-center my-3">
              <Col md={6} className="">
                <button
                  type="submit"
                  disabled={
                    !pageActions?.includes("dieselInvoiceSearch_ListView")
                  }
                  className="main-button primary btn d-block w-50 m-auto"
                >
                  {t("search.search")}
                </button>
              </Col>
              <Col md={6}>
                <button
                  onClick={(e) => exportExcel(e)}
                  type="button"
                  className="btn btn-secondary d-block w-50 m-auto"
                  disabled={
                    !pageActions?.includes("dieselInvoiceSearch_ListView")
                  }
                >
                  {" "}
                  {t("search.exportExcel")}
                </button>
              </Col>
            </Row>
          )}
        </Form>
      </div>
      {resData?.Data?.length ? (
        <InvoicesTable data={resData.Data} productTypes={productTypes} adminUsers={adminUsers} />
      ) : (
        // eslint-disable-next-line react/jsx-one-expression-per-line
        <h3 className="text-center mt-4"> {t("search.noData")} </h3>
      )}
      <div className="d-flex justify-content-center">
        {resData?.Page > 0 && resData?.Data?.length ? (
          <div className="text-center mt-5 mx-4">
            <button
              onClick={() => {
                handlePagination(-1);
              }}
              type="button"
              className="btn btn-secondary px-5 pt-2"
            >
              {" "}
              {t("clients.previousData")}
            </button>
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <> </>
        )}
        {resData?.TotalCount > (page + 1) * pageSize &&
        resData?.Data?.length >= 20 ? (
          <div className="text-center mt-5 mx-4">
            <button
              onClick={() => {
                handlePagination(1);
              }}
              type="button"
              className="btn btn-secondary px-5 pt-2"
            >
              {" "}
              {t("clients.nextData")}
            </button>
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <> </>
        )}
      </div>
      {allQueriesLoading && <Loading />}
      {isloading && <Loading />}
    </div>
  );
}
export default DieselInvoiceSearch;
