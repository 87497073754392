/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useCallback, useEffect } from "react";
// eslint-disable-next-line object-curly-newline
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { toast } from "react-hot-toast";
import schema from "./schema";
import Api from "../../ymc-sdk/services";
import Loading from "../../components/Loading";

function ClientsTable({ data, status, users }) {
  const { t } = useTranslation();
  const [clientData, setClientData] = useState([]);
  const [user, setUser] = useState(null);
  const [isloading, setLoading] = useState(false);
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const adminUserData = useSelector((state) => state.auth?.userData);
  const pages = useSelector((state) => state.auth?.userData?.Pages);
  const isPageRouteValid = (pageRoute) => {
    return pages?.some((page) => page.PageRouting === pageRoute);
  };
  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });
  useEffect(() => {
    setClientData(data.map((client) => ({ ...client, isSelected: false })));
  }, [data]);
  const handleCheckboxChange = (clientId) => {
    setClientData((clientData) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      clientData.map((client) =>
        client.Id === clientId
          ? { ...client, isSelected: !client.isSelected }
          : client
      )
    );
  };
  const onSubmit = (data) => {
    // console.log(adminUserData);
    const selectedClients = clientData.filter((client) => client.isSelected);
    const newData = selectedClients.map((client) => ({
      UserId: data.UserId,
      ClientId: client.Id,
      ClientName: client.ClientName,
      ManagerId: adminUserData.Id,
      managerName: adminUserData.FullName,
    }));
    // console.log(newData);
    setLoading(true);
    Api.clientSearch
      .createNotificationAssignedToUser(newData)
      .then((res) => {
        setLoading(false);
        toast.success(t("shared.assignedSelectedClientsSuccessfully"));
        setClientData((clientData) =>
          clientData.map((client) => ({ ...client, isSelected: false }))
        );
      })
      .catch((err) => {
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  return (
    <div className="mt-3">
      <Table striped bordered hover className="mt-5 table-responsive mb-0">
        <thead className="text-white" style={{ backgroundColor: "#333" }}>
          <tr>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("clients.clientName")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("clients.region")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("clients.industry")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("clients.address")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("clients.status")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("clients.currentSalesAgent")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("clients.lastClientContactDate")}
            </th>
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("clients.additionalNotes")}
            </th>
            {((pageActions?.includes("clientSearch_Update") &&
              isPageRouteValid("editClient")) ||
              isPageRouteValid("clients")) && (
              <th
                className="bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("search.options")}
              </th>
            )}
            <th
              className="bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {t("clients.selectClient")}
            </th>
          </tr>
        </thead>
        <tbody>
          {clientData?.map((client) => (
            <tr key={client.Id}>
              <td>{client.ClientName}</td>
              <td>{client.Region?.RegionName}</td>
              <td>{client.Industry?.IndustryName}</td>
              <td>{client.Address}</td>
              <td>
                {
                  status?.find((st) => st.Id === client.ClientStatusId)
                    ?.StatusName
                }
              </td>
              <td>{client.AssignedUser?.UserName}</td>
              <td>{client.LastContactDateWithCompany?.substring(0, 10)}</td>
              <td>{client.Notes}</td>
              {((pageActions?.includes("clientSearch_Update") &&
                isPageRouteValid("editClient")) ||
                isPageRouteValid("clients")) && (
                <td>
                  <div className="d-flex">
                    {pageActions?.includes("clientSearch_Update") &&
                      isPageRouteValid("editClient") && (
                        <Button
                          size="sm"
                          variant="success"
                          className="me-2"
                          disabled={
                            (!pageActions?.includes("clientSearch_Update") &&
                              !pageActions?.includes("clientSearch_Delete")) ||
                            !isPageRouteValid("editClient")
                          }
                        >
                          <Link
                            className="text-white"
                            to={`/editClient/${client.Id}`}
                          >
                            {t("clients.editClient")}
                          </Link>
                        </Button>
                      )}
                    {isPageRouteValid("clients") && (
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        disabled={
                          // eslint-disable-next-line operator-linebreak
                          !isPageRouteValid("clients")
                        }
                      >
                        <Link
                          className="outline-secondary"
                          to={`/clients/${client.Id}`}
                        >
                          {t("clients.viewClient")}
                        </Link>
                      </Button>
                    )}
                  </div>
                </td>
              )}
              <td>
                <input
                  type="checkbox"
                  checked={client.isSelected} // You will need to manage the isSelected state in your component's state.
                  onChange={() => handleCheckboxChange(client.Id)} // Create a function to handle checkbox changes.
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Form className="w-100 mt-3" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {" "}
          <div className="col-12 col-sm-6 col-md-4 m-auto">
            <Controller
              name="UserId"
              control={control}
              render={({ usereField }) => (
                <Select
                  {...usereField}
                  name="UserId"
                  defaultValue={user}
                  value={user}
                  onChange={(selectedOption) => {
                    setUser(selectedOption); // Update the selected option in the state
                    setValue("UserId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                  }}
                  options={users}
                  placeholder={t("clients.selectUser")}
                  isSearchable
                  isClearable
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.Id}
                  className={errors.UserId && !user ? "error-border" : ""}
                />
              )}
            />
            {errors.UserId && !user ? (
              <p className="error-label">{errors.UserId.message}</p>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )}
          </div>
        </Row>
        <Row>
          <div className="col-12 col-sm-6 col-md-4 m-auto mt-3">
            <button
              type="submit"
              className="main-button primary btn d-block  m-auto"
            >
              {t("clients.assignSelectedClients")}
            </button>
          </div>
        </Row>
      </Form>
      {isloading && <Loading />}
    </div>
  );
}
export default ClientsTable;
