import React, { useState, useEffect } from 'react';
import {
  Form, Row, Col, Table, Label,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';
import InputField from '../../components/input';
import PaymentApiEndpoints from '../../ymc-sdk/services/terms.api';
import TermsInput from './termsInput';

function TermsForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // get payment terms
  const { data: allPaymentTerms } = useQuery(
    ['allPaymentTerms'],
    () => PaymentApiEndpoints.gePaymentTerms(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    },
  );
  useEffect(() => {
    if (allPaymentTerms) {
      const resetObj = {};
      // allPaymentTerms.forEach((el) => {
      //   resetObj.el.Name = el.Percentage;
      // });
      // console.log(resetObj, 'vvvvvvvvvvvvvvvv');
    }
  }, [allPaymentTerms]);

  return (
    <div className="p-5">
      <Row className="gx-5 gy-3">
        {allPaymentTerms && allPaymentTerms?.map((term) => (
          <div className="col-md-4">
            <TermsInput term={term} name={term.Name} />
          </div>
        ))}

      </Row>
    </div>
  );
}

export default TermsForm;
