import HttpHelpers from "./helpers";

const ClientsApiEndpoints = {
  getClientsSystem9: () => {
    return HttpHelpers.authenticatedAxios
      .get("Client/GetClientsFromSystem9")
      .then((response) => {
        // console.log(response);
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          el.label = el.ClientName;
          arrayData.push(el);
        });
        return arrayData;
      });
  },
  getClientsYMC: () => {
    return HttpHelpers.authenticatedAxios
      .get("Client/GetClientsFromYMCSystem")
      .then((response) => {
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          el.label = el.ClientName;
          arrayData.push(el);
        });
        return response?.data.Data;
      });
  },
  creteClient: (params, data) => {
    return HttpHelpers.authenticatedAxios
      .post("Client", data, { params })
      .then((response) => response.data);
  },
  updateClient: (Id, params, data) => {
    return HttpHelpers.authenticatedAxios
      .put(`Client/${Id}`, data, { params })
      .then((response) => response.data);
  },
  clientContactDetails: (params, data) => {
    return HttpHelpers.authenticatedAxios
      .post("ClientContactDetails", data, { params })
      .then((response) => response.data);
  },
  getClientDataById: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`Client/${id}`)
      .then((response) => response.data);
  },
  deleteClientById: (id, params) => {
    return HttpHelpers.authenticatedAxios
      .delete(`Client/HardDelete/${id}`, { params })
      .then((response) => response.data);
  },
  deleteContactClientById: (id, params) => {
    return HttpHelpers.authenticatedAxios
      .delete(`ClientContactDetails/HardDelete/${id}`, { params })
      .then((response) => response.data);
  },
  IsClientCodeUnique: (params) => {
    return HttpHelpers.authenticatedAxios
      .get(`Client/IsClientCodeUnique`, { params })
      .then((response) => response.data);
  },
};

export default ClientsApiEndpoints;
