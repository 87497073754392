/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Table, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import Loading from "../../components/Loading";
import {
  updateClientsSystem9,
  updateProductsSystem9,
} from "../../ymc-sdk/redux/actions/config.actions";

function SyncStstem9() {
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("UserId");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);

  const syncClents = () => {
    setLoading(true);
    dispatch(updateClientsSystem9(userId))
      .then((res) => {
        setLoading(false);
        toast.success(t("shared.updatedSuccessfully"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  const syncProducts = () => {
    setLoading(true);
    dispatch(updateProductsSystem9(userId))
      .then((res) => {
        setLoading(false);
        toast.success(t("shared.updatedSuccessfully"));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  return (
    <div className="my-5 text-center">
      <hr />
      {pageActions?.includes("config_Update") && (
        <Row>
          <Col md={6}>
            <button
              onClick={syncProducts}
              className="main-button primary px-5 pt-2 mt-3"
              disabled={!pageActions?.includes("config_Update")}
              type="button"
            >
              {t("configs.syncProductsData")}
            </button>
          </Col>
          <Col md={6}>
            <button
              onClick={syncClents}
              className="main-button primary px-5 pt-2 mt-3"
              disabled={!pageActions?.includes("config_Update")}
              type="button"
            >
              {t("configs.syncClientsData")}
            </button>
          </Col>
        </Row>
      )}
      {loading && <Loading />}
    </div>
  );
}

export default SyncStstem9;
