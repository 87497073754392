import React from 'react';
import EditOffer from './Edit offer';
import OfferInquiry from './OfferInquiry';
import OfferSearch from './OfferSearch';
import VersionHistory from './VersionHistory';

function PriceOfferPage() {
  return (
    <>
      <OfferSearch />
      {/* <EditOffer />
      <VersionHistory />
      <OfferInquiry /> */}
    </>
  );
}

export default PriceOfferPage;
