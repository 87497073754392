/* eslint-disable object-curly-newline */
import React, { useState, useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import {
  deleteClientById,
  deleteContactClientById,
} from "../../ymc-sdk/redux/actions/client.actions";
import Loading from "../../components/Loading";
import { deleteUserById } from "../../ymc-sdk/redux/actions/auth.actions";

function DeleteUser({ userId, userName, onCloseModal, ...props }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    const deletedById = localStorage.getItem("UserId");
    setLoading(true);
    dispatch(deleteUserById(userId, deletedById))
      .then((res) => {
        setLoading(false);
        props.onHide();
        onCloseModal();
        toast.success(t("shared.deletedSuccessfully"));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`${t("userSettings.deleteUser")} -> `}
          <span className="text-danger fw-bolder fs-2">{userName}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("userSettings.areYouWantToDeleteThisUser")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="md"
          variant="danger"
          className="me-2"
          onClick={handleDelete}
        >
          {t("userSettings.deleteUser")}
        </Button>
        <Button onClick={props.onHide} variant="secondary">
          {t("shared.cancel")}
        </Button>
      </Modal.Footer>
      {loading && <Loading />}
    </Modal>
  );
}

export default DeleteUser;
