/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Form, Row, Col, Label, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../dieselInvoiceSearch/datePickerCustomStyle.css";
import InputField from "../../components/input";
import {
  searchPriceOffer,
  exportPriceOffersWithProductsToExcelSheet,
  exportPriceOffersToExcelSheet,
} from "../../ymc-sdk/redux/actions/searchOffer.actions";
import AuthApiEndpoints from "../../ymc-sdk/services/auth.api";
import ClientsApiEndpoints from "../../ymc-sdk/services/client.api";
import Loading from "../../components/Loading";
import BinaryInput from "../../components/binary-input";

// get adminUsers

function OfferSearch() {
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const pages = useSelector((state) => state.auth?.userData?.Pages);
  const isPageRouteValid = (pageRoute) => {
    return pages?.some((page) => page.PageRouting === pageRoute);
  };
  // get clients system 9
  const { data: clients9 } = useQuery(
    ["Clients 9"],
    () => ClientsApiEndpoints.getClientsSystem9(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  // get clients system YMC
  const { data: clientsYMC, isLoading } = useQuery(
    ["Clients YMC"],
    () => ClientsApiEndpoints.getClientsYMC(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  const { data: adminUsers } = useQuery(
    ["admin users"],
    () => AuthApiEndpoints.getSystemUsers(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  const statusOptions = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Accepted" },
    { value: 2, label: "Rejected" },
  ];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    reset,
    register,
    handleSubmit,
    setError,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectClient, setSelectClient] = useState(null);
  const [status, setStatus] = useState(null);
  const [data, setData] = useState({
    Data: [],
    Page: 0,
    PageSize: 0,
    TotalCount: 0,
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [searchData, setSearchData] = useState({
    from: "",
    serialNumber: "",
    serial_number: "",
    to: "",
    status: "",
    userId: "",
    page,
    pageSize,
  });
  const [expoExel, setExportExel] = useState("1");

  // const excelWach = watch('excel');

  // useEffect(() => {
  //   console.log(excelWach, 'Ggggggggggggggg');
  //   setExportExel(excelWach);
  // }, [excelWach]);
  const onValidDate = (data) => {
    if (data.from) {
      const modifiedDate = new Date(data.from);
      modifiedDate.setHours(modifiedDate.getHours() + 2);
      data.from = modifiedDate;
    }
    if (data.to) {
      const modifiedDate = new Date(data.to);
      modifiedDate.setHours(modifiedDate.getHours() + 2);
      data.to = modifiedDate;
    }
    return data;
  };
  const onSubmit = (data) => {
    data.page = page;
    data.pageSize = pageSize;
    data.status = status?.value;
    data.userId = userId?.Id;
    data.clientId = selectClient?.Id;
    data.page = 0;
    data.pageSize = pageSize;
    data = onValidDate(data);
    setSearchData(data);
    setLoading(true);
    dispatch(searchPriceOffer(data))
      .then((res) => {
        setLoading(false);
        const updatedData = {
          ...data,
          Page: res.Page,
          PageSize: res.PageSize,
          TotalCount: res.TotalCount,
          Data: [...res.Data],
        };
        setData(updatedData);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  useEffect(() => {
    const newSearchData = { ...searchData };
    setLoading(true);
    dispatch(searchPriceOffer(newSearchData))
      .then((res) => {
        setLoading(false);
        const updatedData = {
          ...data,
          Page: res.Page,
          PageSize: res.PageSize,
          TotalCount: res.TotalCount,
          Data: [...res.Data],
        };
        setData(updatedData);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
    reset({ excel: "1" });
  }, []);
  const renderStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Accepted";
      case 2:
        return "Rejected";
      default:
        return "Not Available";
    }
  };
  const handlePagination = () => {
    setLoading(true);
    const newSearchData = { ...searchData, page: page + 1 };
    setPage(page + 1);
    setSearchData({
      ...searchData,
      page: page + 1,
    });
    dispatch(searchPriceOffer(newSearchData))
      .then((res) => {
        setLoading(false);
        const updatedData = {
          ...data,
          Page: res.Page,
          PageSize: res.PageSize,
          TotalCount: res.TotalCount,
          Data: [...res.Data],
        };
        setData(updatedData);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  const exportExcel = (e) => {
    const newSearchData = { ...searchData };
    const excelWach = watch("excel");
    console.log(newSearchData);
    e.preventDefault();
    setLoading(true);
    if (excelWach === "0") {
      dispatch(exportPriceOffersWithProductsToExcelSheet(newSearchData))
        .then((res) => {
          setLoading(false);
          window.open(`https://backendapi.ymcgroup.org/${res.Data}`);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(t("shared.sorrySomethingWrong"));
        });
    } else {
      dispatch(exportPriceOffersToExcelSheet(newSearchData))
        .then((res) => {
          setLoading(false);
          window.open(`https://backendapi.ymcgroup.org/${res.Data}`);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(t("shared.sorrySomethingWrong"));
        });
    }
  };
  // console.log(data);
  return (
    <div className="offer_search p-5 ">
      <Helmet>
        <title>{`YMC Group | ${ t("priceOffer.priceOfferSearch")}`}</title>
      </Helmet>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="gx-5 gy-3">
          <Col md={6}>
            <Label> {t("search.startDate")} </Label>
            <Controller
              name="from"
              control={control}
              className="w-100"
              render={({ field }) => (
                <DatePicker
                  className="w-100 react-datepicker__input-container"
                  selected={field.value}
                  placeholderText={t("dieselInvoice.startDate")}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  {...field}
                />
              )}
            />
          </Col>
          <Col md={6}>
            <Label>{t("search.endDate")}</Label>
            <br />
            <Controller
              name="to"
              control={control}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  placeholderText={t("dieselInvoice.endDate")}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  {...field}
                />
              )}
            />
          </Col>
          <Col md={6}>
            <Label> {t("search.selectUserName")} </Label>
            <Select
              defaultValue={userId}
              value={userId}
              onChange={setUserId}
              options={adminUsers}
              placeholder={t("search.selectUserName")}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
              isClearable
            />
          </Col>
          <Col md={6}>
            <Label> {t("search.poStatus")} </Label>
            <Select
              defaultValue={status}
              value={status}
              onChange={setStatus}
              options={statusOptions}
              placeholder={t("search.poStatus")}
              isClearable
            />
          </Col>
          <Col md={6}>
            <Label className="mb-2"> {t("search.newClienList")} </Label>
            <Select
              defaultValue={selectClient}
              onChange={setSelectClient}
              options={clientsYMC}
              placeholder={t("priceOffer.chooseClient")}
              isClearable
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
            />
          </Col>
          <Col md={6}>
            {/* (from system 9) */}
            <Label className="mb-2"> {t("search.clientSystem9")}</Label>
            <Select
              defaultValue={selectClient}
              onChange={setSelectClient}
              options={clients9}
              placeholder={t("priceOffer.chooseAnOption")}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
              isClearable
            />
          </Col>
          <Col md={6}>
            <Label> {t("search.poSerialNum")} </Label>
            <InputField
              placeholder="Serial Number"
              name="serialNumber"
              errors={errors}
              type="number"
              {...register("serialNumber")}
            />
          </Col>
          <Col md={6}>
            <Table> {t("search.exportExcel")}</Table>
            <BinaryInput
              id={1}
              label={t("search.priceOffer")}
              type="radio"
              value="1"
              className="cancellation-policy-label"
              name="excel"
              {...register("excel")}
            />
            <BinaryInput
              id={2}
              label={t("search.priceOfferWithProduct")}
              type="radio"
              value="0"
              className="cancellation-policy-label"
              name="excel"
              {...register("excel")}
            />
          </Col>
          {pageActions?.includes("offerSearch_ListView") && (
            <Col md={6}>
              <button
                type="submit"
                className="main-button primary btn d-block w-30 m-auto"
                disabled={!pageActions?.includes("offerSearch_ListView")}
              >
                {" "}
                {t("search.search")}{" "}
              </button>
            </Col>
          )}
          {pageActions?.includes("offerSearch_ListView") && (
            <Col md={6}>
              <button
                onClick={(e) => exportExcel(e)}
                type="button"
                className="btn btn-secondary px-5 pt-2"
                disabled={!pageActions?.includes("offerSearch_ListView")}
              >
                {" "}
                {t("search.exportExcel")}{" "}
              </button>
            </Col>
          )}
        </Row>
      </Form>
      {pageActions?.includes("offerSearch_ListView") &&
      data?.Data?.length > 0 ? (
        <Table striped bordered hover className="mt-5 table-responsive mb-0">
          <thead className="text-white" style={{ backgroundColor: "#333" }}>
            <tr>
              <th> {t("search.serialNumber")} </th>
              <th> {t("search.dateIssued")} </th>
              <th>{t("search.userName")} </th>
              <th> {t("search.clientName")} </th>
              <th> {t("search.totalPOValue")} </th>
              <th>{t("search.search")} </th>
              {((pageActions?.includes("offerSearch_Update") &&
                isPageRouteValid("editPriceOffer")) ||
                isPageRouteValid("history")) && (
                <th> {t("search.options")} </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.Data?.map((item) => (
              <tr key={item.Id}>
                <td>{item.SerialNumber}</td>
                <td>{item.CreationDate?.substring(0, 10)}</td>
                <td>
                  {adminUsers?.find((st) => st.Id === item.CreatedBy)?.FullName}
                </td>
                <td>{item?.Client?.ClientName}</td>
                <td>{item.Total}</td>
                <td>{renderStatus(item.Status)}</td>

                <td>
                  <div className="d-flex">
                    {pageActions?.includes("offerSearch_Update") &&
                      isPageRouteValid("editPriceOffer") && (
                        <Button
                          size="sm"
                          variant="success"
                          className="me-2"
                          disabled={
                            !pageActions?.includes("offerSearch_Update") ||
                            !isPageRouteValid("editPriceOffer")
                          }
                        >
                          <Link
                            className="text-white"
                            to={`/editPriceOffer/${item.Id}`}
                          >
                            {t("search.edit")}
                          </Link>
                        </Button>
                      )}
                    {isPageRouteValid("history") && (
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        disabled={!isPageRouteValid("history")}
                      >
                        <Link
                          className="outline-secondary"
                          to={`/history/${item.Id}`}
                        >
                          {t("search.view")}
                        </Link>
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <h3 className="text-center mt-4"> {t("search.noData")} </h3>
      )}
      {loading && <Loading />}
      {pageActions?.includes("offerSearch_ListView") &&
        data?.TotalCount > (page + 1) * pageSize && (
          <div className="text-center mt-5 ">
            <button
              onClick={handlePagination}
              type="button"
              className="btn btn-secondary px-5 pt-2"
            >
              {" "}
              {t("search.loadMore")}{" "}
            </button>
          </div>
        )}
    </div>
  );
}

export default OfferSearch;
// 0 pending
// 1 accepted
// 2 rejected
