import * as yup from "yup";
import i18n from "i18next";

const schema = yup
  .object({
    CarNumber: yup.string().required(() => i18n.t("diesalClients.carNumberIsRequired")),
    AdditionalCarInfo: yup.string(),
    ProuductTypeId: yup.string().required(() => i18n.t("diesalClients.pleaseChooseProductType")),
  })
  .required();

export default schema;
