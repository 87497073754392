import HttpHelpers from "./helpers";

const AuthApiEndpoints = {
  login: (data) => {
    return HttpHelpers.authenticatedAxios
      .post("User/Login", data)
      .then((response) => response.data);
  },
  getSystemUsers: (data) => {
    return HttpHelpers.authenticatedAxios
      .get("User/AdminGetAllUsers", data)
      .then((response) => {
        const arrayData = [];
        // console.log(response.data);
        response.data.Data.forEach((el) => {
          el.label = el.UserName;
          arrayData.push(el);
        });
        return arrayData;
      });
  },
  signUp: (data) => {
    return HttpHelpers.authenticatedAxios
      .post("User/RegisterUser", data)
      .then((response) => response.data);
  },
  getRoles: () => {
    return HttpHelpers.authenticatedAxios.get("Role").then((response) => {
      const arrayData = [];
      // console.log(response.data);
      response.data.Data.forEach((el) => {
        el.label = el.Name;
        arrayData.push(el);
      });
      return arrayData;
    });
  },
  addRole: (data) => {
    return HttpHelpers.authenticatedAxios
      .post("Role", data)
      .then((response) => response.data);
  },
  getUserData: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`User/admingetuserbyid/${id}`)
      .then((response) => response.data);
  },
  updateUserData: (userId, data) => {
    return HttpHelpers.authenticatedAxios
      .post(`User/AdminUpdateUser/${userId}`, data)
      .then((response) => response.data);
  },
  deleteUserById: (userId, deletedById) => {
    return HttpHelpers.authenticatedAxios
      .delete(`User/AdminDeleteUser/${userId}/${deletedById}`)
      .then((response) => response.data);
  },
  changeUserPassword: (userId, data) => {
    return HttpHelpers.authenticatedAxios
      .post(`User/ForgetPassword/${userId}`, data)
      .then((response) => response.data);
  },
};

export default AuthApiEndpoints;
