/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-debugger */
import React, { useState, useEffect } from 'react';
import { uid } from 'uid';
import {
  Table, Form, Row, Col, Button,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { GrAdd, GrLinkNext } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputField from '../../../components/input';
import schema from './schema';
import { clientContactDetails, getClientDataById, deleteContactClientById } from '../../../ymc-sdk/redux/actions/client.actions';
import Loading from '../../../components/Loading';

function ClientContacts({ clientId, offerId }) {
  const {
    reset, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getClientDataById(clientId)).then((res) => {
      console.log(res.Data);
      setTableData(res?.Data?.ClientContactDetails);
    }).catch((error) => {
      console.log(error);
    });
  }, []);
  const onSubmit = (data) => {
    setLoading(true);
    const userId = localStorage.getItem('UserId');
    data.createdBy = userId;
    data.clientId = clientId;
    /// ////////send client function
    dispatch(clientContactDetails({ userId }, data)).then((res) => {
      setLoading(false);
      data.Id = res.Data;
      const newData = { ...data };
      console.log(newData);
      setTableData((prevVals) => [...prevVals, newData]);
      reset();
      // navigate(`/CreateFirstTable/${res.Data}`);
    }).catch((error) => {
      setLoading(false);
      toast.error(t('shared.sorrySomethingWrong'));
    });
  };
  const handleDelete = (cid) => {
    const userId = localStorage.getItem('UserId');
    console.log(cid);
    dispatch(deleteContactClientById(cid, { userId })).then((res) => {
      setLoading(false);
      const data = [...tableData];
      setTableData(data.filter((item) => item.Id !== cid));
      toast.success(t('shared.deletedSuccessfully'));
    }).catch((error) => {
      setLoading(false);
      toast.error(t('shared.sorrySomethingWrong'));
    });
  };
  return (
    <div className="first_table">
      <h5 className="my-3 text-center">
        {' '}
        {t('priceOffer.clientDetails')}
        {' '}
      </h5>
      <Table striped bordered hover className="mt-5 table-responsive mb-0">
        <thead className="text-white" style={{ backgroundColor: '#333' }}>
          <tr>
            <th className="col-md-4 py-3">
              {' '}
              {t('priceOffer.name')}
              {' '}
            </th>
            <th className="col-md-4 py-3">
              {' '}
              {t('priceOffer.number')}
              {' '}
            </th>
            <th className="col-md-4 py-3">
              {' '}
              {t('priceOffer.position')}
              {' '}
            </th>
          </tr>
        </thead>
        { tableData.length > 0 ? (
          <tbody>
            {tableData?.map((el) => (
              <tr key={el.id}>
                <td className="col-md-4 py-3">{el.PersonName}</td>
                <td className="col-md-4 py-3">{el.PersonNumber}</td>
                <td className="d-flex justify-content-between w-100 col-md-4 py-3">
                  {el.PersonPosition}
                  <Button variant="danger" size="sm" onClick={() => handleDelete(el.Id)}><RiDeleteBin5Line /></Button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : '' }
        {/* form */}
      </Table>

      <div className="form_table">
        <Form className="w-100 mt-1" onSubmit={handleSubmit(onSubmit)}>
          <Row className="form_table">
            <Col xs={4} className="position-relative">
              <InputField
                placeholder={t('priceOffer.contactName')}
                name="PersonName"
                errors={errors}
                type="text"
                {...register('PersonName')}
              />
            </Col>
            <Col xs={4} className="position-relative">
              <InputField
                placeholder={t('priceOffer.contactNumber')}
                name="PersonNumber"
                errors={errors}
                type="number"
                {...register('PersonNumber')}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col xs={4} className="position-relative d-flex align-items-center">
              <InputField
                placeholder={t('priceOffer.contactPerson')}
                name="PersonPosition"
                errors={errors}
                type="text"
                {...register('PersonPosition')}
                wrapperClassName="ltr-input w-80"
              />
              <button title="add" type="submit" className="main-button secondary btn d-block m-auto p-2 text-center mt-0">
                Add
                {' '}
                <GrAdd />
              </button>
            </Col>

          </Row>
        </Form>
        <div className="mt-5">
          <Link style={{ width: 'maxContent' }} to={`/offerDetails/${offerId}`} className="main-button primary btn d-flex w-10 px-5 m-auto ">
            Next

            {/* <GrLinkNext /> */}
          </Link>
        </div>
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default ClientContacts;
