/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
import React, { useState, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "reactstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";
import InputField from "../../components/input";
import Loading from "../../components/Loading";
import {
  createInvoice,
  getCarById,
} from "../../ymc-sdk/redux/actions/diesalClients.actions";
import { getClientDataById } from "../../ymc-sdk/redux/actions/client.actions";
import Api from "../../ymc-sdk/services";
import CapturePhotoModal from "./capturePhotoModal";
import schema from "./schemaInvoice";

function DieselInvoiceForm() {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carId = searchParams.get("carId");
  const driverId = searchParams.get("driverId");
  const imagesUrl = process.env.REACT_APP_IMAGES_URL;
  const [isLoading, setLoading] = useState(false);
  const [productType, setProductType] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [carData, setCarData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gasolinePumpPhoto, setGasolinePumpPhoto] = useState("");
  const [carKmsCounterPhoto, setCarKmsCounterPhoto] = useState("");
  const [imageChoose, setImageChoose] = useState(0);
  const UserId = localStorage.getItem("UserId");
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);

  const {
    reset,
    control,
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

  const { data: productTypes, isLoading: productTypesLoading } = useQuery(
    ["productTypes"],
    () => Api.lookupData.getDiesalProductTypes(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("industries", data);
        return data;
      },
      // eslint-disable-next-line comma-dangle
    }
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getCarById(carId))
      .then((res) => {
        // console.log(res.Data);
        setCarData(res.Data);
        dispatch(getClientDataById(res.Data.ClientId))
          .then((response) => {
            // console.log(response.Data);
            setClientData(response.Data);
            Api.diesalClients.Invoice.getLastTimeKms({ CarId: carId })
              .then((res) => {
                // console.log(res);
                setValue("LastTimeKMS", res);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
              });
          })
          .catch((error) => {});
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);
  useEffect(() => {
    if (clientData && carData) {
      const DriverData = carData?.DriverCars.find(
        // eslint-disable-next-line comma-dangle
        (p) => p.DriverId === driverId
      )?.Driver;
      setValue("ClientName", clientData?.ClientName);
      setValue("CarNumber", carData?.CarNumber);
      setValue("DriverName", DriverData?.DriverName);
      setValue("DriverNationalId", DriverData?.NationalIdNumber);
      setValue("ClientCreditLimit", clientData?.CreditLimit);
      setValue("ClientNetDept", clientData?.ClientDebt);
    }
  }, [clientData, carData]);

  useEffect(() => {
    if (Object.keys(errors).length > 0 && !gasolinePumpPhoto) {
      setError("GasolinePumpDataImagePath", {
        type: "required",
        message: `${t("dieselInvoice.GasolinePumpPhotoRequired")}`,
      });
    }
    if (Object.keys(errors).length > 0 && !carKmsCounterPhoto) {
      setError("CarKMSCounterImagePath", {
        type: "required",
        message: `${t("dieselInvoice.CarKMSCounterPhotoRequired")}`,
      });
    }
  }, [errors]);

  const handlePhotoCapture = async (imageSrc) => {
    setLoading(true);
    // console.log(imageSrc);
    const formData = new FormData();
    const blob = await fetch(imageSrc).then((res) => res.blob());
    formData.append("files", blob, "captured_image.jpg");
    Api.uploadFiles
      .uploadImage("dieselClient", formData)
      .then((res) => {
        // console.log(res.Data[0]);
        if (imageChoose === 1) {
          setGasolinePumpPhoto(res.Data[0]);
        } else if (imageChoose === 2) {
          setCarKmsCounterPhoto(res.Data[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
    setIsModalOpen(false);
  };
  const handleGasolineImage = () => {
    setImageChoose(1);
    setIsModalOpen(true);
  };
  const handleCarKmsImage = () => {
    setImageChoose(2);
    setIsModalOpen(true);
  };
  const onSubmit = (data) => {
    if (!gasolinePumpPhoto) {
      setError("GasolinePumpDataImagePath", {
        type: "required",
        message: `${t("dieselInvoice.GasolinePumpPhotoRequired")}`,
      });
    } else {
      clearErrors("GasolinePumpDataImagePath");
    }

    if (!carKmsCounterPhoto) {
      setError("CarKMSCounterImagePath", {
        type: "required",
        message: `${t("dieselInvoice.CarKMSCounterPhotoRequired")}`,
      });
    } else {
      clearErrors("CarKMSCounterImagePath");
    }
    if (gasolinePumpPhoto && carKmsCounterPhoto) {
      setLoading(true);
      data.CreatedBy = UserId;
      data.GasolinePumpDataImagePath = gasolinePumpPhoto;
      data.CarKMSCounterImagePath = carKmsCounterPhoto;
      data.ClientId = clientData.Id;
      data.CarId = carId;
      data.DriverId = driverId;
      data.ProductTypeName = productType.label;
      if (
        clientData?.CreditLimit === null ||
        clientData?.CreditLimit === undefined
      ) {
        delete data.ClientCreditLimit;
      }
      if (
        clientData?.ClientDebt === null ||
        clientData?.ClientDebt === undefined
      ) {
        delete data.ClientNetDept;
      }
      // console.log("ssssssssssssssss");
      // console.log(data);
      dispatch(createInvoice({ UserId }, data))
        .then((res) => {
          toast.success(t("shared.addedSuccessfully"));
          setLoading(false);
          // console.log(res);
          reset();
          navigate(`/dieselScanner`);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
          toast.error(t("shared.sorrySomethingWrong"));
        });
    }
  };
  return (
    <div className="auth-modal body-style mt-3 p-5">
      <Helmet>
        <title>{`YMC Group | ${t("navBar.dieselInvoice")} | ${
          clientData?.ClientName || " "
        } | ${t("dieselInvoice.carNumber")} => ${carData?.CarNumber || " "}`}</title>
      </Helmet>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="gy-3 gx-4">
          {/* client name */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ClientName">
              {t("dieselInvoice.clientName")}
            </label>
            <InputField
              name="ClientName"
              placeholder={t("dieselInvoice.clientName")}
              errors={errors}
              type="text"
              readOnly
              {...register("ClientName")}
            />
          </Col>
          {/* car number */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="CarNumber">
              {t("dieselInvoice.carNumber")}
            </label>
            <InputField
              name="CarNumber"
              placeholder={t("dieselInvoice.carNumber")}
              errors={errors}
              type="text"
              readOnly
              {...register("CarNumber")}
            />
          </Col>
          {/* Driver Name */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="DriverName">
              {t("dieselInvoice.driverName")}
            </label>
            <InputField
              name="DriverName"
              placeholder={t("dieselInvoice.driverName")}
              errors={errors}
              type="text"
              readOnly
              {...register("DriverName")}
            />
          </Col>
          {/* Driver Id */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="DriverNationalId">
              {t("dieselInvoice.nationalIdNumber")}
            </label>
            <InputField
              name="DriverNationalId"
              placeholder={t("dieselInvoice.nationalIdNumber")}
              errors={errors}
              type="text"
              readOnly
              {...register("DriverNationalId")}
            />
          </Col>
          {/* Client credit limit */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ClientCreditLimit">
              {t("dieselInvoice.creditLimit")}
            </label>
            <InputField
              name="ClientCreditLimit"
              placeholder={t("dieselInvoice.creditLimit")}
              errors={errors}
              type="text"
              readOnly
              {...register("ClientCreditLimit")}
            />
          </Col>
          {/* Client net debt */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="ClientNetDept">
              {t("dieselInvoice.netDebt")}
            </label>
            <InputField
              name="ClientNetDept"
              placeholder={t("dieselInvoice.netDebt")}
              errors={errors}
              type="text"
              readOnly
              {...register("ClientNetDept")}
            />
          </Col>
          {/* product Type */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="DieselProductTypeId">
              {t("dieselInvoice.productType")}
              <span className="text-danger"> * </span>
            </label>
            <Controller
              name="DieselProductTypeId"
              control={control}
              render={({ productTypeField }) => (
                <Select
                  {...productTypeField}
                  name="DieselProductTypeId"
                  defaultValue={productType}
                  value={productType}
                  onChange={(selectedOption) => {
                    setProductType(selectedOption); // Update the selected option in the state
                    setValue("DieselProductTypeId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                  }}
                  options={productTypes}
                  placeholder={t("dieselInvoice.productType")}
                  isSearchable
                  isClearable
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.Id}
                  className={
                    errors.DieselProductTypeId && !productType
                      ? "error-border"
                      : ""
                  }
                />
              )}
            />
            {errors.DieselProductTypeId && !productType ? (
              <p className="error-label">
                {errors.DieselProductTypeId.message}
              </p>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )}
          </Col>
          {/* liters taken by car */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="LitersTakenByCar">
              {t("dieselInvoice.litersTakenByCar")}
              <span className="text-danger"> * </span>
            </label>
            <InputField
              name="LitersTakenByCar"
              placeholder={t("dieselInvoice.litersTakenByCar")}
              errors={errors}
              type="number"
              step="0.1"
              min={0}
              {...register("LitersTakenByCar")}
            />
          </Col>
          {/* total invoice value */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="TotalInvoiceValue">
              {t("dieselInvoice.totalInvoiceValue")}
              <span className="text-danger"> * </span>
            </label>
            <InputField
              name="TotalInvoiceValue"
              placeholder={t("dieselInvoice.totalInvoiceValue")}
              errors={errors}
              type="number"
              step="0.1"
              min={0}
              {...register("TotalInvoiceValue")}
            />
          </Col>
          {/*  last time Kms counter */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="LastTimeKMS">
              {t("dieselInvoice.lastTimeKmsCounter")}
              <span className="text-danger"> * </span>
            </label>
            <InputField
              name="LastTimeKMS"
              placeholder={t("dieselInvoice.lastTimeKmsCounter")}
              errors={errors}
              type="number"
              min={0}
              readOnly
              {...register("LastTimeKMS")}
            />
          </Col>
          {/*   Kms counter today */}
          <Col md={6} className="">
            <label className="mb-2" htmlFor="KmsCounterToday">
              {t("dieselInvoice.kmsCounterToday")}
              <span className="text-danger"> * </span>
            </label>
            <InputField
              name="KmsCounterToday"
              placeholder={t("dieselInvoice.kmsCounterToday")}
              errors={errors}
              type="number"
              min={0}
              {...register("KmsCounterToday")}
            />
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-around">
            {" "}
            {gasolinePumpPhoto ? (
              <img
                src={`${imagesUrl}${gasolinePumpPhoto}`}
                alt="gasolinePumpPhoto"
                style={{ width: "100px" }}
              />
            ) : (
              <div>
                <Button
                  size="md"
                  variant="outline-success"
                  className="me-2"
                  onClick={() => handleGasolineImage()}
                >
                  {t("dieselInvoice.uploadGasolinePumpPhoto")}
                </Button>
                <p className="error-label py-3">
                  {errors.GasolinePumpDataImagePath?.message}
                </p>
              </div>
            )}
            {carKmsCounterPhoto ? (
              <img
                src={`${imagesUrl}${carKmsCounterPhoto}`}
                alt="carKmsCounterPhoto"
                style={{ width: "100px" }}
              />
            ) : (
              <div>
                <Button
                  size="md"
                  variant="outline-success"
                  className="me-2"
                  onClick={handleCarKmsImage}
                >
                  {t("dieselInvoice.uploadCarKmsCounterPhoto")}
                </Button>
                <p className="error-label py-3">
                  {errors.CarKMSCounterImagePath?.message}
                </p>
              </div>
            )}
          </div>
        </Row>
        {pageActions?.includes("dieselInvoice_Create") && (
          <Row className="justify-content-center my-3">
            <Col md={6} lg={4} className="">
              <button
                type="submit"
                disabled={!pageActions?.includes("dieselInvoice_Create")}
                className="main-button primary btn d-block w-50 m-auto"
              >
                {t("dieselInvoice.submitData")}
              </button>
            </Col>
          </Row>
        )}
      </Form>
      <CapturePhotoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCapture={handlePhotoCapture}
      />
      {isLoading && <Loading />}
      {productTypesLoading && <Loading />}
    </div>
  );
}
export default DieselInvoiceForm;
