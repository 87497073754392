import HttpHelpers from './helpers';

const SearchOfferApiEndpoints = {

  searchPriceOffer: (params) => {
    return HttpHelpers.authenticatedAxios
      .get('PriceOffer/SearchPriceOffer', { params })
      .then((response) => response.data);
  },
  uploadPurchaseFile: (data) => {
    return HttpHelpers.authenticatedAxios
      .post('File/UploadFiles/AcceptedPOFiles', data)
      .then((response) => response.data);
  },
  getHistory: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`PriceOffer/GetHistoricalData/${id}`)
      .then((response) => response.data);
  },
  exportPriceOffersToExcelSheet: (params) => {
    return HttpHelpers.authenticatedAxios
      .get('PriceOffer/ExportPriceOffersToExcelSheet', { params })
      .then((response) => response.data);
  },
  exportPriceOffersWithProductsToExcelSheet: (params) => {
    return HttpHelpers.authenticatedAxios
      .get('PriceOffer/ExportPriceOffersWithProductsToExcelSheet', { params })
      .then((response) => response.data);
  },
  getTableHistory: (id) => {
    return HttpHelpers.authenticatedAxios
      .get(`PriceOfferItem/GetHistoricalPriceOfferItemData/${id}`)
      .then((response) => response.data);
  },

};

export default SearchOfferApiEndpoints;
