/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-curly-newline */
import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, FormGroup, FormFeedback, Row, Col } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { ErrorMessage } from "@hookform/error-message";
import schema from "./schema";
import InputField from "../../components/input";
import { signUp } from "../../ymc-sdk/redux/actions/auth.actions";
import Loading from "../../components/Loading";

function AddNewUser({ AllRoles, managerUsers, onCloseModal, ...props }) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [manager, setManager] = useState(null);
  const CreatedBy = localStorage.getItem("UserId");

  const {
    reset,
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

  const encryptPass = (pass) => {
    return btoa(pass);
  };

  const addUserMutation = useMutation((data) => {
    const currentDate = new Date();
    const isoString = currentDate.toISOString();
    data.Password = encryptPass(data.Password);
    data.CreatedBy = CreatedBy;
    data.LastUpdatedBy = CreatedBy;
    data.CreationDate = isoString;
    // data.LastModificationDate = isoString;
    // console.log(data);
    dispatch(signUp(data))
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setRole(null);
        setManager(null);
        reset();
        props.onHide();
        toast.success(t("shared.addedSuccessfully"));
        onCloseModal();
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        if (
          error?.response?.data?.Errors?.[0] === "This UserName used before"
        ) {
          // setError("UserName", "This UserName used before");
          setError("UserName", {
            type: "manual",
            message: t("userSettings.thisUserNameUsedBefore"),
          });
          toast.error(t("userSettings.thisUserNameUsedBefore"));
        } else {
          toast.error(t("shared.sorrySomethingWrong"));
        }
      });
  }, {});

  const onSubmit = (data) => {
    setLoading(true);
    addUserMutation.mutate(data);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("userSettings.addNewUser")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form_login" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <InputField
                placeholder={t("userSettings.userName")}
                name="UserName"
                errors={errors}
                type="text"
                {...register("UserName")}
              />
            </Col>
            <Col md={12}>
              <InputField
                placeholder={t("userSettings.fullName")}
                name="FullName"
                errors={errors}
                type="text"
                {...register("FullName")}
              />
            </Col>
            <Col className="position-relative" md={12}>
              <InputField
                placeholder={t("userSettings.password")}
                name="Password"
                errors={errors}
                type="Password"
                {...register("Password")}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col className="position-relative" md={12}>
              <InputField
                placeholder={t("userSettings.phoneNumber")}
                name="PhoneNumber"
                errors={errors}
                type="number"
                {...register("PhoneNumber")}
                wrapperClassName="ltr-input"
              />
            </Col>
            <Col md={12}>
              <FormGroup className="mb-4">
                <label className="mb-2 font-weight-bold" htmlFor="RoleId">
                  {t("userSettings.selectRole")}
                </label>
                <Controller
                  name="RoleId"
                  control={control}
                  render={({ roleField }) => (
                    <Select
                      {...roleField}
                      name="RoleId"
                      defaultValue={role}
                      value={role}
                      onChange={(selectedOption) => {
                        setRole(selectedOption); // Update the selected option in the state
                        setValue("RoleId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                      }}
                      options={AllRoles}
                      placeholder={t("userSettings.selectRole")}
                      isSearchable
                      isClearable
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.Id}
                      className={errors.RoleId && !role ? "error-border" : ""}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="RoleId"
                  render={({ message }) => (
                    <FormFeedback className="d-block">{message}</FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup className="mb-4">
                <label className="mb-2 font-weight-bold" htmlFor="ManagerId">
                  {t("userSettings.selectManager")}
                </label>
                <Controller
                  name="ManagerId"
                  control={control}
                  render={({ managerField }) => (
                    <Select
                      {...managerField}
                      name="ManagerId"
                      defaultValue={manager}
                      value={manager}
                      onChange={(selectedOption) => {
                        setManager(selectedOption); // Update the selected option in the state
                        setValue("ManagerId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                      }}
                      options={managerUsers}
                      placeholder={t("userSettings.selectManager")}
                      isSearchable
                      isClearable
                      getOptionLabel={(option) =>
                        `${option.UserName} | ${option.FullName}`
                      }
                      getOptionValue={(option) => option.Id}
                      className={
                        errors.ManagerId && !manager ? "error-border" : ""
                      }
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="ManagerId"
                  render={({ message }) => (
                    <FormFeedback className="d-block">{message}</FormFeedback>
                  )}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <button
                type="submit"
                className="main-button primary d-block btn m-auto mt-5"
              >
                {t("userSettings.addUser")}
              </button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {loading && <Loading />}
    </Modal>
  );
}

export default AddNewUser;
