/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown } from "react-bootstrap";
import { BiUserCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/images/Logo.png";
import Language from "../../Language";
import {
  getUserData,
  logOut,
} from "../../../ymc-sdk/redux/actions/auth.actions";

function Header() {
  const dispatch = useDispatch([]);
  const [authnticated, setAthnticated] = useState(true);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth?.userData);
  const pages = useSelector((state) => state.auth?.userData?.Pages);
  const isPageRouteValid = (pageRoute) => {
    return pages?.some((page) => page.PageRouting === pageRoute);
  };
  const handleLogout = () => {
    dispatch(logOut());
    navigate("/login");
  };
  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={Logo} className="logo" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0 align-items-lg-center">
            {isPageRouteValid("") && (
              <Link className="nav-link" to="/">
                {t("navBar.home")}
              </Link>
            )}
            {/* {isPageRouteValid("createOffer") && (
              <Link className="nav-link" to="/createOffer">
                {t("navBar.priceOffer")}
              </Link>
            )}
            {isPageRouteValid("offerSearch") && (
              <Link className="nav-link" to="/offerSearch">
                {t("navBar.search")}
              </Link>
            )}
            {isPageRouteValid("config") && (
              <Link className="nav-link" to="/config">
                {t("navBar.configs")}
              </Link>
            )} */}
            {isPageRouteValid("createOffer") +
              isPageRouteValid("offerSearch") +
              isPageRouteValid("config") >=
            2 ? (
              <NavDropdown
                title={t("navBar.priceOffer")}
                id="nav-dropdown-light-example"
                menuVariant="light"
              >
                {isPageRouteValid("createOffer") && (
                  <Link className="nav-link dropdown-item" to="/createOffer">
                    {t("navBar.priceOffer")}
                  </Link>
                )}
                {isPageRouteValid("offerSearch") && (
                  <Link className="nav-link dropdown-item" to="/offerSearch">
                    {t("navBar.search")}
                  </Link>
                )}
                {isPageRouteValid("config") && (
                  <Link className="nav-link dropdown-item" to="/config">
                    {t("navBar.configs")}
                  </Link>
                )}
              </NavDropdown>
            ) : (
              <>
                {isPageRouteValid("createOffer") && (
                  <Link className="nav-link" to="/createOffer">
                    {t("navBar.priceOffer")}
                  </Link>
                )}
                {isPageRouteValid("offerSearch") && (
                  <Link className="nav-link" to="/offerSearch">
                    {t("navBar.search")}
                  </Link>
                )}
                {isPageRouteValid("config") && (
                  <Link className="nav-link" to="/config">
                    {t("navBar.configs")}
                  </Link>
                )}
              </>
            )}
            {isPageRouteValid("addNewClient") &&
            isPageRouteValid("clientSearch") ? (
              <NavDropdown
                title={t("navBar.clients")}
                id="nav-dropdown-light-example"
                menuVariant="light"
              >
                <Link className="nav-link dropdown-item" to="/addNewClient">
                  {t("navBar.newClient")}
                </Link>
                <Link className="nav-link dropdown-item" to="/clientSearch">
                  {t("navBar.clientSearch")}
                </Link>
              </NavDropdown>
            ) : (
              <>
                {isPageRouteValid("addNewClient") && (
                  <Link className="nav-link" to="/addNewClient">
                    {t("navBar.newClient")}
                  </Link>
                )}
                {isPageRouteValid("clientSearch") && (
                  <Link className="nav-link" to="/clientSearch">
                    {t("navBar.clientSearch")}
                  </Link>
                )}
              </>
            )}
            {isPageRouteValid("dieselScanner") &&
            isPageRouteValid("dieselInvoiceSearch") ? (
              <NavDropdown
                title={t("navBar.dieselInvoice")}
                id="nav-dropdown-light-example"
                menuVariant="light"
              >
                <Link className="nav-link" to="/dieselScanner">
                  {t("navBar.dieselInvoice")}
                </Link>
                <Link
                  className="nav-link dropdown-item"
                  to="/dieselInvoiceSearch"
                >
                  {t("navBar.dieselInvoiceSearch")}
                </Link>
              </NavDropdown>
            ) : (
              <>
                {isPageRouteValid("dieselScanner") && (
                  <Link className="nav-link" to="/dieselScanner">
                    {t("navBar.dieselInvoice")}
                  </Link>
                )}
                {isPageRouteValid("dieselInvoiceSearch") && (
                  <Link className="nav-link" to="/dieselInvoiceSearch">
                    {t("navBar.dieselInvoiceSearch")}
                  </Link>
                )}
              </>
            )}
            {isPageRouteValid("userSettings") && (
              <Link className="nav-link" to="/userSettings">
                {t("navBar.createUser")}
              </Link>
            )}
            <Language />
            {authnticated && (
              <div className="nav-link" href="#action6">
                <div className="user px-lg-2 text-center">
                  <div className="user_icon">
                    <BiUserCircle />
                  </div>
                  <div className="user_text">
                    <h6 className="m-0">{auth?.UserName}</h6>
                    <a onClick={handleLogout} className="m-0" to="/">
                      {t("navBar.Logout")}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
