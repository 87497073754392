/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-hot-toast";
import BinaryInput from "../../components/binary-input";
import schema from "./schemaNewClient";
import InputField from "../../components/input";
import { cretePriceOffer } from "../../ymc-sdk/redux/actions/offer.actions";
import { creteClient } from "../../ymc-sdk/redux/actions/client.actions";
import OfferApiEndpoints from "../../ymc-sdk/services/offer.api";
import Loading from "../../components/Loading";
import Buttons from "../../components/Buttons";

function NewClient({ regions, clientsYMC, stetClientId, setOfferId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectClientYMC, setSelectClientYMC] = useState(null);
  const [selectRegion, setSelectRegion] = useState(null);
  const [selectIndustries, setSelectIndustries] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);

  // get industries
  const { data: industries } = useQuery(
    ["industries"],
    () => OfferApiEndpoints.getIndustries(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });
  useEffect(() => {
    /// //////////// disabled if he select data
    if (selectClientYMC) {
      // RegionId Id
      // console.log(selectClientYMC);
      // IndustryId
      // Address
      // ClientType
      regions.forEach((el) => {
        if (el.Id === selectClientYMC?.RegionId) {
          setSelectRegion(el);
        }
      });
      industries?.forEach((el) => {
        if (el.Id === selectClientYMC?.IndustryId) {
          setSelectIndustries(el);
        }
      });
      const selectedClientType = selectClientYMC?.ClientType ? "1" : "0";
      reset({
        ClientType: selectedClientType,
        ClientName: selectClientYMC?.ClientName,
        Address: selectClientYMC?.Address,
      });
    }
  }, [selectClientYMC]);
  const onSubmit = (data) => {
    setLoading(true);
    const userId = localStorage.getItem("UserId");
    data.createdBy = userId;
    data.RegionId = selectRegion?.Id;
    data.IndustryId = selectIndustries?.Id;
    /// //////// create offer function
    if (selectClientYMC) {
      data.clientId = selectClientYMC?.Id;
      dispatch(cretePriceOffer({ userId }, data))
        .then((res) => {
          setLoading(false);
          navigate(`/offerDetails/${res.Data}`);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(t("shared.sorrySomethingWrong"));
        });
    } else {
      data.AssignedUserId = userId;

      dispatch(creteClient({ userId }, data))
        .then((resClient) => {
          data.clientId = resClient.Data;
          dispatch(cretePriceOffer({ userId }, data))
            .then((res) => {
              setLoading(false);
              stetClientId(resClient.Data);
              setOfferId(res.Data);
            })
            .catch((error) => {
              setLoading(false);
              toast.error(t("shared.sorrySomethingWrong"));
            });
        })
        .catch((error) => {
          setLoading(false);
          toast.error(t("shared.sorrySomethingWrong"));
        });
    }
  };

  return (
    <div className="new_client pt-3">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="gx-5 gy-3">
          <Col md={6}>
            <label className="mb-2">{t("priceOffer.newClientsList")} </label>
            <Select
              defaultValue={selectClientYMC}
              onChange={setSelectClientYMC}
              options={clientsYMC}
              placeholder="Choose Client"
              isClearable
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
            />
          </Col>
          <Col md={6}>
            <label className="mb-2">{t("priceOffer.clientType")}</label>
            <BinaryInput
              errors={errors}
              id="company"
              label={t("priceOffer.company")}
              type="radio"
              value={0}
              className="cancellation-policy-label"
              name="cancellation_policy"
              {...register("ClientType")}
              readOnly={selectClientYMC}
              hideError
            />
            <BinaryInput
              errors={errors}
              id="trader"
              label={t("priceOffer.trader")}
              type="radio"
              value={1}
              className="cancellation-policy-label"
              name="cancellation_policy"
              readOnly={selectClientYMC}
              {...register("ClientType")}
              hideError
            />
          </Col>
          <Col md={6}>
            <label className="mb-2">{t("priceOffer.clientName")} :</label>
            <InputField
              placeholder={t("priceOffer.clientName")}
              name="client name"
              errors={errors}
              readOnly={selectClientYMC}
              type="text"
              {...register("ClientName")}
            />
          </Col>
          <Col md={6}>
            <label className="mb-2"> {t("priceOffer.industry")} </label>
            <Select
              defaultValue={selectIndustries}
              value={selectIndustries}
              onChange={setSelectIndustries}
              options={industries}
              placeholder={t("priceOffer.chooseIndustry")}
              isDisabled={selectClientYMC}
              isClearable
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
            />
          </Col>
          <Col md={6}>
            <label className="mb-2">{t("priceOffer.region")}</label>
            <Select
              cacheOptions
              defaultValue={selectRegion}
              value={selectRegion}
              onChange={setSelectRegion}
              options={regions}
              placeholder={t("priceOffer.chooseRegion")}
              isDisabled={selectClientYMC}
              isClearable
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.Id}
            />
          </Col>
          <Col md={6}>
            <label className="mb-2">{t("priceOffer.address")}</label>
            <InputField
              placeholder={t("priceOffer.clientAddress")}
              name="Address"
              errors={errors}
              readOnly={selectClientYMC}
              type="text"
              {...register("Address")}
            />
          </Col>
          <Col md={6}>
            <label className="mb-2">
              {" "}
              {t("priceOffer.clientNamePresentOnPriceOffer")}
            </label>
            <InputField
              placeholder="Enter User Name"
              name="ClientNameInPO"
              errors={errors}
              type="text"
              {...register("ClientNameInPO")}
            />
          </Col>
          <Col md={6}>
            <label className="mb-2"> {t("priceOffer.offerIsSentTo")}</label>
            <InputField
              placeholder="Enter Offer"
              name="priceOfferSentTo"
              errors={errors}
              type="text"
              {...register("priceOfferSentTo")}
            />
          </Col>
          {pageActions?.includes("createOffer_Create") && (
            <Col md={12}>
              <button
                type="submit"
                className="main-button primary btn d-block px-5  m-auto"
                disabled={!pageActions?.includes("createOffer_Create")}
              >
                {" "}
                {t("priceOffer.next")}
              </button>
            </Col>
          )}
        </Row>
      </Form>
      {loading && <Loading />}
    </div>
  );
}

export default NewClient;
