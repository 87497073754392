import HttpHelpers from "./helpers";

const RoleActionsEndPoints = {
  getActions: () => {
    return HttpHelpers.authenticatedAxios
      .get("Action/GetAllActions")
      .then((response) => {
        return response.data.Data;
      });
  },
  getAllPages: () => {
    return HttpHelpers.authenticatedAxios
      .get("RoleActions/GetAllPages")
      .then((response) => {
        return response.data.Data;
      });
  },
  getRoleData: (Id) => {
    return HttpHelpers.authenticatedAxios.get(`Role/${Id}`).then((response) => {
      return response.data;
    });
  },
  getRoleActions: (params) => {
    return HttpHelpers.authenticatedAxios
      .get(`RoleActions/GetRoleActionsByRoleId`, { params })
      .then((response) => {
        return response.data;
      });
  },
  updateRoleActions: (params, data) => {
    return HttpHelpers.authenticatedAxios
      .post(`RoleActions/UpdateRoleActions`, data, { params })
      .then((response) => {
        return response.data;
      });
  },
};

export default RoleActionsEndPoints;
