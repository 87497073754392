/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-curly-newline */
import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, FormGroup, FormFeedback, Row, Col } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import InputField from "../../components/input";
import Loading from "../../components/Loading";
import schema from "./addRoleSchema";
import Api from "../../ymc-sdk/services";

function AddNewRole({ onCloseModal, ...props }) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

  const addUserMutation = useMutation((data) => {
    Api.auth
      .addRole(data)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        reset();
        props.onHide();
        toast.success(t("shared.addedSuccessfully"));
        onCloseModal();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  }, {});

  const onSubmit = (data) => {
    setLoading(true);
    addUserMutation.mutate(data);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("userSettings.addNewRole")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form_login" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <InputField
                placeholder={t("userSettings.roleName")}
                name="Name"
                errors={errors}
                type="text"
                {...register("Name")}
              />
            </Col>
            <Col md={12}>
              <InputField
                placeholder={t("userSettings.arRoleName")}
                name="NameAr"
                errors={errors}
                type="text"
                {...register("NameAr")}
              />
            </Col>
            <Col md={12}>
              <button
                type="submit"
                className="main-button primary d-block btn m-auto mt-5"
              >
                {t("userSettings.addRole")}
              </button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {loading && <Loading />}
    </Modal>
  );
}

export default AddNewRole;
