/* eslint-disable import/prefer-default-export */
import Api from '../../services';

export const creteClient = (params, data) => {
  return (dispatch) => {
    return Api.clients.creteClient(params, data).then((response) => {
      dispatch({
        type: 'CREATE_CLIENT',
        data: response,
      });
      return response;
    });
  };
};

export const clientContactDetails = (params, data) => {
  return (dispatch) => {
    return Api.clients.clientContactDetails(params, data).then((response) => {
      dispatch({
        type: 'CREATE_CONTACT',
        data: response,
      });
      return response;
    });
  };
};
export const getClientDataById = (id) => {
  return (dispatch) => {
    return Api.clients.getClientDataById(id).then((response) => {
      dispatch({
        type: 'CREATE_CONTACT',
        data: response,
      });
      return response;
    });
  };
};
export const deleteClientById = (id, params) => {
  return (dispatch) => {
    return Api.clients.deleteClientById(id, params).then((response) => {
      dispatch({
        type: 'DELETE_CLIENT',
        data: response,
      });
      return response;
    });
  };
};
export const deleteContactClientById = (id, params) => {
  return (dispatch) => {
    return Api.clients.deleteContactClientById(id, params).then((response) => {
      dispatch({
        type: 'CREATE_CONTACT',
        data: response,
      });
      return response;
    });
  };
};
