/* eslint-disable import/prefer-default-export */
import Api from '../../services';

export const searchPriceOffer = (params) => {
  return (dispatch) => {
    return Api.search.searchPriceOffer(params).then((response) => {
      dispatch({
        type: 'SEARCH_OFFER',
        data: response,
      });
      return response;
    });
  };
};
export const uploadPurchaseFile = (data) => {
  return (dispatch) => {
    return Api.search.uploadPurchaseFile(data).then((response) => {
      dispatch({
        type: 'SEARCH_OFFER',
        data: response,
      });
      return response;
    });
  };
};
export const getHistory = (data) => {
  return (dispatch) => {
    return Api.search.getHistory(data).then((response) => {
      dispatch({
        type: 'SEARCH_OFFER',
        data: response,
      });
      return response;
    });
  };
};

export const exportPriceOffersToExcelSheet = (params) => {
  return (dispatch) => {
    return Api.search.exportPriceOffersToExcelSheet(params).then((response) => {
      dispatch({
        type: 'SEARCH_OFFER',
        data: response,
      });
      return response;
    });
  };
};

export const exportPriceOffersWithProductsToExcelSheet = (params) => {
  return (dispatch) => {
    return Api.search.exportPriceOffersWithProductsToExcelSheet(params).then((response) => {
      dispatch({
        type: 'SEARCH_OFFER',
        data: response,
      });
      return response;
    });
  };
};
export const getTableHistory = (id) => {
  return (dispatch) => {
    return Api.search.getTableHistory(id).then((response) => {
      dispatch({
        type: 'TABLE_SEARCH_OFFER',
        data: response,
      });
      return response;
    });
  };
};
