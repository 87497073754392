/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Col, Form, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../components/input";
import OfferApiEndpoints from "../../ymc-sdk/services/offer.api";
import Loading from "../../components/Loading";
import Api from "../../ymc-sdk/services";
import ClientsTable from "./clientsTable";
import AuthApiEndpoints from "../../ymc-sdk/services/auth.api";

function ClientSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isloading, setLoading] = useState(false);
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const [region, setRegion] = useState(null);
  const [status, setStatus] = useState(null);
  const [salesAgent, setSalesAgent] = useState(null);
  const [resData, setResData] = useState({
    Data: [],
    Page: 0,
    PageSize: 0,
    TotalCount: 0,
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [searchData, setSearchData] = useState({
    regionId: "",
    clientStatusId: "",
    assignedSalesManId: "",
    lastContactDate: null,
    clientCode: "",
    clientName: "",
    page,
    pageSize,
  });
  const { data: regions, isLoading: regionsLoading } = useQuery(
    ["regions"],
    () => OfferApiEndpoints.getRegions(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("regions", data);
        return data;
      },
    }
  );
  const { data: clientStatus, isLoading: clientStatusLoading } = useQuery(
    ["clientStatus"],
    () => Api.lookupData.getClientStatus(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("clientStatus", data);
        return data;
      },
    }
  );
  const { data: adminUsers, isLoading: adminUsersLoading } = useQuery(
    ["adminUsers"],
    () => AuthApiEndpoints.getSystemUsers(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  const {
    reset,
    register,
    handleSubmit,
    setError,
    control,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const formData = useWatch({ control });
  useEffect(() => {}, []);
  const onSabmit = (data) => {
    data.regionId = region?.Id;
    data.clientStatusId = status?.Id;
    data.assignedSalesManId = salesAgent?.Id;
    data.page = 0;
    data.pageSize = pageSize;
    // console.log(data);
    setPage(0);
    setLoading(true);
    setSearchData(data);
    // console.log(page, pageSize);
    Api.clientSearch
      .getAllClientsWithFilteration(data)
      .then((res) => {
        setLoading(false);
        // console.log(res.Data);
        setResData({
          ...resData,
          Data: [...res.Data.Data],
          Page: res.Data.Page,
          PageSize: res.Data.PageSize,
          TotalCount: res.Data.TotalCount,
        });
        // console.log(resData);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  const handlePagination = (updatePage) => {
    const newSearchData = { ...searchData, page: page + updatePage };
    setLoading(true);
    setSearchData({ ...searchData, page: page + updatePage });
    setPage(page + updatePage);
    // console.log(searchData);
    Api.clientSearch
      .getAllClientsWithFilteration(newSearchData)
      .then((res) => {
        setLoading(false);
        // console.log(res.Data);
        setResData({
          ...resData,
          Data: [...res.Data.Data],
          Page: res.Data.Page,
          PageSize: res.Data.PageSize,
          TotalCount: res.Data.TotalCount,
        });
        // console.log(resData);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  const exportExcel = (e) => {
    const filterData = { ...formData };
    filterData.regionId = region?.Id;
    filterData.clientStatusId = status?.Id;
    filterData.assignedSalesManId = salesAgent?.Id;
    // console.log(filterData);
    e.preventDefault();
    setLoading(true);
    Api.clientSearch
      .ExportFilteredClientsToExcel(filterData)
      .then((res) => {
        setLoading(false);
        // console.log(res.Data);
        window.open(`https://backendapi.ymcgroup.org/${res.Data}`);
        // window.open(`http://localhost:5292/${res.Data}`);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  // eslint-disable-next-line operator-linebreak
  const allQueriesLoading =
    regionsLoading || clientStatusLoading || adminUsersLoading;
  return (
    <div className="auth-modal body-style mt-3 p-5">
      <Helmet>
        <title>{`YMC Group | ${ t("navBar.clientSearch")}`}</title>
      </Helmet>
      <div className="text-center mb-4">
        <h2 className="">{t("clients.clients")}</h2>
      </div>
      <div>
        <Form onSubmit={handleSubmit(onSabmit)}>
          <Row className="gy-5 gx-4">
            {/* client code */}
            <Col md={6} className="">
              <label className="mb-2" htmlFor="clientCode">
                {t("clients.clientCode")}
              </label>
              <InputField
                name="clientCode"
                placeholder={t("clients.clientCode")}
                type="text"
                {...register("clientCode")}
              />
            </Col>
            {/* client Name */}
            <Col md={6} className="">
              <label className="mb-2" htmlFor="clientName">
                {t("clients.clientName")}
              </label>
              <InputField
                name="clientName"
                placeholder={t("clients.clientName")}
                type="text"
                {...register("clientName")}
              />
            </Col>
            <Col md={6} className="">
              <label className="mb-2" htmlFor="region">
                {t("clients.selectRegion")}
              </label>
              <Select
                name="region"
                defaultValue={region}
                onChange={setRegion}
                options={regions}
                placeholder={t("clients.selectRegion")}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.Id}
              />
            </Col>
            <Col md={6} className="">
              <label className="mb-2" htmlFor="clientStatus">
                {t("clients.clientStatus")}
              </label>
              <Select
                name="clientStatus"
                defaultValue={status}
                onChange={setStatus}
                options={clientStatus}
                placeholder={t("clients.selectClientStatus")}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </Col>
            <Col md={6} className="">
              <label className="mb-2" htmlFor="salesAgent">
                {t("clients.salesAgent")}
              </label>
              <Select
                name="salesAgent"
                defaultValue={salesAgent}
                onChange={setSalesAgent}
                options={adminUsers}
                placeholder={t("clients.selectAgent")}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </Col>
            <Col md={6} className="">
              <label className="mb-2" htmlFor="lastContactDate">
                {t("clients.lastClientContactDate")}
              </label>
              <InputField
                name="lastContactDate"
                errors={errors}
                type="date"
                {...register("lastContactDate")}
              />
            </Col>
            {pageActions?.includes("clientSearch_ListView") && (
              <Col md={6} className="">
                <button
                  type="submit"
                  disabled={!pageActions?.includes("clientSearch_ListView")}
                  className="main-button primary btn d-block w-50 m-auto"
                >
                  {t("search.search")}
                </button>
              </Col>
            )}
            {pageActions?.includes("clientSearch_ListView") && (
              <Col md={6}>
                <button
                  onClick={(e) => exportExcel(e)}
                  type="button"
                  className="btn btn-secondary d-block w-50 m-auto"
                  disabled={!pageActions?.includes("clientSearch_ListView")}
                >
                  {" "}
                  {t("search.exportExcel")}
                </button>
              </Col>
            )}
          </Row>
        </Form>
      </div>
      {resData?.Data?.length ? (
        <ClientsTable
          data={resData.Data}
          status={clientStatus}
          users={adminUsers}
        />
      ) : (
        // eslint-disable-next-line react/jsx-one-expression-per-line
        <h3 className="text-center mt-4"> {t("search.noData")} </h3>
      )}
      <div className="d-flex justify-content-center">
        {resData?.Page > 0 && resData?.Data?.length ? (
          <div className="text-center mt-5 mx-4">
            <button
              onClick={() => {
                handlePagination(-1);
              }}
              type="button"
              className="btn btn-secondary px-5 pt-2"
            >
              {" "}
              {t("clients.previousData")}
            </button>
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <> </>
        )}
        {resData?.TotalCount > (page + 1) * pageSize &&
        resData?.Data?.length >= 20 ? (
          <div className="text-center mt-5 mx-4">
            <button
              onClick={() => {
                handlePagination(1);
              }}
              type="button"
              className="btn btn-secondary px-5 pt-2"
            >
              {" "}
              {t("clients.nextData")}
            </button>
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <> </>
        )}
      </div>
      {allQueriesLoading && <Loading />}
      {isloading && <Loading />}
    </div>
  );
}
export default ClientSearch;
