import React, { useEffect, useState } from 'react';
import {
  Form, Row, Col, Label, Table,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { getTableHistory } from '../../ymc-sdk/redux/actions/searchOffer.actions';

function TableHistory() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { offerId } = useParams();
  useEffect(() => {
    dispatch(getTableHistory(offerId)).then((res) => {
    //   reset(res?.Data);
      setData(res);
      console.log(res, 'historryyyyyyyyyyyy');
    }).catch((error) => {
      console.log(error);
    });
  }, [offerId]);
  const renderStatus = (status) => {
    switch (status) {
      case 0:
        return 'Pending';
      case 1:
        return 'Accepted';
      case 2:
        return 'Rejected';
      default:
        return 'Not Available';
    }
  };
  return (
    <div>
      {data?.Data?.length > 0
        ? (
          <Table striped bordered hover className="mt-5 table-responsive mb-0">
            <thead className="text-white" style={{ backgroundColor: '#333' }}>
              <tr>
                <th className="col-md-2">
                  {' '}
                  {t('priceOffer.productName')}
                  {' '}
                </th>
                <th className="col-md-2">
                  {t('priceOffer.adjustedProductName')}
                  {' '}
                </th>
                <th className="col-md-1">
                  {' '}
                  {t('priceOffer.quantity')}
                  {' '}
                </th>
                <th className="col-md-1">
                  {' '}
                  {t('priceOffer.listPurchasePrice')}
                  {' '}
                </th>
                <th className="col-md-1">
                  {' '}
                  {t('priceOffer.sellingPrice')}
                  {' '}
                </th>
                <th className="col-md-1">
                  {' '}
                  {t('priceOffer.inventory')}
                  {' '}
                </th>
                <th className="col-md-1">
                  {' '}
                  {t('priceOffer.quantityRequestedByOtherPriceOffer')}
                  {' '}
                </th>
                {/* <th className="col-md-1"> Minimum Price Set </th> */}
                <th className="col-md-1">
                  {t('priceOffer.priceMatchingPaymentTerms')}
                  {' '}
                </th>
                <th className="col-md-1">
                  {' '}
                  {t('priceOffer.total')}
                  {' '}
                </th>
                <th className="col-md-1">
                  {' '}
                  Deleted or not
                  {' '}
                </th>
              </tr>
            </thead>
            { data?.Data?.length > 0 ? (
              <tbody>
                {data?.Data?.map((el) => (
                  <tr key={el?.Id}>
                    <td className="col-md-3">{el?.ProductName}</td>
                    <td className="col-md-2">{el?.ProductNamePO}</td>
                    <td className="col-md-1">{el?.Quantity}</td>
                    <td className="col-md-1">{el?.PurchasePriceWithNoTax}</td>
                    <td className="col-md-1">{el?.SellPriceWithNoTax}</td>
                    <td className="col-md-1">{el?.Inventory}</td>
                    <td className="col-md-1">{el?.QuantityRequestedInOtherPO}</td>
                    {/* <td className="col-md-1">{el?.MinPrice}</td> */}
                    <td className="col-md-1">{el?.IsPriceMatchPaymentTerms === false ? 'No' : 'Yes'}</td>
                    <td className="col-md-1">{el.Quantity * el.SellPriceWithNoTax}</td>
                    <td className="col-md-1">
                      {el?.IsDeleted ? 'Deleted' : 'Not Deleted'}

                    </td>

                  </tr>
                ))}
              </tbody>
            ) : '' }
            {/* form */}
          </Table>
        ) : (
          ''
        )}
      {loading && <Loading />}
    </div>
  );
}

export default TableHistory;
