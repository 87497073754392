import React from 'react';
import {
  FormGroup, Label, Input, FormFeedback,
} from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { getErrorsByPath } from '../../helpers';

const BinaryInput = React.forwardRef(({ hideError, ...props }, ref) => {
  const errors = props.errors || [];

  return (
    <FormGroup
      check
      className={`${props.className} ${
        props.type === 'switch' ? 'form-switch' : ''
      }`}
    >
      <Input
        {...props}
        id={props.label}
        type={props.type}
        className={props.className}
        innerRef={ref}
        invalid={getErrorsByPath(errors, props.name) && true}
        bsSize={props.bsSize}
        disabled={!!props.readOnly}

      />
      <Label size={props.labelSize} for={props.label} check>
        {props.label}
      </Label>
      {!hideError
      && (
        <ErrorMessage
          errors={errors}
          name={props.name}
          render={({ message }) => <FormFeedback>{message}</FormFeedback>}
        />
      )}
    </FormGroup>
  );
});

export default BinaryInput;
