import React from 'react';

function Footer() {
  return (
    <footer>
      <small>&copy; 2023 All Rights Reserved</small>
    </footer>
  );
}

export default Footer;
