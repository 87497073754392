/* eslint-disable func-names */
/* eslint-disable comma-dangle */
import * as yup from "yup";
import i18n from "i18next";
import Api from "../../ymc-sdk/services";

const schema = (clientCodeReadOnly) => {
  return yup
    .object({
      // driverName: yup
      //   .string()
      //   .required(() => i18n.t("diesalClients.driverNameIsRequired")),
      // ClientCode: yup.string(),
      ClientName: yup.string().required(() => i18n.t("auth.required")),
      RegionId: yup.string().required(() => i18n.t("auth.required")),
      Address: yup.string().required(() => i18n.t("auth.required")),
      // ClientEmail: yup.string().email(() => i18n.t("auth.Invalidemail")),
      PhoneNumber: yup.string().test({
        name: "phone",
        test: (value) => {
          if (!value || value.trim() === "") {
            // If the field is empty or undefined, it's considered valid.
            return true;
          }
          // Check the regular expression pattern for validation.
          return /^01[0125][0-9]{8}$/.test(value);
        },
        message: i18n.t("auth.phoneRequired"),
      }),
      IndustryId: yup.string().required(() => i18n.t("auth.required")),
      ProductCategoryId: yup.string().required(() => i18n.t("auth.required")),
      // CreditLimit: yup.number().default(0),
      // ContractualPaymentTerm: yup.number().default(0),
      // ActualPaymentPeriod: yup.number().default(0),
      AssignedUserId: yup.string().required(() => i18n.t("auth.required")),
      ClientStatusId: yup.string().required(() => i18n.t("auth.required")),
      // ManagementAddress: yup.string(),
      // Notes: yup.string(),
      IsPriceSentOffer: yup.boolean().required(() => i18n.t("auth.required")),
      // IsDatePriceSentOffer: yup.boolean()
      // IsPossibleToProvideServiceToCompany: yup.boolean(),
      // AttachInEmailPhotoGasPumpTaken: yup.boolean(),
      // EmailStandardFormat: yup.string(),
      ClientCode: yup
        .string()
        .when("ClientStatusId", ([ClientStatusId], schema) => {
          if (ClientStatusId === "c8a39ac7-9871-422c-b11d-6e3989395aad") {
            return schema.required();
          }
          return schema.notRequired();
        })
        .test(
          "unique-clientCode",
          i18n.t("auth.mustBeUnique"),
          // eslint-disable-next-line prefer-arrow-callback
          async function (value) {
            if (value && !clientCodeReadOnly) {
              try {
                const res = await Api.clients.IsClientCodeUnique({
                  clientCode: value,
                });
                // console.log(res);
                return res.Data;
              } catch (err) {
                return false;
              }
            }
            return true;
          }
        ),
    })
    .required();
};

export default schema;
