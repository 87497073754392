/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import AddNewRole from "./addNewRole";

function Roles({ AllRoles }) {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [isloading, setLoading] = useState(false);
  const [addingModalShow, setAddingModalShow] = useState(false);
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);
  const pages = useSelector((state) => state.auth?.userData?.Pages);
  const isPageRouteValid = (pageRoute) => {
    return pages?.some((page) => page.PageRouting === pageRoute);
  };
  // console.log(pageActions);
  const queryClient = useQueryClient();

  const handleCloseModalAndRefetch = () => {
    queryClient.invalidateQueries("user-roles");
    // Close the modal (you can implement modal closing logic here)
  };
  useEffect(() => {
    // console.log(AllRoles);
  }, [AllRoles]);

  return (
    <div className="">
      <div className="col-12 text-right">
        {pageActions?.includes("userSettings_Create") && (
          <Button
            size="md"
            className="main-button primary btn d-block m-auto me-2"
            disabled={!pageActions?.includes("userSettings_Create")}
            onClick={() => setAddingModalShow(true)}
          >
            {t("userSettings.addNewRole")}
          </Button>
        )}
      </div>
      {pageActions?.includes("userSettings_ListView") && (
        <Table striped bordered hover className="mt-3 table-responsive mb-0">
          <thead className="text-white" style={{ backgroundColor: "#333" }}>
            <tr>
              <th
                className="bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("userSettings.role")}
              </th>
              {pageActions?.includes("userSettings_Update") &&
                isPageRouteValid("editRoleActions") && (
                  <th
                    className="bg-dark text-white"
                    style={{ verticalAlign: "middle" }}
                  >
                    {t("userSettings.editRoleActions")}
                  </th>
                )}
            </tr>
          </thead>
          <tbody>
            {AllRoles?.map((role) => (
              <tr key={role.Id}>
                <td>{role.Name}</td>
                {pageActions?.includes("userSettings_Update") &&
                  isPageRouteValid("editRoleActions") && (
                    <td>
                      <Button
                        size="md"
                        className="main-button primary btn m-auto me-2"
                        disabled={
                          !pageActions?.includes("userSettings_Update") ||
                          !isPageRouteValid("editRoleActions")
                        }
                      >
                        <Link
                          className="text-white"
                          to={`/editRoleActions/${role.Id}`}
                        >
                          {t("userSettings.editRoleActions")}
                        </Link>
                      </Button>
                    </td>
                  )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <AddNewRole
        onCloseModal={handleCloseModalAndRefetch}
        show={addingModalShow}
        onHide={() => setAddingModalShow(false)}
      />
      {isloading && <Loading />}
    </div>
  );
}

export default Roles;
