/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import { uid } from "uid";
import { Table, Form, Row, Col, Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RiDeleteBin5Line } from "react-icons/ri";
import Select from "react-select";
import { GrAdd, GrLinkNext } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputField from "../../../components/input";
import schema from "./schemaCar";
import Loading from "../../../components/Loading";
import {
  createCar,
  deleteCar,
} from "../../../ymc-sdk/redux/actions/diesalClients.actions";

function ClientCars({
  clientId,
  productTypes,
  clientCars,
  onDataUpdate,
  isCreateValid,
  isDeleteValid,
}) {
  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableCarsData, setTableCarsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [productType, setProductType] = useState(null);

  useEffect(() => {
    if (clientCars) {
      setTableCarsData(clientCars);
      // console.log(clientCars);
    }
  }, [clientId, clientCars]);
  const onSubmit = (data) => {
    setLoading(true);
    const userId = localStorage.getItem("UserId");
    data.createdBy = userId;
    data.clientId = clientId;
    data.LitersUsedPerKiloMeter = 0;
    data.CarKiloMeterCounter = 0;
    // console.log(data);
    /// ////////send client function
    if (tableCarsData?.find((el) => el.CarNumber === data.CarNumber)) {
      setLoading(false);
      // console.log("This car is already exist with the same client");
      toast.error(t("clients.thisCarIsArleadyExistWithTheSameClient"));
    } else {
      dispatch(createCar({ UserId: userId }, data))
        .then((res) => {
          onDataUpdate();
          setLoading(false);
          // console.log(res);
          reset();
          setProductType(null);
        })
        .catch((err) => {
          // console.log(err?.response?.data?.Errors[0]);
          setLoading(false);
          if (
            // eslint-disable-next-line operator-linebreak
            err?.response?.data?.Errors[0] ===
            "This car is already exist with the other client you can not add the same car to different clients"
          ) {
            toast.error(t("clients.carExistWithOtherClient"));
          } else {
            toast.error(t("shared.sorrySomethingWrong"));
          }
        });
    }
  };
  const handleDelete = (cid) => {
    const userId = localStorage.getItem("UserId");
    setLoading(true);
    // console.log(cid);
    dispatch(deleteCar(cid, { userId }))
      .then((res) => {
        onDataUpdate();
        setLoading(false);
        toast.success(t("shared.deletedSuccessfully"));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };
  const handleDownloadQrcode = (qrCode, carNumber) => {
    const imageUrl = `data:image/jpeg;base64,${qrCode}`;
    const img = new Image();
    img.onload = () => {
      const width = 1060;
      const height = 1060;

      // Create a Canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the Canvas dimensions to the desired width and height
      canvas.width = width;
      canvas.height = height;

      // Draw the image on the Canvas with the desired dimensions
      ctx.drawImage(img, 0, 0, width, height);

      // Convert the Canvas content to a data URL
      const resizedImageUrl = canvas.toDataURL("image/jpeg");

      // Create an anchor element and trigger a click event to download the image
      const a = document.createElement("a");
      a.href = resizedImageUrl;
      a.download = `carNumber-${carNumber}.jpg`; // Replace 'image.jpg' with the desired file name
      a.click();
    };

    // Set the image source to load it
    img.src = imageUrl;
  };
  return (
    <div className="first_table">
      <h5 className="my-3 text-center"> {t("clients.cars")} </h5>
      <Table striped bordered hover className="mt-5 table-responsive mb-0">
        <thead className="text-white" style={{ backgroundColor: "#333" }}>
          <tr>
            <th
              className="col-md-3 py-3 bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {" "}
              {t("diesalClients.carNumber")}{" "}
            </th>
            <th
              className="col-md-3 py-3 bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {" "}
              {t("diesalClients.additionalCarInfo")}{" "}
            </th>
            <th
              className="col-md-3 py-3 bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {" "}
              {t("diesalClients.productType")}{" "}
            </th>
            <th
              className="col-md-3 py-3 bg-dark text-white"
              style={{ verticalAlign: "middle" }}
            >
              {" "}
              {t("diesalClients.carQRCode")}{" "}
            </th>
            {isDeleteValid && (
              <th
                className="col-md-3 py-3 bg-dark text-white"
                style={{ verticalAlign: "middle" }}
              >
                {t("search.options")}
              </th>
            )}
          </tr>
        </thead>
        {tableCarsData.length > 0 ? (
          <tbody>
            {tableCarsData?.map((el) => (
              <tr key={el.Id}>
                <td className="col-md-3 py-3">{el.CarNumber}</td>
                <td className="col-md-3 py-3">{el.AdditionalCarInfo}</td>
                <td className="col-md-3 py-3">
                  {productTypes?.find((p) => p.Id === el.ProuductTypeId)?.label}
                </td>
                <td className="d-flex justify-content-between w-100 col-md-3 py-3">
                  <img
                    src={`data:image/jpeg;base64,${el.CarQrCode}`}
                    alt="Base64 Image"
                    width="70px"
                    height="70px"
                    className="mx-2"
                  />
                  <div>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={
                        () =>
                          // eslint-disable-next-line implicit-arrow-linebreak
                          handleDownloadQrcode(el.CarQrCode, el.CarNumber)
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    >
                      download QRCode
                    </Button>
                  </div>
                </td>
                {isDeleteValid && (
                  <td className="col-md-3 py-3">
                    <Button
                      variant="danger"
                      size="md"
                      disabled={!isDeleteValid}
                      onClick={() => handleDelete(el.Id)}
                    >
                      <RiDeleteBin5Line size="1.5em" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        ) : (
          ""
        )}
        {/* form */}
      </Table>

      <div className="form_table">
        {isCreateValid && (
          <Form className="w-100 mt-1" onSubmit={handleSubmit(onSubmit)}>
            <Row className="form_table">
              <Col md={4} className="position-relative">
                <InputField
                  name="CarNumber"
                  placeholder={t("diesalClients.carNumber")}
                  //   label={t("diesalClients.carNumber")}
                  errors={errors}
                  type="text"
                  {...register("CarNumber")}
                />
              </Col>
              <Col md={4} className="position-relative">
                <textarea
                  className="form-control"
                  aria-label={t("diesalClients.additionalCarInfo")}
                  placeholder={t("diesalClients.additionalCarInfo")}
                  name="AdditionalCarInfo"
                  rows="2"
                  type="text"
                  {...register("AdditionalCarInfo")}
                />
              </Col>
              <Col
                xmd={4}
                className="position-relative d-flex align-items-center"
              >
                <div>
                  <Controller
                    name="ProuductTypeId"
                    control={control}
                    render={({ productTypeField }) => (
                      <Select
                        {...productTypeField}
                        name="ProuductTypeId"
                        defaultValue={productType}
                        value={productType}
                        onChange={(selectedOption) => {
                          setProductType(selectedOption); // Update the selected option in the state
                          setValue("ProuductTypeId", selectedOption?.Id); // Set the selected value to the 'favoriteFruit' field
                        }}
                        options={productTypes}
                        placeholder={t("diesalClients.productType")}
                        isSearchable
                        isClearable
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.Id}
                        className={
                          errors.ProuductTypeId && !productType
                            ? "error-border"
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.ProuductTypeId && !productType ? (
                    <p className="error-label">
                      {errors.ProuductTypeId.message}
                    </p>
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <></>
                  )}
                </div>

                <button
                  title="add"
                  type="submit"
                  disabled={!isCreateValid}
                  className="main-button secondary btn d-block m-auto p-2 text-center mt-0"
                >
                  {t("shared.add")} <GrAdd />
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default ClientCars;
