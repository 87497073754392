import React from "react";
import { Route, Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";

function ProtectedLayout({ pageRoute }) {
  const isAuthenticated = useSelector((state) => state.auth?.authenticated);
  const pages = useSelector((state) => state.auth?.userData?.Pages);
  //   console.log("sssssssssssssssss");
  if (!isAuthenticated) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/login" />;
  }
  const isPageRouteValid = pages?.some((page) => page.PageRouting === pageRoute);

  if (!isPageRouteValid) {
    return <Navigate to="/unauthorized" />;
  }
  return (
    <>
      <Header />
      <div style={{ minHeight: "72.5vh" }}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default ProtectedLayout;
