/* eslint-disable comma-dangle */
import * as yup from "yup";
import i18n from "i18next";

const schema = yup

  .object({
    NewPassword: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]+$/,
        "Password must contain at least one lowercase letter, one uppercase letter, one digit (0-9), and one non-alphanumeric character"
      )
      .min(6, "Password must be at least 6 characters long")
      .required("Password field is required"),
    ConfirmPassword: yup
      .string()
      .oneOf([yup.ref("NewPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  })
  .required();

export default schema;
