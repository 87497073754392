import HttpHelpers from "./helpers";

const LookupDataApiEndpoints = {
  getProductCategories: () => {
    return HttpHelpers.authenticatedAxios
      .get("LookupData/GetProductCategories")
      .then((response) => {
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          el.label = el.CategoryName;
          arrayData.push(el);
        });
        return arrayData; // JSON data parsed by `data.json()` call
      });
  },
  getClientStatus: () => {
    return HttpHelpers.authenticatedAxios
      .get("LookupData/GetClientStatus")
      .then((response) => {
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          el.label = el.StatusName;
          arrayData.push(el);
        });
        return arrayData; // JSON data parsed by `data.json()` call
      });
  },
  getDiesalProductTypes: () => {
    return HttpHelpers.authenticatedAxios
      .get("LookupData/GetDieselProductTypes")
      .then((response) => {
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          el.label = el.TypeName;
          arrayData.push(el);
        });
        return arrayData; // JSON data parsed by `data.json()` call
      });
  },
};

export default LookupDataApiEndpoints;
