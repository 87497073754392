import React, { useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import {
  updatePriceOffer,
} from '../../ymc-sdk/redux/actions/offer.actions';
import Pdf from './pdf';
import PdfAr from './pdfAR';
import { ComponentToPrint } from '../../components/print/ComponentToPrint';

function SubmitPdf({
  setLoading, offerData, issueFrom, IssueProduct, pdfLang,
}) {
  const dispatch = useDispatch();
  const { offerId } = useParams();
  /// /printing
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'emp-data',
  });

  const updateAndPrint = () => {
    // setLoading(true);
    handlePrint();

    // const userId = localStorage.getItem('UserId');
    // const newValues = { ...offerData };
    // newValues.Status = 0;
    // // handlePrint();
    // /// ////////send client function
    // dispatch(updatePriceOffer(offerId, { userId }, newValues)).then((res) => {
    //   setLoading(false);
    // }).catch((error) => {
    //   setLoading(false);
    //   toast.error('Something went wrong');
    // });
  };
  return (
    <>
      <div>
        {/* Your non-printable content goes here */}
        <div style={{ display: 'none' }}>
          {pdfLang === '1'
            ? <Pdf IssueProduct={IssueProduct} issueFrom={issueFrom} offerData={offerData} ref={componentRef} />
            : <PdfAr IssueProduct={IssueProduct} issueFrom={issueFrom} offerData={offerData} ref={componentRef} />}
        </div>

        <div style={{ display: 'none' }}>
          <ComponentToPrint
            Table={pdfLang === '1' ? Pdf : PdfAr}
            ref={componentRef}
            offerData={offerData}
            IssueProduct={IssueProduct}
            issueFrom={issueFrom}
          />
        </div>
      </div>
      <button type="button" className="main-button primary" onClick={updateAndPrint}>Submit (and publish PDF)</button>

    </>

  );
}

export default SubmitPdf;
