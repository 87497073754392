import HttpHelpers from "./helpers";

const SharedApiEndpoints = {
  getProducts: (lang) => {
    return HttpHelpers.authenticatedAxios
      .get("/ProductSystem9/GetProductsFromSystem9")
      .then((response) => {
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          if (lang.dir() === "ltr") {
            el.label = el.ProductNameEn;
            arrayData.push(el);
          } else {
            el.label = el.ProductNameAr;
            arrayData.push(el);
          }
        });
        return arrayData; // JSON data parsed by `data.json()` call
      });
  },
  getProductCategories: () => {
    return HttpHelpers.authenticatedAxios
      .get("LookupData/GetProductCategories")
      .then((response) => {
        const arrayData = [];
        response?.data.Data.forEach((el) => {
          el.label = el.CategoryName;
          arrayData.push(el);
        });
        return arrayData; // JSON data parsed by `data.json()` call
      });
  },
};

export default SharedApiEndpoints;
