/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable comma-dangle */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Loading from "../../components/Loading";
import ContactPerson from "./contactPerson/contactPerson";
import { getClientDataById } from "../../ymc-sdk/redux/actions/client.actions";
import Api from "../../ymc-sdk/services";
import ClientCars from "./clientCars/clientCars";
import ClientDriver from "./clientDrivers/clientDrivers";

function Client() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isloading, setLoading] = useState(false);
  const { clientId } = useParams();
  const [clientData, setClientData] = useState({});
  const [updatedClient, setUpdatedClient] = useState(false);
  const [update, setUpdate] = useState(false);
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);

  const { data: productTypes, isLoading: productTypesLoading } = useQuery(
    ["productTypes"],
    () => Api.lookupData.getDiesalProductTypes(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("industries", data);
        return data;
      },
    }
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getClientDataById(clientId))
      .then((res) => {
        // console.log(res.Data);
        setClientData(res.Data);
        setUpdate(!update);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    dispatch(getClientDataById(clientId))
      .then((res) => {
        // console.log(res.Data);
        setClientData(res.Data);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [updatedClient]);

  const handleDataUpdate = () => {
    setUpdatedClient(!updatedClient);
  };

  const allQueriesLoading = productTypesLoading;
  return (
    <div className="auth-modal body-style mt-3 px-5 py-3">
      <Helmet>
        <title>{`YMC Group | ${clientData?.ClientName || " "}`}</title>
      </Helmet>
      <div className="text-center mb-4">
        <h2 className="">{clientData?.ClientName}</h2>
      </div>
      <Tabs
        defaultActiveKey="contactPerson"
        id="fill-tab-example"
        className="mb-3"
        fill
      >
        <Tab eventKey="contactPerson" title={t("clients.contactPerson")}>
          <ContactPerson
            clientId={clientId}
            contactDetails={clientData?.ClientContactDetails}
            isCreateValid={pageActions?.includes("clients_Create")}
            isDeleteValid={pageActions?.includes("clients_Delete")}
          />
        </Tab>
        <Tab eventKey="cars" title={t("clients.cars")}>
          <ClientCars
            clientId={clientId}
            clientCars={clientData?.ClientCars}
            productTypes={productTypes}
            onDataUpdate={handleDataUpdate}
            isCreateValid={pageActions?.includes("clients_Create")}
            isDeleteValid={pageActions?.includes("clients_Delete")}
          />
        </Tab>
        <Tab eventKey="drivers" title={t("clients.drivers")}>
          <ClientDriver
            clientId={clientId}
            clientCars={clientData?.ClientCars}
            onDataUpdate={handleDataUpdate}
            isCreateValid={pageActions?.includes("clients_Create")}
            isDeleteValid={pageActions?.includes("clients_Delete")}
          />
        </Tab>
      </Tabs>
      {isloading && <Loading />}
      {allQueriesLoading && <Loading />}
    </div>
  );
}
export default Client;
