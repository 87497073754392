import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

function PdfTableHeader({ pdfLang }) {
  const styles = StyleSheet.create({
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    container: {
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      display: 'flex',
      color: '#ba2928',
      border: '2px solid #ba2928',
      fontStyle: 'bold',
      marginTop: '20px',
      fontSize: '12px',
    },
    description: {
      width: '60%',
      padding: '5px',
      color: '#ba2928',
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      display: 'flex',
    },
    qty: {
      width: '20%',
      borderRight: '2px solid #ba2928',
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      display: 'flex',
      borderLeft: '2px solid #ba2928',
      padding: '5px',

    },
    rate: {
      width: '20%',
      padding: '5px',
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      display: 'flex',
    },
  });
  return (
    // <View style={styles.table}>
    <View style={styles.container}>
      <View style={styles.description}>
        <Text>
          {pdfLang === '1' ? 'Product Description' : 'الصنف' }
        </Text>
      </View>
      <View style={styles.qty}>
        <Text>
          {' '}
          {pdfLang === '1' ? 'Price' : 'السعر' }
        </Text>
      </View>
      <View style={styles.rate}>
        <Text>
          {' '}
          {pdfLang === '1' ? 'Quantity' : 'الكميه' }
        </Text>
      </View>
    </View>
    // </View>
  );
}

export default PdfTableHeader;
