/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';
import footerAr from '../../assets/images/Header/arFooter.png';
import enFooter from '../../assets/images/Header/enFooter.png';
import headerAr from '../../assets/images/Header/arHeader.png';
import headerEn from '../../assets/images/Header/enHeader.png';
import arYasser from '../../assets/images/Header/arYasser.png';
import enYasser from '../../assets/images/Header/enYasser.png';

// const Pdf = forwardRef((props, ref) => {
function PdfAr({ offerData, issueFrom, IssueProduct }) {
  return (
    <div style={{ direction: 'rtl' }} className="printable-content">
      <div className="pdf-header">
        <img src={headerAr} alt="" className="img-fluid w-100" />
      </div>
      <div className="body-content px-3">
        <h5 style={{ fontSize: '1.5rem' }} className="text-center mb-3 mt-2"> عرض أسعار  </h5>
        <Row>
          <Col xs={9}>
            <div className="d-flex">
              <p className="font-weight-bold mb-0">  شركه  : </p>
              <p className="px-2 mb-0">
                {' '}
                {offerData?.ClientNameInPO}
              </p>
            </div>
          </Col>
          <Col xs={3}>
            <div className="d-flex">
              <p className="font-weight-bold mb-0">عنايه :</p>
              <p className="px-2 mb-0">
                {' '}
                {offerData?.PriceOfferSentTo}
              </p>
            </div>
          </Col>
          <Col xs={9}>
            <div className="d-flex mt-2">
              <p className="font-weight-bold  mb-0">التاريخ :</p>
              <p className="px-2 mb-0">
                {' '}
                {offerData?.CreationDate?.substring(0, 10)}
              </p>
            </div>
          </Col>
          <Col xs={3}>
            <div className="d-flex mt-2">
              <p className="font-weight-bold  mb-0">عرض سعر :</p>
              <p className="px-2 mb-0">
                #
                {offerData?.SerialNumber}
              </p>
            </div>
          </Col>
        </Row>

        <Table bordered hover className="mt-2 table-responsive mb-0 pdf-table mb-1">
          <thead className="">
            <tr>
              <th className="col-md-6 py-1"> الصنف</th>
              <th className="col-md-3 py-1"> السعر </th>
              <th className="col-md-3 py-1"> الكميه </th>
            </tr>

          </thead>
          <tbody>
            {offerData?.PriceOfferItems.map((el) => (
              <tr key={el.PriceOfferId}>
                <td className="col-md-6 py-1">

                  {IssueProduct === '1' ? el.ProductNamePO : el.ProductName }
                </td>
                <td className="col-md-3 py-1">{el.SellPriceWithNoTax}</td>
                <td className="col-md-6 py-1">{el.Quantity}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <p className="pt-1">* تخضع الشركه لنظام الدفعات زز المقدمه  </p>
        <ul className="px-3">
          <li>
            <div className="d-flex">
              <ins className="font-weight-bold text-underline"> صلاحيه عرض السعر : </ins>
              <p className="px-2 m-0">
                {' '}
                عرض السعر ساري لمده
                {' '}
                {offerData?.PriceOfferValidity}
                {' '}
                ساعه
              </p>
            </div>
          </li>
          <li>
            <div className="d-flex">
              <ins style={{ width: '15%' }} className="font-weight-bold text-underline">    شروط الدفع:</ins>
              <p className="px-2 m-0">
                {' '}
                شروط الدفع
                {' '}
                {offerData?.PaymentTerms?.Name}
                {' '}
                , نقدا او بتحويل بنكي او من خلال شيك
              </p>
            </div>
          </li>
          <li>
            <div className="d-flex">
              <p className="m-0"> الأسعار غير شامله القيمه المضافه 14% </p>
            </div>
          </li>
          <li>
            <div className="d-flex">
              <p className="m-0">
                {' '}
                الأسعار ساريه ما لم يكن هناك تغيير في الاسعار من قبل الشركات المنتجه حتى تاريخ استلام امر التوريد مع شروط توافر المنتجات لدى الشركه المنتجه
                {' '}

              </p>
            </div>
          </li>
          <li>
            <div className="d-flex">
              {/* <p className="m-0"> معلومات إضافيه :</p> */}
              <p style={{ whiteSpace: 'pre-wrap' }} className="m-0 px-2 ">{offerData?.Notes}</p>
            </div>
          </li>
        </ul>
        <h6 className="m-0"> في حال وجود اي اسئله او ملاحظات يرجى عدم التردد في الاتصال بنا </h6>
        <h6 className="pt-3 m-0">
          {' '}
          {offerData?.SalesRepresentitiveName}
          {' '}
          {offerData?.SalesRepresentitiveNumber}
        </h6>
      </div>
      <div className="pdf-footer">
        <img src={footerAr} alt="" className="img-fluid w-100" />
      </div>
    </div>
  );
}

export default PdfAr;
