/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

function Buttons({ text, disabled }) {
  return (
    <button
      type="submit"
      className="main-button primary btn d-block w-30 m-auto"
      disabled
    >
      {" "}
      {text} <BsFillArrowRightCircleFill />
    </button>
  );
}

export default Buttons;
