/* eslint-disable object-curly-newline */
import React, { useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import Button from "react-bootstrap/Button";
import { RiLock2Line } from "react-icons/ri";
import { MdLock } from 'react-icons/md';

function Unauthorized() {
  return (
    <div className="auth-modal body-style mt-3 px-5 py-3 text-center">
      <Helmet>
        <title>YMC Group | Unauthorized Access</title>
      </Helmet>
      {/* Use the imported icon */}
      <RiLock2Line size={150} color="#dd2c00" />
      {/* <MdLock size={96} color="#ff0000" /> */}
      <h1>Unauthorized Access</h1>
      <p>You do not have permission to access this page.</p>
    </div>
  );
}

export default Unauthorized;
