/* eslint-disable default-param-last */
import StorageService from '../../storage-service';

const AuthReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      StorageService.set('Token', action.data.Data.Token);
      StorageService.set('UserId', action.data.Data.UserId);
      StorageService.set('auth', true);
      if (action.error) return state;
      return {
        ...state,
        authenticated: true,
        userData: action.data.Data,
      };
    case 'SIGN_UP':
      if (action.error) return state;
      return {
        ...state,
        // authenticated: true,
      };
    case 'GET_USER':
      if (action.error) return state;
      return {
        ...state,
        authenticated: true,
        userData: action.data.Data,
      };
    case 'LOGOUT':
      StorageService.set('Token', null);
      StorageService.set('UserId', null);
      StorageService.set('auth', false);
      if (action.error) return state;
      return {
        ...state,
        authenticated: false,
        userData: null,
      };
    default:
      return state;
  }
};

export default AuthReducer;
