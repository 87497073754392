import React, { useEffect, useState } from 'react';
import {
  Form, Row, Col, Table,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import BinaryInput from '../../components/binary-input';
import InputField from '../../components/input';

function Inputs({
  offerData, setIssueFrom, setIssueProduct, setPdfLaNG,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    reset, register, watch, setError, formState: { errors },
  } = useForm({ mode: 'onChange' });

  const IssueProduct = watch('IssueProduct');
  const issueFrom = watch('issueFrom');
  const pdfLang = watch('pdfLang');
  useEffect(() => {
    setIssueFrom(issueFrom);
  }, [issueFrom]);
  useEffect(() => {
    setIssueProduct(IssueProduct);
  }, [IssueProduct]);
  useEffect(() => {
    setPdfLaNG(pdfLang);
  }, [pdfLang]);
  useEffect(() => {
    if (offerData) {
      const newValues = { ...offerData };
      newValues.PaymentTerms = newValues.PaymentTerms?.Name;
      newValues.Client = newValues.Client?.ClientName;
      newValues.issueFrom = '1';
      newValues.IssueProduct = '1';
      newValues.pdfLang = '0';
      newValues.CreationDate = newValues?.CreationDate?.substring(0, 10);
      // console.log(newValues?.CreationDate?.substring(0, 10));
      reset(newValues);
      // Name
    }
  }, [offerData]);
  return (
    <div className="new_client pt-3">
      <Form>
        <Row className="gx-5 gy-3">
          <Col md={3}>
            <Table>
              {' '}
              {t('priceOffer.priceOfferDate')}
            </Table>
            <InputField
              type="text"
              disabled
              name="CreationDate"
              {...register('CreationDate')}
            />
          </Col>
          <Col md={3}>
            <Table>
              {t('priceOffer.priceOfferExpectedReplyDate')}
              {' '}
            </Table>
            <InputField
              type="text"
              disabled
              name="ExpectedDateForClientReply"
              {...register('ExpectedDateForClientReply')}
            />
          </Col>
          <Col md={3}>
            <Table>
              {t('search.poSerialNum')}
              {' '}
            </Table>
            <InputField
              type="text"
              disabled
              name="SerialNumber"
              {...register('SerialNumber')}
            />
          </Col>
          <Col md={3}>
            <Table>
              {t('priceOffer.expectedDateToReceivePaymentCheck')}
              {' '}
            </Table>
            <InputField
              type="text"
              disabled
              name="ExptectedDateToRecievePaymentCheck"
              {...register('ExptectedDateToRecievePaymentCheck')}
            />
          </Col>
          <Col md={3}>
            <Table>{t('priceOffer.contactName')}</Table>
            <InputField
              type="text"
              disabled
              name="Client"
              {...register('Client')}
            />
          </Col>
          <Col md={3}>
            <Table>
              {' '}
              {t('priceOffer.offerIsSentTo')}
            </Table>
            <InputField
              type="text"
              disabled
              name="PriceOfferSentTo"
              {...register('PriceOfferSentTo')}
            />
          </Col>
          <Col md={3}>
            <Table>
              {' '}
              {t('priceOffer.priceOfferValidity')}
              {' '}
            </Table>
            <InputField
              type="text"
              disabled
              name="PriceOfferValidity"
              {...register('PriceOfferValidity')}
            />
          </Col>
          <Col md={3}>
            <Table>
              {' '}
              {t('priceOffer.paymentTerms')}
              {' '}
            </Table>
            <InputField
              type="text"
              disabled
              name="PaymentTerms"
              {...register('PaymentTerms')}
            />
          </Col>
          <Col md={3}>
            <Table>
              {' '}
              {t('priceOffer.poResponsiblePersonName')}
              {' '}
            </Table>
            <InputField
              type="text"
              disabled
              name="SalesRepresentitiveName"
              {...register('SalesRepresentitiveName')}
            />
          </Col>
          <Col md={3}>
            <Table>
              {' '}
              {t('priceOffer.poResponsiblePersonNumber')}
              {' '}
            </Table>
            <InputField
              type="text"
              disabled
              name="SalesRepresentitiveNumber"
              {...register('SalesRepresentitiveNumber')}
            />
          </Col>
          <Col md={6}>
            <Table>
              {t('priceOffer.additionalInformation')}
              {' '}
            </Table>
            <textarea
              rows="5"
              style={{ width: '100%' }}
              name="Notes"
              type="number"
              disabled
              {...register('Notes')}
            />
          </Col>
          <Col md={3}>
            <Table>
              {' '}
              {t('priceOffer.issueProductsAs')}
              {' '}
            </Table>
            <BinaryInput
              id={1}
              label={t('priceOffer.productNameSystem9')}
              type="radio"
              value="0"
              className="cancellation-policy-label"
              name="IssueProduct"
              disabled
              {...register('IssueProduct')}
            />
            <BinaryInput
              id={2}
              label={t('priceOffer.productNameWritten')}
              type="radio"
              value="1"
              className="cancellation-policy-label"
              name="IssueProduct"
              disabled
              {...register('IssueProduct')}
            />
          </Col>
          <Col md={3}>
            <Table>{t('priceOffer.issuePoAs')}</Table>
            <BinaryInput
              id={1}
              label={t('priceOffer.yasserMohamedCompany')}
              type="radio"
              value="0"
              className="cancellation-policy-label"
              name="issueFrom"
              disabled
              {...register('issueFrom')}
            />
            <BinaryInput
              id={2}
              label={t('priceOffer.yasserMohamedName')}
              type="radio"
              value="1"
              className="cancellation-policy-label"
              name="issueFrom"
              disabled
              {...register('issueFrom')}
            />
          </Col>
          <Col md={3}>
            <Table>{t('priceOffer.choosePdfLang')}</Table>
            <BinaryInput
              id={1}
              label={t('priceOffer.english')}
              type="radio"
              value="1"
              className="cancellation-policy-label"
              name="pdfLang"
              disabled
              {...register('pdfLang')}
            />
            <BinaryInput
              id={2}
              label={t('priceOffer.arabic')}
              type="radio"
              value="0"
              className="cancellation-policy-label"
              name="pdfLang"
              disabled
              {...register('pdfLang')}
            />
          </Col>
        </Row>
      </Form>

    </div>
  );
}

export default Inputs;
