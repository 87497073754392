/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { BrowserMultiFormatReader, BarcodeFormat } from "@zxing/library";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
// import QrReader from "react-qr-reader";
// import { Html5QrcodeScanner } from "html5-qrcode";
import Loading from "../../components/Loading";
import {
  getCarById,
  getDriverById,
} from "../../ymc-sdk/redux/actions/diesalClients.actions";
import Api from "../../ymc-sdk/services";

function QRScanner() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [carData, setCarData] = useState(null);
  const [driverScannerResult, setDriverScannerResult] = useState(null);
  const [isCarScanning, setCarScanning] = useState(false);
  const [isDriverScanning, setDriverScanning] = useState(false);
  const videoRef = useRef(null);
  const { data: productTypes, isLoading: productTypesLoading } = useQuery(
    ["productTypes"],
    () => Api.lookupData.getDiesalProductTypes(),
    {
      staleTime: 10 * 60 * 1000, // 10 mins
      cacheTime: 15 * 60 * 1000, // 15 mins
      select: (data) => {
        // console.log("industries", data);
        return data;
      },
    }
  );
  const carVideoRef = useRef(null);
  const driverVideoRef = useRef(null);

  // Function to stop the camera stream
  const stopCamera = (videoRef) => {
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };
  const handleQrcodeScan = async (el) => {
    try {
      const codeReader = new BrowserMultiFormatReader();
      const constraints = {
        video: { facingMode: "user" },
      };
      const result = await codeReader.decodeFromConstraints(
        {
          audio: false,
          video: {
            facingMode: "environment",
          },
        },
        el === "car" ? carVideoRef.current : driverVideoRef.current,
        async (result, error) => {
          if (result) {
            // console.log("Scanned result:", result.text);
            if (el === "car") {
              stopCamera(carVideoRef);
              // eslint-disable-next-line operator-linebreak
              const [clientId, carId, carNumber, ...arr] =
                result.text.split("#");
              setLoading(true);
              dispatch(getCarById(carId))
                .then((res) => {
                  // console.log(res.Data);
                  setCarData(res.Data);
                  setLoading(false);
                  setCarScanning(false);
                })
                .catch((error) => {
                  // console.log(error);
                  setCarScanning(false);
                  setLoading(false);
                  toast.error(t("dieselInvoice.carDoesnotExist"));
                });
            }
            if (el === "driver") {
              stopCamera(driverVideoRef);

              // eslint-disable-next-line operator-linebreak
              const [carId, driverId, carNumber, ...arr] =
                result.text.split("#");
              setLoading(true);
              dispatch(getDriverById(driverId))
                .then((res) => {
                  // console.log(res.Data);
                  if (res.Data?.CarId === carData.Id && carId === carData.Id) {
                    setLoading(false);
                    toast.success(t("shared.scanSuccessfully"));
                    setDriverScanning(false);
                    navigate(
                      `/dieselInvoice?carId=${carId}&driverId=${driverId}`
                    );
                  } else {
                    setLoading(false);
                    setDriverScanning(false);
                    toast.error(
                      t("dieselInvoice.driverDoesnotExistWithThisCar")
                    );
                  }
                })
                .catch((error) => {
                  // console.log(error);
                  setDriverScanning(false);
                  setLoading(false);
                  toast.error(t("dieselInvoice.driverDoesnotExist"));
                });
            }
          }
          if (error) {
            // console.error("Scanning error:", error);
          }
        },
        // eslint-disable-next-line comma-dangle
        constraints
      );
    } catch (err) {
      // console.log(err);
      toast.error(t("shared.sorrySomethingWrong"));
      stopCamera(el === "car" ? carVideoRef : driverVideoRef);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isCarScanning) {
      handleQrcodeScan("car");
    }
    if (isDriverScanning) {
      handleQrcodeScan("driver");
    }
  }, [isCarScanning, isDriverScanning]);
  return (
    <div className="auth-modal body-style mt-3 py-3 px-5">
      <Helmet>
        <title>{`YMC Group | ${ t("navBar.dieselInvoice")}`}</title>
      </Helmet>
      {!carData && !isCarScanning && (
        <Button
          className="main-button primary btn d-block"
          onClick={() => {
            setCarScanning(true);
            // handleQrcodeScan("car");
          }}
        >
          {t("dieselInvoice.scanCarQrCode")}
        </Button>
      )}
      {carData && (
        <div className="my-3 w-100">
          <h2>{t("dieselInvoice.carDetails")}</h2>
          <Table striped bordered hover className="table-responsive mb-0">
            <thead className="text-white" style={{ backgroundColor: "#333" }}>
              <tr>
                <th
                  className="bg-dark text-white"
                  style={{ verticalAlign: "middle" }}
                >
                  {t("dieselInvoice.carNumber")}
                </th>
                <th
                  className="bg-dark text-white"
                  style={{ verticalAlign: "middle" }}
                >
                  {t("dieselInvoice.additionalCarInfo")}
                </th>
                <th
                  className="bg-dark text-white"
                  style={{ verticalAlign: "middle" }}
                >
                  {t("dieselInvoice.productType")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{carData.CarNumber}</td>
                <td>{carData.AdditionalCarInfo}</td>
                <td>
                  {
                    productTypes?.find((p) => p.Id === carData.ProuductTypeId)
                      ?.label
                  }
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
      {carData && !isDriverScanning && (
        <Button
          className="main-button primary btn d-block"
          onClick={() => {
            setDriverScanning(true);
            // handleQrcodeScan("driver");
          }}
        >
          {t("dieselInvoice.scanDriverQrCode")}
        </Button>
      )}
      {isCarScanning && (
        <div className="row justify-content-center py-3">
          <div className="col-md-6 col-12 text-center">
            <video
              ref={carVideoRef}
              style={{ width: "100%", maxWidth: "400px" }}
              playsInline
              autoPlay
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></video>
          </div>
        </div>
      )}
      {isDriverScanning && (
        <div className="row justify-content-center py-3">
          <div className="col-md-6 col-12 text-center">
            <video
              ref={driverVideoRef}
              style={{ width: "100%", maxWidth: "400px" }}
              playsInline
              autoPlay
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></video>
          </div>
        </div>
      )}
      {isLoading && <Loading />}
      {productTypesLoading && <Loading />}
    </div>
  );
}
export default QRScanner;
